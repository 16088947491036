import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import {Router} from '@vaadin/router';
import OrgAdminUsersClient from '../control/OrgAdminUsersClient.js';
import {setUserListState} from '../control/OrgAdminControl.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';
import '../css/OrgAdmin.css';

class OrgAdminUsersList extends BElement {
    connectedCallback() {
        super.connectedCallback();
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token)
            .getMatrixUser('all').then(r => setUserListState({name: 'results', value: r}));
    }

    extractState(state) {
        return {
            matrix: state.matrix,
            user_list: state.orgAdmin.user_list
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>OrgAdmin - Users</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Display Name</th>
                            <th>ID</th>
                            <th>Active</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        ${this.state.user_list.results.map(e => html`
                            <tr>
                                <td>${e.username}</td>
                                <td>${e.displayname || '-'}</td>
                                <td>${e.user_id}</td>
                                <td>${e.active ? 'Yes' : 'No'}</td>
                                <td class='button-group'>
                                    <kosyma-button
                                            identifier='Open'
                                            label='Open'
                                            @click=${() => Router.go('/messenger/orgadmin/users/' + e.user_id + '/edit')}
                                    ></kosyma-button>
                                    <kosyma-button
                                            identifier='DisplayName'
                                            label='Display Name'
                                            variant='sky-blue'
                                            @click=${() => Router.go('/messenger/orgadmin/users/' + e.user_id + '/displayname')}
                                    ></kosyma-button>
                                    <kosyma-button
                                            identifier='Delete'
                                            label='Delete'
                                            variant='warm-red'
                                            @click=${() => this.onDelete(e.user_id)}
                                    ></kosyma-button>
                                </td>
                            </tr>
                        `)}
                        </tbody>
                    </table>
                </div>
            </div>

            ${this.state.user_list.delete_id ? html`
                <kosyma-securitycheck-password
                        text='Delete User ${this.state.user_list.delete_id}'
                        .onCancel=${() => setUserListState({name: 'delete_id', value: null})}
                        .onContinue=${password => {
                            this.onDelete(this.state.list.delete_id);
                        }}
                ></kosyma-securitycheck-password>` : nothing}
        `
    }

    onDelete = userId => {
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token).deleteUser(userId).then(() => {
            addInformationMessage(userId + ' has been deleted.')
        }).catch(err => {
            addErrorMessage(err);
        });
    }
}

customElements.define('kosyma-orgadmin-users-list', OrgAdminUsersList);

import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/tab.css';
import {setRoomState} from '../control/RoomControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class Room extends BElement {
    connectedCallback() {
        super.connectedCallback();
        const room = getMatrixClient().getRoom(this.location.params.roomId);
        setRoomState({name: 'id', value: this.location.params.roomId});
        setRoomState({name: 'name', value: room.name});

        // Handle tab parameter in URL
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('tab')) {
            switch (urlParams.get('tab')) {
                case 'invitation':
                    this.selectTab(2); // Index 2 is the invitation tab
                    break;
                case 'members':
                    this.selectTab(1);
                    break;
                case 'admin':
                    this.selectTab(4);
                    break;
                case 'vzd':
                    this.selectTab(3);
                    break;
                default:
                    this.selectTab(0); // Default to chat tab
            }
        }
    }

    extractState(state) {
        return {
            room: state.rooms.room,
            matrix_mode: state.matrix.matrix_mode
        }
    }

    // Helper function to check if a room is public
    isPublicRoom(roomId) {
        const room = getMatrixClient().getRoom(roomId);
        if (!room) return false;
        
        const joinRules = room.currentState.getStateEvents('m.room.join_rules', '');
        return joinRules && joinRules.getContent() && joinRules.getContent().join_rule === 'public';
    }

    // Helper function to check if a room is a direct message
    isDirectMessage(roomId) {
        const client = getMatrixClient();
        if (!client || !roomId) return false;

        // Get the direct message map from account data
        const directEvent = client.getAccountData('m.direct');
        if (!directEvent || !directEvent.getContent()) return false;

        const directRooms = directEvent.getContent();
        
        // Check if this room ID is in any user's direct message list
        return Object.values(directRooms).some(rooms => 
            Array.isArray(rooms) && rooms.includes(roomId)
        );
    }

    getRoomAvatarUrl(roomId) {
        const room = getMatrixClient().getRoom(roomId);
        if (!room) return null;
        
        const avatarEvent = room.currentState.getStateEvents('m.room.avatar', '');
        if (!avatarEvent || !avatarEvent.getContent() || !avatarEvent.getContent().url) {
            return null;
        }
        
        const mxcUrl = avatarEvent.getContent().url;
        return getMatrixClient().mxcUrlToHttp(mxcUrl);
    }

    view() {
        if (!this.state.room.id) {
            return nothing;
        }

        const isPublic = this.isPublicRoom(this.state.room.id);

        return html`
            <style>
                .room-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 24px;
                }
                .room-header h1 {
                    color: #241f21; /* Anthrazit */
                    font-size: 28px;
                    margin: 0;
                    font-weight: 600;
                }
                .room-avatar {
                    width: 64px;
                    height: 64px;
                    border-radius: 12px;
                    margin-right: 20px;
                    object-fit: cover;
                    background-color: #f8f9fa;
                }
                .room-avatar-placeholder {
                    width: 64px;
                    height: 64px;
                    border-radius: 12px;
                    margin-right: 20px;
                    background-color: #f8f9fa;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #908f87;
                    font-size: 24px;
                }
                .room-header-content {
                    flex: 1;
                }
                .public-room-indicator {
                    display: inline-flex;
                    align-items: center;
                    background-color: #f39200; /* Dark Yellow */
                    color: white;
                    font-size: 14px;
                    padding: 6px 12px;
                    border-radius: 16px;
                    margin-left: 16px;
                    font-weight: 600;
                }
                .public-room-indicator::before {
                    content: "⚠️";
                    margin-right: 6px;
                    font-size: 16px;
                }
                .public-room-banner {
                    background-color: rgba(243, 146, 0, 0.1); /* Dark Yellow with opacity */
                    border: 1px solid #f39200; /* Dark Yellow */
                    border-radius: 8px;
                    padding: 16px 20px;
                    margin-bottom: 24px;
                    display: ${isPublic ? 'flex' : 'none'};
                    align-items: center;
                }
                .public-room-banner p {
                    margin: 0;
                    color: #241f21; /* Anthrazit */
                    font-weight: 500;
                    line-height: 1.5;
                }
                .public-room-banner-icon {
                    font-size: 24px;
                    margin-right: 12px;
                }
                .tabs {
                    display: flex;
                    border-bottom: 2px solid #e1e5e8; /* Light Grey */
                    margin-bottom: 24px;
                }
                .tab {
                    padding: 12px 24px;
                    cursor: pointer;
                    font-weight: 500;
                    color: #908f87; /* Warm Grey */
                    border-bottom: 3px solid transparent;
                    margin-bottom: -2px;
                    transition: all 0.2s ease;
                }
                .tab:hover {
                    color: #1f73a3; /* Sky Blue */
                }
                .tab[selected] {
                    color: #1f73a3; /* Sky Blue */
                    border-bottom-color: #1f73a3; /* Sky Blue */
                }
                .tab-content {
                    background-color: white;
                    border-radius: 8px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
                    padding: 20px;
                }
            </style>
            <div class="room-header">
                ${this.getRoomAvatar(this.state.room.id)}
                <div class="room-header-content">
                    <h1>${this.state.room.name}</h1>
                    ${isPublic ? html`<span class="public-room-indicator">Public</span>` : ''}
                    ${this.isDirectMessage(this.state.room.id) ? html`
                        <span class="direct-message-indicator" style="
                            display: inline-flex;
                            align-items: center;
                            background-color: #2196F3;
                            color: white;
                            font-size: 14px;
                            padding: 6px 12px;
                            border-radius: 16px;
                            margin-left: 16px;
                            font-weight: 600;
                        ">
                            Direct Message
                        </span>
                    ` : ''}
                </div>
            </div>
            
            ${isPublic ? html`
                <div class="public-room-banner">
                    <span class="public-room-banner-icon">⚠️</span>
                    <p>This is a public room. Messages sent here may be visible to anyone. Please be mindful of sharing sensitive information.</p>
                </div>
            ` : ''}
            
            <div class='tabs'>
                <div class='tab' id='chat' ?selected=${this.state.room.selected_tab === 0}
                     @click=${() => this.selectTab(0)}>
                    Chat
                </div>
                <div class='tab' id='members' ?selected=${this.state.room.selected_tab === 1}
                     @click=${() => this.selectTab(1)}>
                    Members
                </div>
                ${!this.isDirectMessage(this.state.room.id) ? html`
                    <div class='tab' id='invitation' ?selected=${this.state.room.selected_tab === 2}
                        @click=${() => this.selectTab(2)}>
                        Invitation
                    </div>
                ` : nothing}
                <div class='tab' id='vzd' ?selected=${this.state.room.selected_tab === 3}
                     @click=${() => this.selectTab(3)}>
                    VZD
                </div>
                <div class='tab' id='admin' ?selected=${this.state.room.selected_tab === 4}
                     @click=${() => this.selectTab(4)}>
                    Admin
                </div>
            </div>
            <div class='tab-content'>
                ${this.state.room.selected_tab === 0 ? html`
                    <kosyma-room-chat roomId=${this.state.room.id}></kosyma-room-chat>` : nothing}
                ${this.state.room.selected_tab === 1 ? html`
                    <kosyma-room-members roomId=${this.state.room.id}></kosyma-room-members>` : nothing}
                ${this.state.room.selected_tab === 2 ? html`
                    <kosyma-room-invitation roomId=${this.state.room.id}></kosyma-room-invitation>` : nothing}
                ${this.state.room.selected_tab === 3 ? html`
                    <kosyma-room-vzd-search roomId=${this.state.room.id}></kosyma-room-vzd-search>` : nothing}
                ${this.state.room.selected_tab === 4 ? html`
                    <kosyma-room-admin roomId=${this.state.room.id}></kosyma-room-admin>` : nothing}
            </div>
        `;
    }

    selectTab = index => {
        setRoomState({name: 'selected_tab', value: index});
    }

    getRoomAvatar(roomId) {
        const room = getMatrixClient().getRoom(roomId);
        if (!room) return null;
        
        const avatarEvent = room.currentState.getStateEvents('m.room.avatar', '');
        if (avatarEvent && avatarEvent.getContent() && avatarEvent.getContent().url) {
            const mxcUrl = avatarEvent.getContent().url;
            const avatarUrl = getMatrixClient().mxcUrlToHttp(mxcUrl);
            return html`<img src=${avatarUrl} class="room-avatar" alt="${room.name}" @error=${this.onAvatarError}/>`;
        }
        return html`<div class="room-avatar-placeholder">${room.name.charAt(0).toUpperCase()}</div>`;
    }

    onAvatarError(e) {
        // Replace failed avatar with placeholder
        const roomName = e.target.alt;
        const placeholder = document.createElement('div');
        placeholder.className = 'room-avatar-placeholder';
        placeholder.textContent = roomName.charAt(0).toUpperCase();
        e.target.parentNode.replaceChild(placeholder, e.target);
    }
}

customElements.define('kosyma-room', Room);
import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import {Router} from '@vaadin/router';
import '../css/notification.css'
import {removeNotificationState} from '../control/NotificationsControl.js';


class Notifications extends BElement {
    extractState(state) {
        return {
            notifications: state.notifications
        }
    }

    view() {
        if (this.state.notifications.notifications.length === 0) {
            return nothing;
        }

        return html`
            <div class='notification-wrapper'>
                ${this.state.notifications.notifications.map(m => this.renderNotification(m))}
            </div>
        `
    }

    renderNotification = notification => {
        const notificationClass = notification.type === 'security-critical' ? 'notification-content security-critical' : 'notification-content';
        const linkText = notification.link || notification.action;
        
        return html`
            <div class='${notificationClass}'>
                <span class='notification-title'>${notification.title}</span>
                <span class='notification-text'>${notification.text}</span>
                ${linkText ? html`
                    <span class='notification-link'
                          @click=${() => this.onOpenLink(notification)}>${linkText}</span>
                ` : nothing}
                ${notification.closeable !== false ? html`
                    <button class='close-button' @click=${() => this.onRemoveNotification(notification)}>✖</button>
                ` : nothing}
            </div>
        `;
    }

    onRemoveNotification = notification => {
        removeNotificationState([notification]);
    }

    onOpenLink = notification => {
        const route = notification.link || notification.action;
        if (route) {
            Router.go(route);
        }
    }
}

customElements.define('kosyma-notifications', Notifications);

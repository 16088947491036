import BElement from '../../BElement.js'
import {getMatrixClient} from '../control/MatrixClient.js';
import {setMatrixState, setMatrixUpdateState} from '../control/MatrixControl.js';
import {addNotificationState} from '../../notification/control/NotificationsControl.js';
import {addInformationMessage} from '../../messages/control/MessagesControl.js';
import {nothing} from 'lit-html';
import {processReadReceipt} from '../../room/control/ReadReceiptsControl.js';

class Matrix extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix,
        }
    }

    view() {
        if (getMatrixClient() && !this.state.matrix.client_started) {
            setMatrixState({name: 'client_started', value: true});
            this.onStartClient().catch(r => setMatrixState({name: 'client_started', value: false}));
        }
        return nothing;
    }

    onStartClient = async () => {
        getMatrixClient().once('sync', (state, prevState, res) => {
            // Process any existing read receipts from initial sync
            if (res?.rooms?.join) {
                Object.entries(res.rooms.join).forEach(([roomId, roomData]) => {
                    if (roomData.ephemeral?.events) {
                        roomData.ephemeral.events
                            .filter(event => event.type === 'm.receipt')
                            .forEach(event => {
                                // Add room_id to the event since it's not included in ephemeral events
                                event.room_id = roomId;
                                processReadReceipt(event);
                            });
                    }
                });
            }
        });

        getMatrixClient().on('event', async event => {
            setMatrixUpdateState();
            // Process read receipts
            if (event.getType() === 'm.receipt') {
                // Get room ID from the event or from the room object
                const roomId = event.getRoomId() || event.room_id || event.event?.room_id;
                if (roomId) {
                    event.room_id = roomId;
                    processReadReceipt(event);
                }
            }
        });

        getMatrixClient().on('RoomMember.typing', (event, member) => {
            setMatrixUpdateState();
        });

        getMatrixClient().on('Room.receipt', (event, room) => {
            // Process read receipts from room events
            if (room) {
                event.room_id = room.roomId;
                processReadReceipt(event);
            }
        });

        getMatrixClient().on('crypto.keyRequest', async event => {
            const request = event.getContent();
            const userConfirmation = confirm(`Device ${request.requesting_device_id} wants a key. Allow?`);
            if (userConfirmation) {
                await getMatrixClient().secretStorage.store(request.session_id, request.requesting_device_id);
            }
        });

        getMatrixClient().on('crypto.verification.request', verificationRequest => {
            this.clearVerificationRequests();
            this.addVerificationRequest(verificationRequest);
            addNotificationState({
                title: 'New Device Detected',
                text: `A new device has been added to your account at ${new Date().toLocaleString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit',
                })}`,
                link: '/messenger/devices/verification/receiver/',
                closeable: true
            });
            addInformationMessage('New Device Verification Request Received');
        });

        getMatrixClient().on('toDeviceEvent', async event => {

                if (event.event.type === 'm.key.verification.request') {
                } else if (event.event.type === 'm.key.verification.cancel') {
                } else if (event.event.type === 'm.key.verification.start') {
                }
            }
        );

        getMatrixClient().on('Room.myMembership', (room, memberShip) => {
            const name = room.currentState.getStateEvents('m.room.name').pop().event.content.name;
            const creator = room.currentState.getStateEvents('m.room.create').pop().event.sender;
            const invitations = this.state.matrix.invitations.filter(e => e.room_id !== room.roomId);
            if (memberShip === 'invite') {
                invitations.push({room_name: name, room_id: room.roomId, room_creator: creator});
            }
            setMatrixState({name: 'invitations', value: invitations});
            setMatrixUpdateState();
        });
    }

    addVerificationRequest = event => {
        if (!globalThis.verificationRequests) {
            globalThis.verificationRequests = [];
        }
        globalThis.verificationRequests.push(event);
    }

    clearVerificationRequests = () => {
        globalThis.verificationRequests = null;
    }
}

customElements.define('kosyma-matrix', Matrix)

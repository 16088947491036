import {html} from 'lit-html';
import BElement from '../../BElement.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';

class RoomMembers extends BElement {
    view() {
        const room = getMatrixClient().getRoom(this.getAttribute('roomId'));
        return html`
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>State</th>
                    <th>Presence</th>
                    <th>Typing</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                ${room.getMembers().map(e => html`
                    <tr>
                        <td>${e.user?.displayName}</td>
                        <td>${e.membership}</td>
                        <td>${e.user?.presence}</td>
                        <td>${e.user?.typing ? 'Yes' : 'No'}</td>
                        <td>
                            <kosyma-button
                                    identifier='kick'
                                    label='Kick'
                                    @click=${() => this.onKick(room, e)}
                            ></kosyma-button>
                        </td>
                        <td>
                            <kosyma-button
                                    identifier='Ban'
                                    label='Ban'
                                    @click=${() => this.onBan(room, e)}
                            ></kosyma-button>
                        </td>
                    </tr>
                `)}
                </tbody>
            </table>`
    }

    onKick = (room, user) => {
        getMatrixClient().kick(room.roomId, user.userId).then(res => {
            addInformationMessage(user.userId + ' Kicked');
        }).catch(err => {
            addErrorMessage(err);
        });
    }

    onBan = (room, user) => {
        getMatrixClient().ban(room.roomId, user.userId).then(res => {
            addInformationMessage(user.userId + ' Banned');
        }).catch(err => {
            addErrorMessage(err);
        });
    }
}

customElements.define('kosyma-room-members', RoomMembers);

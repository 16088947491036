import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import './RoomChatMessage.js';
import './RoomChatInputField.js';
import '../css/RoomChat.css';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {setRoomChatState} from '../control/RoomControl.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';
import * as sdk from 'matrix-js-sdk';

class RoomChat extends BElement {
    extractState(state) {
        return {
            room: state.rooms.room,
            matrix: state.matrix,
            chat: state.rooms.room.chat
        }
    }

    constructor() {
        super();
        this.userHasScrolled = false;
        this.lastMessageCount = 0;
    }

    connectedCallback() {
        super.connectedCallback();
        // Add event listener to scroll to bottom on new messages
        this.setupRoomEventListener();
        this.setupScrollListener();
    }

    disconnectedCallback() {
        // Remove event listeners when component is destroyed
        this.teardownRoomEventListener();
        this.teardownScrollListener();
        super.disconnectedCallback();
    }

    setupScrollListener() {
        this.handleScroll = this.handleScroll.bind(this);
        const messagesDiv = document.querySelector('#messages');
        if (messagesDiv) {
            messagesDiv.addEventListener('scroll', this.handleScroll);
        }
    }

    teardownScrollListener() {
        const messagesDiv = document.querySelector('#messages');
        if (messagesDiv) {
            messagesDiv.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll() {
        const div = document.querySelector('#messages');
        if (!div) return;

        // Check if user has scrolled up
        const isAtBottom = Math.abs(div.scrollHeight - div.scrollTop - div.clientHeight) < 10;
        
        // If user scrolled up from bottom, set flag to prevent auto-scrolling
        if (!isAtBottom) {
            this.userHasScrolled = true;
        } else {
            // User has scrolled back to bottom
            this.userHasScrolled = false;
        }
    }

    setupRoomEventListener() {
        this.onRoomTimelineEvent = this.onRoomTimelineEvent.bind(this);
        const client = getMatrixClient();
        if (client) {
            client.on('Room.timeline', this.onRoomTimelineEvent);
        }
    }

    teardownRoomEventListener() {
        const client = getMatrixClient();
        if (client) {
            client.removeListener('Room.timeline', this.onRoomTimelineEvent);
        }
    }

    onRoomTimelineEvent(event, room, toStartOfTimeline) {
        // Don't react to historical messages being loaded
        if (toStartOfTimeline) return;
        
        // Check if this event is for the current room
        if (room && room.roomId === this.getAttribute('roomId')) {
            // Only proceed for actual message events
            const isMessageEvent = event.getType() === 'm.room.message' || event.getType() === 'm.room.encrypted';
            if (!isMessageEvent) return;
            
            const isSelfMessage = event.getSender() === getMatrixClient().getUserId();
            
            // Only scroll if:
            // 1. It's the user's own message, or
            // 2. The user hasn't manually scrolled up (viewing history)
            if (isSelfMessage || !this.userHasScrolled) {
                // Use setTimeout to ensure the DOM has updated with the new message
                setTimeout(() => this.scrollToBottom(), 100);
            }
        }
    }

    scrollToBottom() {
        const div = document.querySelector('#messages');
        if (div) {
            div.scrollTo({
                top: div.scrollHeight,
                behavior: 'smooth'
            });
        }
    }

    view() {
        const room = getMatrixClient().getRoom(this.getAttribute('roomId'));
        if (!room) {
            throw Error('Room not found');
        }

        const events = [];
        const isLoadingMore = this.state.chat?.isLoadingMoreMessages || false;
        
        try {
            // First attempt - get from timelines
            room.getLiveTimeline()
                .getTimelineSet()
                .getTimelines()
                .forEach(timeline => {
                    timeline.getEvents()
                        .forEach((event) => {
                            // Only add events that have been successfully sent
                            if (event.status === null || event.status === 'sent') {
                                // Get the actual event object - sometimes it's directly on the event,
                                // sometimes it's in event.event
                                const eventData = event.event || event;
                                events.push(eventData);
                            }
                        });
                });
                
            // Second attempt - fallback to room.timeline if available
            if (events.length === 0 && room.timeline && Array.isArray(room.timeline)) {
                room.timeline.forEach(event => {
                    if (event.status === null || event.status === 'sent') {
                        const eventData = event.event || event;
                        events.push(eventData);
                    }
                });
            }
        } catch (error) {
            console.error('Error getting timeline events:', error);
        }

        // Sort events in ascending order (oldest first, newest last)
        events.sort((a, b) => a.origin_server_ts - b.origin_server_ts);

        // Separate system events and messages
        const systemEvents = events.filter(event => event.type !== 'm.room.message' && event.type !== 'm.room.encrypted');
        const messageEvents = events.filter(event => event.type === 'm.room.message' || event.type === 'm.room.encrypted');

        const isSystemEventsCollapsed = this.state.chat?.system_events_collapsed ?? true;
        const creationEvent = systemEvents.find(event => event.type === 'm.room.create');
        const creator = creationEvent ? creationEvent.sender : 'Unknown user';

        return html`
            <div class='chat-container'>
                <div class='messages' id='messages'>
                    <div class='load-more-container'>
                        <button class='load-more-button' 
                                @click=${this.loadMoreMessages}
                                ?disabled=${isLoadingMore}>
                            ${isLoadingMore ? 'Loading...' : 'Load more messages'}
                        </button>
                    </div>
                    
                    ${events.length === 0 ? html`
                        <div class="empty-state">
                            <p>No messages found. This could be due to a temporary connection issue or an empty room.</p>
                            <button class="force-reload-button" @click=${this.forceReloadTimeline}>
                                Reload Timeline
                            </button>
                        </div>
                    ` : nothing}
                    
                    ${systemEvents.length > 0 ? html`
                        <div class='system-events-section'>
                            <div class='system-events-header' @click=${this.toggleSystemEvents}>
                                <span>${creator} created and configured the room</span>
                                <kosyma-icon
                                        icon=${isSystemEventsCollapsed ? 'chevron-down' : 'chevron-up'}></kosyma-icon>
                            </div>
                            <div class='system-events-content ${isSystemEventsCollapsed ? 'collapsed' : ''}'>
                                ${systemEvents.map(event => {
                                    return html`
                                        <kosyma-room-chat-message
                                                eventId=${event.event_id}
                                                roomId=${this.getAttribute('roomId')}>
                                        </kosyma-room-chat-message>
                                    `;
                                })}
                            </div>
                        </div>
                    ` : nothing}
                    
                    ${messageEvents.map(event => {
                        // Ensure room_id is set on the event
                        if (!event.room_id) {
                            event.room_id = this.getAttribute('roomId');
                        }
                        return html`
                            <kosyma-room-chat-message
                                    eventId=${event.event_id}
                                    roomId=${this.getAttribute('roomId')}>
                            </kosyma-room-chat-message>
                        `;
                    })}
                </div>
                <kosyma-room-chat-input-field></kosyma-room-chat-input-field>
            </div>
        `;
    }

    loadMoreMessages = async () => {
        // Set user scrolled flag to true as they're explicitly 
        // requesting to view history
        this.userHasScrolled = true;
        
        try {
            setRoomChatState({
                name: 'isLoadingMoreMessages',
                value: true
            });

            const room = getMatrixClient().getRoom(this.getAttribute('roomId'));
            if (!room) {
                throw Error('Room not found');
            }

            const timeline = room.getLiveTimeline();
            
            // Check if there's a pagination token - if not, we may have reached the end
            if (!timeline.getPaginationToken(sdk.EventTimeline.BACKWARDS)) {
                addInformationMessage('Reached the beginning of conversation history');
                setRoomChatState({
                    name: 'isLoadingMoreMessages',
                    value: false
                });
                return;
            }
            
            // Try both the scrollback function and the paginate method
            try {
                // First try scrollback
                await getMatrixClient().scrollback(room, 20);
            } catch (scrollbackError) {
                console.error('Error with scrollback, trying direct pagination:', scrollbackError);
                
                // If scrollback fails, try direct pagination on the timeline
                try {
                    await timeline.getTimelineSet().paginate(
                        sdk.EventTimeline.BACKWARDS, 
                        20, 
                        false
                    );
                } catch (paginateError) {
                    console.error('Both pagination methods failed:', paginateError);
                    throw new Error('Failed to load more messages: ' + paginateError.message);
                }
            }
            
            setRoomChatState({
                name: 'isLoadingMoreMessages',
                value: false
            });
            
            // Add a small informational message to show success
            addInformationMessage('Loaded older messages');
        } catch (error) {
            console.error('Error loading more messages:', error);
            addErrorMessage('Failed to load more messages. Please try again.');
            setRoomChatState({
                name: 'isLoadingMoreMessages',
                value: false
            });
        }
    }

    toggleSystemEvents = () => {
        setRoomChatState({
            name: 'system_events_collapsed',
            value: !this.state.chat?.system_events_collapsed
        });
    }

    testScrollPosition = () => {
        // Only scroll to bottom if user hasn't manually scrolled up
        if (!this.userHasScrolled) {
            this.scrollToBottom();
        }
    }

    forceReloadTimeline = async () => {
        try {
            setRoomChatState({
                name: 'isLoadingMoreMessages',
                value: true
            });

            const roomId = this.getAttribute('roomId');
            const client = getMatrixClient();
            const room = client.getRoom(roomId);
            
            if (!room) {
                throw Error('Room not found');
            }

            // First, try to get the room state directly
            try {
                // This forces a room state refresh
                await client.roomState(roomId);
            } catch (e) {
                console.warn('Failed to refresh room state:', e);
            }
            
            // Try to force the client to get messages for this room
            try {
                // Request messages from the server for this room
                const response = await client.createMessagesRequest(
                    roomId,
                    null, // No token - start from most recent
                    50,   // Get up to 50 messages
                    'b'   // Direction (backwards in time)
                );
                
                if (response && response.chunk && response.chunk.length > 0) {
                    // Force the SDK to process these events
                    client.getEventMapper()(response.chunk);
                }
            } catch (e) {
                console.error('Direct message request failed:', e);
            }
            
            // Fallback to forcing a limited sync 
            try {
                // Force a sync focusing on this room
                await client.peekInRoom(roomId);
            } catch (peekError) {
                console.warn('Peek in room failed:', peekError);
            }
            
            addInformationMessage('Timeline refresh attempted. Please wait a moment...');
            
            // Allow a moment for the Matrix client to process the events
            setTimeout(() => {
                setRoomChatState({
                    name: 'isLoadingMoreMessages',
                    value: false
                });
            }, 2000);
            
        } catch (error) {
            console.error('Error forcing timeline reload:', error);
            addErrorMessage('Failed to reload timeline. Please try again or refresh the page.');
            setRoomChatState({
                name: 'isLoadingMoreMessages',
                value: false
            });
        }
    }
}

customElements.define('kosyma-room-chat', RoomChat);
import {configureStore} from '@reduxjs/toolkit'
import {matrix} from './matrix/entity/MatrixReducer.js'
import {login} from './login/entity/LoginReducer.js'
import {messages} from './messages/entity/MessagesReducer.js'
import {rooms} from './room/entity/RoomsReducer.js'
import {register} from './register/entity/RegisterReducer.js'
import {devices} from './devices/entity/DevicesReducer.js'
import {securityCheck} from './securitycheck/entity/SecurityCheckReducer.js';
import {notifications} from './notification/entity/NotificationReducer.js';
import {orgAdmin} from './orgadmin/entity/OrgAdminReducer.js';
import {settings} from './settings/entity/SettingsReducer.js';
import {userDirectoryReducer as userDirectory} from './users/entity/UserDirectoryReducer.js';
import {load} from './localstorage/control/StorageControl.js';

const reducer = {
    matrix,
    register,
    login,
    messages,
    rooms,
    devices,
    securityCheck,
    notifications,
    orgAdmin,
    settings,
    userDirectory
};

const preloadedState = load();
const store = configureStore({
    reducer,
    preloadedState: preloadedState || undefined,
});


export default store;
export const showUpdateDialog = newVersion => {
    // Remove any existing update dialog
    const existingDialog = document.querySelector('.update-dialog');
    if (existingDialog) {
        existingDialog.remove();
    }

    const dialog = document.createElement('div');
    dialog.className = 'update-dialog';
    dialog.innerHTML = `
        <div style="
            position: fixed;
            top: 20px;
            right: 20px;
            background: white;
            padding: 24px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            text-align: left;
            z-index: 9999;
            max-width: 400px;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
        ">
            <button id="closeUpdate" style="
                position: absolute;
                top: 12px;
                right: 12px;
                background: none;
                border: none;
                cursor: pointer;
                padding: 4px;
                color: #666;
                font-size: 18px;
            ">×</button>
            <div style="margin-bottom: 16px;">
                <div style="display: flex; align-items: center; gap: 8px; margin-bottom: 8px;">
                    <span style="font-size: 20px;">🔄</span>
                    <h3 style="margin: 0; font-size: 16px; color: #333;">Update Available</h3>
                </div>
                <p style="margin: 0; color: #666; font-size: 14px;">
                    A new version (<b>${newVersion}</b>) of TI-Messenger is available.
                </p>
            </div>
            <div style="display: flex; gap: 8px; justify-content: flex-end;">
                <button id="cancelUpdate" style="
                    padding: 8px 16px;
                    border: 1px solid #e0e0e0;
                    background: white;
                    border-radius: 6px;
                    cursor: pointer;
                    font-size: 14px;
                    color: #666;
                    transition: background-color 0.2s;
                ">Later</button>
                <button id="updateNow" style="
                    padding: 8px 16px;
                    border: none;
                    background: #1f73a3;
                    color: white;
                    border-radius: 6px;
                    cursor: pointer;
                    font-size: 14px;
                    transition: background-color 0.2s;
                ">Update now</button>
            </div>
        </div>
    `;
    document.body.appendChild(dialog);

    document.getElementById('updateNow').addEventListener('click', () => location.reload());
    document.getElementById('cancelUpdate').addEventListener('click', () => dialog.remove());
    document.getElementById('closeUpdate').addEventListener('click', () => dialog.remove());

    // Add hover effects
    const updateNowBtn = document.getElementById('updateNow');
    const cancelUpdateBtn = document.getElementById('cancelUpdate');
    
    updateNowBtn.addEventListener('mouseover', () => updateNowBtn.style.backgroundColor = '#4f46e5');
    updateNowBtn.addEventListener('mouseout', () => updateNowBtn.style.backgroundColor = '#6366f1');
    
    cancelUpdateBtn.addEventListener('mouseover', () => cancelUpdateBtn.style.backgroundColor = '#f5f5f5');
    cancelUpdateBtn.addEventListener('mouseout', () => cancelUpdateBtn.style.backgroundColor = 'white');
};

let currentVersion = null;

export const testVersion = async () => {
    try {
        const response = await fetch(document.location.href, {cache: 'no-store'});
        if (!response.ok) {
            throw new Error(response.status);
        }

        const htmlText = await response.text();
        const doc = new DOMParser().parseFromString(htmlText, 'text/html');

        const metaElement = doc.getElementById('build-time-meta');
        const newVersion = metaElement?.getAttribute('data-build-time') || null;

        console[newVersion ? 'log' : 'warn'](`✅ Build-Version ${newVersion ? 'found' : 'not found'}: ${newVersion}`);

        if (currentVersion && newVersion && currentVersion !== newVersion) {
            console.warn(`🔄 New version detected: ${newVersion} (Old: ${currentVersion})`);
            showUpdateDialog(newVersion);
        }

        if (!currentVersion) {
            currentVersion = newVersion;
            console.log(`📌 Initial Build-Version: ${currentVersion}`);
        }
    } catch (error) {
        console.error('❌ Error while loading:', error);
    }
};
    import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/Home.css';

class Home extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix
        }
    }

    view() {
        return html`
            <div class='main-content'>
                <div class='header'>
                    <h1>Welcome ${this.state.matrix.session?.user_id}</h1>
                </div>
                <div class='header'>
                    <h2>🛡️Security Notice</h2>
                    <p>
                        Please be aware that using the TI Messenger Client on hardware not under your personal control
                        may pose serious security risks.
                        This includes, but is not limited to, publicly accessible devices or shared systems without
                        proper IT security monitoring.
                    </p>
                    <p>
                        We strongly recommend not using the TI Messenger Client on such devices.
                    </p>
                    <p>
                        Your data security and confidentiality cannot be guaranteed in these environments.
                    </p>
                </div>
                <div class='header'>
                    <h2>🛡️Information About Malware Risks</h2>
                    <p>
                        Documents received via the messenger may contain malicious software (malware). 
                    </p>
                    <p>
                        Please make sure to scan all files using appropriate security or antivirus software before opening them. 
                    </p>
                    <p>
                        You are solely responsible for verifying the safety of the received documents.
                    </p>
                </div>
                <div class='cards'>
                    <kosyma-home-invitations></kosyma-home-invitations>
                </div>
                <div class='cards'>
                    <kosyma-notifications></kosyma-notifications>
                </div>
            </div>`
    }
}

customElements.define('kosyma-home', Home);

import {createReducer} from '@reduxjs/toolkit'
import {
    resetAction,
    roomAction,
    roomChatAction,
    roomVzdSearchAction,
    roomInvitationAction, roomCreateMatrixAction, roomCreateTimAction
} from '../control/RoomControl.js'


/*
  {
                type: 'm.room.encryption',
                state_key: '',
                content: {
                    algorithm: 'm.megolm.v1.aes-sha2'
                }
            }
 */
const initialState = {
    room: {
        id: null,
        name: null,
        selected_tab: 0,
        members: [],
        chat: {
            text: null,
            show_code: [],
            reply: [],
        },
        invitation: {
            filter: null,
            results: []
        },
        vzd_search: {
            filter: null,
            results: []
        }
    },
    room_create: {
        matrix: {
            name: '',
            topic: '',
            encrypted: true,
            visibility: 'private',
            preset: 'trusted_private_chat',
            initial_state: []
        },
        tim: {
            enabled: false,
            name: '',
            topic: ''
        }
    },
}

export const rooms = createReducer(initialState, (builder) => {
    builder.addCase(roomCreateMatrixAction, (state, {payload}) => {
        state.room_create.matrix[payload.name] = payload.value
    }).addCase(roomCreateTimAction, (state, {payload}) => {
        state.room_create.tim[payload.name] = payload.value
    }).addCase(roomAction, (state, {payload}) => {
        state.room[payload.name] = payload.value
    }).addCase(resetAction, (state, {payload}) => {
        state.room = initialState.room;
        state.room_create = initialState.room_create;
        state.room_list = initialState.room_list;
    }).addCase(roomChatAction, (state, {payload}) => {
        state.room.chat[payload.name] = payload.value
    }).addCase(roomInvitationAction, (state, {payload}) => {
        state.room.invitation[payload.name] = payload.value
    }).addCase(roomVzdSearchAction, (state, {payload}) => {
        state.room.vzd_search[payload.name] = payload.value
    });
})

import { html, nothing } from 'lit-html';
import { Router } from '@vaadin/router';
import BElement from '../../BElement.js';
import { searchUserDirectory, setUserDirectorySearchState, createDirectMessageRoom, findExistingDirectMessageRoom } from '../control/UserDirectoryControl.js';
import { addInformationMessage, addErrorMessage } from '../../messages/control/MessagesControl.js';
import { getMatrixClient } from '../../matrix/control/MatrixClient.js';
import '../css/users.css';

class UserDirectory extends BElement {
    constructor() {
        super();
        this.timeoutId = null;
    }

    extractState(state) {
        return {
            search: state.userDirectory?.search || { filter: '', results: [], isLoading: false, error: null },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('debounced-input', this.onDebouncedInput);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('debounced-input', this.onDebouncedInput);
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner user-directory-container'>
                    <h2>User Directory</h2>
                    <p>Search for users in your organization to start a direct encrypted chat.</p>
                    
                    <div class='search-container'>
                        <kosyma-input-text
                            identifier='user-search'
                            label='Search Users'
                            autofocus='true'
                            name='filter'
                            value=${this.state.search.filter}
                            @input=${this.onInputChange}
                        ></kosyma-input-text>
                    </div>
                    
                    ${this.state.search.isLoading ? html`
                        <div class='loading'>Searching users...</div>
                    ` : nothing}
                    
                    ${this.state.search.error ? html`
                        <div class='error'>Error: ${this.state.search.error}</div>
                    ` : nothing}
                    
                    ${this.state.search.results.length > 0 ? html`
                        <table class='users-list'>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${this.state.search.results.map(user => html`
                                    <tr>
                                        <td>
                                            <div class='user-info'>
                                                <div class='user-avatar'>
                                                    ${this.getUserInitials(user.display_name || user.user_id)}
                                                </div>
                                                <div>
                                                    <div>${user.display_name || user.user_id}</div>
                                                    <div class='user-id'>${user.user_id}</div>
                                                    ${user.has_direct_message ? html`
                                                        <span class='direct-message-label'>Existing Chat</span>
                                                    ` : nothing}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <kosyma-button
                                                identifier='direct-message'
                                                label=${user.has_direct_message ? 'Open Chat' : 'Direct Message'}
                                                @click=${() => this.startDirectMessage(user.user_id)}
                                            ></kosyma-button>
                                        </td>
                                    </tr>
                                `)}
                            </tbody>
                        </table>
                    ` : (this.state.search.filter && !this.state.search.isLoading) ? html`
                        <div class='no-results'>No users found matching "${this.state.search.filter}"</div>
                    ` : nothing}
                </div>
            </div>
        `;
    }

    getUserInitials(name) {
        if (!name) return '?';
        
        // For Matrix IDs, try to extract the username part
        if (name.startsWith('@')) {
            const parts = name.split(':');
            if (parts.length > 0) {
                name = parts[0].substring(1); // Remove the @ symbol
            }
        }
        
        // Split by spaces or dots and take first letters
        const parts = name.split(/[ .]/);
        if (parts.length >= 2) {
            return (parts[0][0] + parts[1][0]).toUpperCase();
        }
        
        // Otherwise just return the first 1-2 characters
        return name.substring(0, Math.min(2, name.length)).toUpperCase();
    }

    onInputChange = (e) => {
        const { name, value } = e.target;
        setUserDirectorySearchState({ name, value });
        this.debouncedUpdate(value);
    };

    onDebouncedInput = () => {
        if (this.state.search.filter.trim().length > 0) {
            searchUserDirectory(this.state.search.filter);
        } else {
            // Clear results if search is empty
            setUserDirectorySearchState({ name: 'results', value: [] });
        }
    };

    debounce = (func, wait) => {
        return (...args) => {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => func.apply(this, args), wait);
        };
    };

    debouncedUpdate = this.debounce(value => {
        this.dispatchEvent(new CustomEvent('debounced-input', {
            detail: { value }
        }));
    }, 500);

    startDirectMessage = async (userId) => {
        try {
            const user = this.state.search.results.find(u => u.user_id === userId);
            let existingRoomId = null;
            
            // Safely check for existing direct message room
            try {
                existingRoomId = user?.direct_message_room_id;
                
                // Double-check that the room still exists
                if (existingRoomId) {
                    const client = getMatrixClient();
                    const room = client?.getRoom(existingRoomId);
                    if (!room || room.getMyMembership() !== 'join') {
                        console.warn('Existing room no longer accessible, will create new room');
                        existingRoomId = null;
                    }
                }
            } catch (error) {
                console.warn('Error checking existing room, will create new one:', error);
                existingRoomId = null;
            }
            
            if (existingRoomId) {
                addInformationMessage(`Opening existing direct chat with ${userId}`);
                Router.go(`/messenger/rooms/${existingRoomId}`);
                return;
            }
            
            // Create a new direct message room
            const roomId = await createDirectMessageRoom(userId);
            if (!roomId) {
                throw new Error('Failed to create direct message room');
            }
            
            addInformationMessage(`Started new encrypted direct chat with ${userId}`);
            Router.go(`/messenger/rooms/${roomId}`);
        } catch (error) {
            // Error is already handled in the createDirectMessageRoom function
            console.error('Failed to start direct message:', error);
            addErrorMessage(`Could not start direct message: ${error.message}`);
        }
    };
}

customElements.define('kosyma-user-directory', UserDirectory); 
import { createAction } from '@reduxjs/toolkit';
import store from '../../store.js';
import { appName } from '../../app.config.js';
import { getMatrixClient } from '../../matrix/control/MatrixClient.js';

const READ_RECEIPTS_ENABLED_KEY = `${appName}.settings.readReceiptsEnabled`;

export const setSettingsAction = createAction('setSettingsAction');

export const setSettingsState = (name, value) => {
    store.dispatch(setSettingsAction({ name, value }));
};

/**
 * Initialize settings with default values
 */
export const initializeSettings = async () => {
    // Read receipts are disabled by default (privacy-by-default)
    const readReceiptsEnabled = localStorage.getItem(READ_RECEIPTS_ENABLED_KEY) === 'true' || false;
    setSettingsState('readReceiptsEnabled', readReceiptsEnabled);
    
    // Initialize password fields
    setSettingsState('currentPassword', '');
    setSettingsState('newPassword', '');
    setSettingsState('confirmPassword', '');

    // Check if password changes are supported
    try {
        const client = getMatrixClient();
        if (client) {
            const capabilities = await client.getCapabilities();
            setSettingsState('passwordChangeEnabled', capabilities?.['m.change_password']?.enabled ?? false);
        } else {
            setSettingsState('passwordChangeEnabled', false);
        }
    } catch (error) {
        console.error('Failed to check password change capability:', error);
        setSettingsState('passwordChangeEnabled', false);
    }
};

/**
 * Toggle read receipts on/off
 * @param {boolean} enabled - Whether read receipts should be enabled
 */
export const toggleReadReceipts = (enabled) => {
    localStorage.setItem(READ_RECEIPTS_ENABLED_KEY, enabled.toString());
    setSettingsState('readReceiptsEnabled', enabled);
};

export const updatePassword = async (currentPassword, newPassword) => {
    const client = getMatrixClient();
    if (!client) {
        throw new Error('Matrix client not initialized');
    }
    
    try {
        await client.http.authedRequest(
            "POST",
            "/account/password",
            undefined,
            {
                new_password: newPassword,
                logout_devices: false,
                auth: {
                    type: 'm.login.password',
                    identifier: {
                        type: 'm.id.user',
                        user: client.getUserId(),
                    },
                    password: currentPassword
                }
            }
        );
        
        // Clear the password fields
        setSettingsState('currentPassword', '');
        setSettingsState('newPassword', '');
        setSettingsState('confirmPassword', '');
    } catch (error) {
        if (error.httpStatus === 401) {
            throw new Error('Current password is incorrect');
        }
        throw error;
    }
};

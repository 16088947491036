import {html} from 'lit-html'
import BElement from '../../BElement.js'
import {setPasswordLoginState} from '../control/RegisterControl.js';
import {isUserAvailable, registerByPassword} from '../control/RegisterByPasswordClient.js';
import {Router} from '@vaadin/router';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';


class RegisterByPassword extends BElement {
    extractState(state) {
        return {
            base_url: state.matrix.base_url,
            password: state.register.password
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Register new User</h2>
                    <h3>${this.state.base_url}</h3>
                    <kosyma-input-text
                            identifier='user'
                            label='User'
                            name='user'
                            autofocus='true'
                            value=${this.state.password.user}
                            @keyup=${(e) => this.onChangeValue(e)}
                            @onChange=${(e) => this.onChangeValue(e)}
                            @input=${e => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='password'
                            label='Password'
                            name='password'
                            type='password'
                            autocomplete='new-password'
                            value=${this.state.password.password}
                            @keyup=${(e) => this.onChangeValue(e)}
                            @onChange=${(e) => this.onChangeValue(e)}
                            @input=${e => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='confirm_password'
                            label='Confirm Password'
                            name='confirm_password'
                            autocomplete='new-password'
                            type='password'
                            value=${this.state.password.confirm_password}
                            @keyup=${(e) => this.onChangeValue(e)}
                            @onChange=${(e) => this.onChangeValue(e)}
                            @input=${e => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <div class='button-group' style="display: inline-flex; gap: 10px;">
                        <kosyma-button
                                identifier='back'
                            label='Back'
                            variant='light-grey'
                            role='secondary'
                            style="flex: 1;"
                            @click=${() => Router.go('/login/')}>
                        </kosyma-button>
                        <kosyma-button
                            identifier='register'
                            label='Register'
                            variant='sky-blue'
                            role='primary'
                            style="flex: 1;"
                            @click=${this.onRegister}>
                        </kosyma-button>
                    </div>
                </div>
            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setPasswordLoginState({name, value})
    }

    onRegister = async () => {
        if (!this.state.password.user ||
            !this.state.password.password || !this.state.password.confirm_password) {
            addErrorMessage('Invalid User/Password');
            return;
        }

        if (this.state.password.password !== this.state.password.confirm_password) {
            addErrorMessage('Password doesn\'t match');
            return;
        }

        const available = await isUserAvailable(this.state.base_url, this.state.password.user);
        if (!available) {
            addErrorMessage(this.state.password.user + ' already in use');
            return;
        }

        registerByPassword(this.state.base_url, {
            username: this.state.password.user,
            password: this.state.password.password,
            refresh_token: true,
            initial_device_display_name: 'UI',
        }).then(async res => {
            Router.go('/login/');
            addInformationMessage('User successfully registered!');
        }).catch(err => {
            if (err.httpStatus !== 401) {
                addErrorMessage(err);
            }
        });
    }
}

customElements.define('kosyma-register-password', RegisterByPassword);

import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/RoomChat.css';
import {setRoomChatState} from '../control/RoomControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import * as maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

class RoomChatMessage extends BElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this._memoizedReceipts = null;
        this._mapInstances = {}; // Store map instances by eventId
        this._mapClassToEventId = {}; // Store map class to event ID map
        
        // Add MapLibre CSS to the shadow DOM
        const maplibreStyle = document.createElement('link');
        maplibreStyle.rel = 'stylesheet';
        maplibreStyle.href = 'https://unpkg.com/maplibre-gl@5.2.0/dist/maplibre-gl.css';
        this.shadowRoot.appendChild(maplibreStyle);
        
        // Add Font Awesome CSS to the shadow DOM
        const fontAwesomeStyle = document.createElement('link');
        fontAwesomeStyle.rel = 'stylesheet';
        fontAwesomeStyle.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css';
        this.shadowRoot.appendChild(fontAwesomeStyle);
        
        // Add custom map styles to the shadow DOM
        const mapStyles = document.createElement('style');
        mapStyles.textContent = `
            /* Message layout styles */
            .message {
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;
                padding: 8px 0;
                position: relative;
                border: none;
            }
            
            .message-row {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
            }
            
            .message-avatar {
                margin-right: 12px;
                flex-shrink: 0;
            }
            
            .message-container {
                flex: 1;
                min-width: 0;
                display: flex;
                flex-direction: column;
            }
            
            .message-header-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 4px;
            }
            
            .message-header {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            
            .sender {
                font-weight: 500;
                margin-right: 8px;
                color: #1f73a3;
            }
            
            .timestamp {
                font-size: 12px;
                color: #666;
            }
            
            .message-actions {
                display: flex;
                align-items: center;
                gap: 8px;
                opacity: 0; /* Hidden by default */
                transition: opacity 0.2s ease;
            }
            
            .message:hover .message-actions {
                opacity: 1; /* Only visible on hover */
            }
            
            .action-button {
                cursor: pointer;
                padding: 6px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f5f5f5;
                transition: all 0.15s ease;
                width: 28px;
                height: 28px;
            }
            
            .action-button:hover {
                background-color: #e0e0e0;
            }
            
            .message-content {
                margin-top: 2px;
                word-break: break-word;
                overflow: hidden;
            }
            
            .system-message {
                font-size: 13px;
                color: #666;
                text-align: center;
                padding: 4px 12px;
                background-color: rgba(0, 0, 0, 0.03);
                border-radius: 8px;
                margin: 8px 0;
            }
            
            /* Location styles */
            .location-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 360px;
                margin: 8px 0;
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
                background-color: #f7f7f7;
            }
            
            .location-map-wrapper {
                position: relative;
                width: 100%;
                height: 180px;
                overflow: hidden;
                border-radius: 8px 8px 0 0;
                background-color: #e5e5e5;
            }
            
            .location-map {
                width: 100% !important;
                height: 100% !important;
                border-radius: 8px 8px 0 0;
                overflow: hidden;
                z-index: 5;
            }
            
            .location-map canvas {
                z-index: 5;
                opacity: 1 !important;
                visibility: visible !important;
            }
            
            .location-static-map {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }
            
            .location-info {
                padding: 10px 12px;
                background-color: white;
                border-top: 1px solid #f0f0f0;
            }
            
            .location-description {
                font-weight: 500;
                margin-bottom: 4px;
                color: #333;
                font-size: 14px;
                line-height: 1.3;
                word-break: break-word;
            }
            
            .location-coordinates {
                font-size: 11px;
                color: #888;
                margin-bottom: 8px;
                font-family: monospace;
                line-height: 1.2;
            }
            
            .location-open-link {
                display: inline-flex;
                align-items: center;
                padding: 6px 10px;
                background-color: #03a9f4;
                color: white;
                text-decoration: none;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 500;
                transition: background-color 0.2s;
            }
            
            .location-open-link:hover {
                background-color: #0288d1;
            }
            
            .location-osm-attribution {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: rgba(255, 255, 255, 0.7);
                font-size: 9px;
                padding: 2px 4px;
                border-radius: 3px 0 0 0;
                z-index: 10;
                color: #333;
            }
            
            .location-error {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #d32f2f;
                font-size: 12px;
                padding: 8px;
                text-align: center;
                height: 100%;
                background-color: rgba(255,255,255,0.8);
            }
            
            /* MapLibre marker styling */
            .maplibregl-marker {
                z-index: 10;
            }
            
            /* Ensure canvas is visible */
            canvas.maplibregl-canvas {
                opacity: 1 !important;
                visibility: visible !important;
            }
            
            /* Media content styles */
            .media-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 4px;
            }
            
            .media-content img,
            .media-content video {
                max-width: 100%;
                border-radius: 8px;
                margin-bottom: 4px;
            }
            
            .download-link {
                font-size: 12px;
                color: #1976d2;
                text-decoration: none;
                cursor: pointer;
                margin-top: 4px;
            }
            
            .download-link:hover {
                text-decoration: underline;
            }
            
            .text-content {
                font-size: 14px;
                line-height: 1.4;
                color: #333;
            }
            
            .text-content.notice {
                font-style: italic;
                color: #666;
            }
            
            /* Read receipts */
            .read-receipts-container {
                display: flex;
                flex-direction: row;
                margin-top: 4px;
                justify-content: flex-end;
            }
            
            .read-receipt-avatar {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                overflow: hidden;
                margin-left: 2px;
            }
            
            /* Style the expanded content to make JSON data readable */
            .metadata-content {
                margin-top: 8px;
                padding: 8px;
                background-color: #f8f9fa;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
                overflow: auto;
                width: 100%;
                box-sizing: border-box;
            }
            
            .message.code {
                padding: 12px;
                font-family: monospace;
                font-size: 12px;
                line-height: 1.4;
                white-space: pre-wrap;
                word-break: break-word;
                background-color: #f5f5f5;
                color: #333;
                border-radius: 4px;
                overflow-x: auto;
                max-height: 400px;
                margin: 0;
            }
            
            .action-text {
                display: inline-block;
                font-size: 14px;
                line-height: 1;
            }
            
            .fa-solid {
                font-size: 14px;
                color: #1976D2;
            }
        `;
        this.shadowRoot.appendChild(mapStyles);
    }

    // Override getRenderTarget to use shadowRoot
    getRenderTarget() {
        return this.shadowRoot;
    }

    extractState(state) {
        return {
            matrix: state.matrix,
            chat: state.rooms.room.chat,
            settings: state.settings,
            readReceipts: state.rooms.room.readReceipts || {}
        }
    }

    formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const now = new Date();
        const isToday = date.toDateString() === now.toDateString();
        const isThisYear = date.getFullYear() === now.getFullYear();

        const time = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});

        if (isToday) {
            return time;
        } else if (isThisYear) {
            return `${date.toLocaleDateString([], {month: 'short', day: 'numeric'})} ${time}`;
        } else {
            return `${date.toLocaleDateString([], {year: 'numeric', month: 'short', day: 'numeric'})} ${time}`;
        }
    }

    formatSystemEvent(event) {
        const sender = event.getSender()?.split(':')[0].substring(1); // Remove @ and server part
        const eventType = event.getType();
        const content = event.getContent();
        
        switch (eventType) {
            case 'm.room.name':
                return `${sender} changed the room name to "${content.name}."`;
            case 'm.room.topic':
                return `${sender} changed the topic to "${content.topic}."`;
            case 'm.room.member':
                switch (content.membership) {
                    case 'join':
                        return `${sender} joined the room.`;
                    case 'invite':
                        return `${sender} invited ${event.getStateKey().split(':')[0].substring(1)}.`;
                    case 'leave':
                        return `${sender} left the room.`;
                    case 'ban':
                        return `${sender} banned ${event.getStateKey().split(':')[0].substring(1)}.`;
                }
                break;
            case 'm.room.power_levels':
                return `${sender} updated room permissions.`;
            case 'm.room.guest_access':
                return `${sender} ${content.guest_access === 'can_join' ? 'enabled' : 'disabled'} guest access.`;
            case 'm.room.history_visibility':
                return `${sender} changed history visibility to ${content.history_visibility}.`;
            case 'm.room.create':
                return `${sender} created this room.`;
            case 'm.room.join_rules':
                return `${sender} made the room ${content.join_rule}.`;
            case 'de.gematik.tim.room.name':
                return `${sender} changed the TI-Messenger room name to "${content.name}."`;
            case 'de.gematik.tim.room.topic':
                return `${sender} changed the TI-Messenger topic to "${content.topic}."`;
            case 'm.room.avatar':
                return content.url ? `${sender} changed the room avatar.` : `${sender} removed the room avatar.`;
            default:
                return `${sender} performed action: ${eventType}`;
        }
    }

    renderReadReceipts(event, roomId) {
        if (!this.state.settings?.readReceiptsEnabled) {
            return nothing;
        }

        if (!roomId || !event) {
            return nothing;
        }

        // Get the event ID from various possible locations
        const eventId = event.getId() || event.getId?.() || event.id;
        if (!eventId) {
            return nothing;
        }

        // Memoize receipts to prevent unnecessary re-renders
        if (this._memoizedReceipts?.eventId === eventId &&
            this._memoizedReceipts?.roomId === roomId) {
            return this._memoizedReceipts.result;
        }

        // Get receipts from both sources
        const stateReceipts = this.state.readReceipts[roomId]?.[eventId] || [];
        const client = getMatrixClient();
        const room = client.getRoom(roomId);
        let sdkReceipts = [];

        if (room) {
            const receiptStore = room.getReceiptsForEvent({
                getId: () => eventId,
                event: {room_id: roomId}
            });

            if (receiptStore) {
                sdkReceipts = receiptStore
                    .filter(receipt => receipt.type === 'm.read')
                    .map(receipt => receipt.userId);
            }
        }

        // Combine and deduplicate receipts
        const allReceipts = [...new Set([...stateReceipts, ...sdkReceipts])];
        const currentUserId = getMatrixClient()?.getUserId();

        if (!currentUserId || allReceipts.length === 0) {
            return nothing;
        }

        // Filter out current user and get user display names
        const otherReadReceipts = allReceipts
            .filter(userId => userId !== currentUserId)
            .map(userId => {
                const member = room?.getMember(userId);
                return {
                    userId,
                    displayName: member?.name || userId.split(':')[0].substring(1),
                    avatarUrl: member?.getAvatarUrl?.(client.baseUrl, 16, 16, 'crop') || null
                };
            })
            .sort((a, b) => a.displayName.localeCompare(b.displayName));

        if (otherReadReceipts.length === 0) {
            return nothing;
        }

        const result = html`
            <div class="read-receipts-container">
                ${otherReadReceipts.map(({userId, displayName, avatarUrl}) => html`
                    <div class="read-receipt-avatar"
                         title="${displayName}"
                         data-user-id="seen by ${userId}">
                        ${avatarUrl ? html`
                            <img src="${avatarUrl}"
                                 alt="${displayName}"
                                 width="14"
                                 height="14"
                                 @error=${this.handleAvatarError}/>
                        ` : html`
                            <kosyma-initials-circle
                                    name=${userId}
                                    size="small"
                                    title="seen by ${userId}">
                            </kosyma-initials-circle>
                        `}
                    </div>
                `)}
            </div>
        `;

        // Memoize the result
        this._memoizedReceipts = {
            eventId,
            roomId,
            result
        };

        return result;
    }

    handleAvatarError(e) {
        // Replace failed avatar with initials circle
        const container = e.target.parentElement;
        const userId = container.dataset.userId;
        const displayName = container.title;

        const initialsCircle = document.createElement('kosyma-initials-circle');
        initialsCircle.setAttribute('name', userId);
        initialsCircle.setAttribute('size', 'small');
        initialsCircle.title = displayName;

        container.replaceChild(initialsCircle, e.target);
    }

    view() {
        const roomId = this.getAttribute('roomId');
        const eventId = this.getAttribute('eventId');
        const isExpanded = this.state.chat.expanded_messages?.includes(eventId);
        const isReply = this.state.chat.reply?.includes(eventId);

        const room = getMatrixClient().getRoom(roomId);
        if (!room) {
            throw new Error('Room not found.');
        }

        // Find the event in the timeline, searching through all available timelines
        let event = null;
        
        try {
            // Method 1: Check the room's timelines
            const timelines = room.getLiveTimeline().getTimelineSet().getTimelines();
            for (const timeline of timelines) {
                const foundEvent = timeline.getEvents().find(e => e.getId() === eventId);
                if (foundEvent) {
                    event = foundEvent;
                    break;
                }
            }

            // Method 2: If not found, try the room.timeline property as fallback
            if (!event && room.timeline) {
                event = room.timeline.find(e => e.getId() === eventId);
            }
            
            // Method 3: Try getting the event directly from the client
            if (!event) {
                const directEvent = getMatrixClient().getEventTimeline(
                    room.getUnfilteredTimelineSet(), 
                    eventId
                );
                if (directEvent) {
                    event = directEvent.getEvent(eventId);
                }
            }
        } catch (error) {
            console.error('Error finding event:', error);
        }

        if (!event) {
            console.error(`Event not found: ${eventId} in room ${roomId}`);
            // Instead of throwing an error, render a placeholder for missing events
            return html`
                <div class='message error'>
                    <div class='system-message'>Event not found: ${eventId}</div>
                </div>
            `;
        }

        const eventType = event.getType();
        const sender = event.getSender();
        const timestamp = event.getTs();

        return html`
            <div class='message ${isExpanded ? 'expanded' : ''}' data-type=${eventType}>
                ${eventType === 'm.room.message' || eventType === 'm.room.encrypted' ? html`
                    <div class="message-row">
                        <div class='message-avatar'>
                            <kosyma-initials-circle name=${sender}></kosyma-initials-circle>
                        </div>
                        
                        <div class="message-container">
                            <div class="message-header-row">
                                <div class='message-header'>
                                    <span class='sender' title=${sender}>${sender}</span>
                                    <span class='timestamp'>${this.formatTimestamp(timestamp)}</span>
                                </div>
                                
                                <div class='message-actions'>
                                    <div class='action-button' @click=${() => this.onToggleReply(eventId)} title="Reply">
                                        <i class="fa-solid fa-reply"></i>
                                    </div>
                                    <div class='action-button' @click=${() => this.onToggleExpand(eventId)} title="View details">
                                        <i class="fa-solid ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div class='message-content'>
                                ${isExpanded ? this.renderExpandedContent(event) : this.renderBasicContent(event)}
                                ${isReply ? this.renderReplyContent(event) : nothing}
                                ${this.renderReadReceipts(event, roomId)}
                            </div>
                        </div>
                    </div>
                ` : html`
                    <div class='system-message'>
                        ${this.formatSystemEvent(event)}
                    </div>
                `}
            </div>
        `;
    }

    renderBasicContent(event) {
        const content = this.getBody(event);
        const url = this.getDownloadUrl(event);
        const thumbnailUrl = this.getThumbnailUrl(event);
        const isEmote = this.isEmoteMessage(event);
        const isNotice = this.isNoticeMessage(event);
        const isVideo = this.isVideoMessage(event);
        const isAudio = this.isAudioMessage(event);
        const isLocation = this.isLocationMessage(event);
        const sender = event.getSender()?.split(':')[0].substring(1); // Remove @ and server part

        // Check if this is a location message
        if (isLocation) {
            try {
                if (!event.event?.content?.geo_uri) {
                    return html`<div class="location-content">
                        <div class="location-error">Invalid location data</div>
                    </div>`;
                }
                
                const locationData = this.getLocationData(event);
                if (!locationData) {
                    return html`<div class="location-content">
                        <div class="location-error">Could not parse location data</div>
                    </div>`;
                }
                
                const eventId = event.event?.event_id || event.getId();
                // Create a CSS-safe class instead of using the raw event ID
                const mapClass = `map-container-${Math.random().toString(36).substring(2, 10)}`;
                this._mapClassToEventId = this._mapClassToEventId || {};
                this._mapClassToEventId[mapClass] = eventId;
                
                const osmUrl = this.getLocationExternalUrl(locationData);
                
                // Create a static map URL as fallback
                const staticMapUrl = `https://staticmap.openstreetmap.de/staticmap.php?center=${locationData.latitude},${locationData.longitude}&zoom=13&size=360x180&markers=${locationData.latitude},${locationData.longitude},red`;
                
                // Schedule map initialization after rendering
                setTimeout(() => this.initializeMapsInShadowDOM(), 100);
                
                return html`
                    <div class="location-content">
                        <div class="location-map-wrapper">
                            <!-- Static map as fallback -->
                            <img src="${staticMapUrl}" 
                                alt="Map showing location"
                                class="location-static-map" />
                                
                            <!-- Dynamic map container (overlays the static map) -->
                            <div class="location-map ${mapClass}"></div>
                            
                            <div class="location-osm-attribution">MapLibre | © OpenStreetMap contributors</div>
                        </div>
                        <div class="location-info">
                            <a href="${osmUrl}" target="_blank" class="location-open-link">Open in OpenStreetMap</a>
                        </div>
                    </div>
                `;
            } catch (error) {
                console.error("Error rendering location message:", error);
                return html`<div class="location-content">
                    <div class="location-error">Error displaying location: ${error.message}</div>
                </div>`;
            }
        }

        return html`
            ${url ? html`
                <div class='media-content'>
                    ${isVideo ? html`
                        <video controls 
                               style='max-width: 80%' 
                               preload="metadata"
                               poster=${thumbnailUrl || ''}
                               @error=${this.handleVideoError}>
                            <source src=${url} type=${this.getVideoMimeType(event)}>
                            Your browser does not support the video tag.
                        </video>
                        <div class="video-info">
                            ${this.getVideoInfo(event)}
                        </div>
                    ` : isAudio ? html`
                        <div class="audio-message">
                            <audio controls 
                                   preload="metadata"
                                   @error=${this.handleAudioError}>
                                <source src=${url} type=${this.getAudioMimeType(event)}>
                                Your browser does not support the audio element.
                            </audio>
                            <div class="audio-info">
                                ${this.getAudioInfo(event)}
                            </div>
                        </div>
                    ` : html`
                        <img src=${url} alt=${content} style='max-width: 80%'>
                    `}
                    <a class='download-link' @click=${() => window.open(url)}>Download</a>
                </div>
            ` : nothing}
            ${content ? html`
                <div class='text-content ${isNotice ? 'notice' : ''}'>
                    ${isEmote ? html`<em>* ${sender} ${content}</em>` : content}
                </div>
            ` : nothing}
        `;
    }

    renderExpandedContent = event => {
        // Get the raw event data, handling different event formats
        let rawEvent = event;
        
        if (event.event) {
            // Most common format - the raw event is in the 'event' property
            rawEvent = event.event;
        } 
        else if (typeof event.toJSON === 'function') {
            // For SDK events that have a toJSON method
            rawEvent = event.toJSON();
        }
        
        return html`
            ${this.renderBasicContent(event)}
            <div class='metadata-content'>
                <pre class='message code'>${JSON.stringify(rawEvent, null, 2)}</pre>
            </div>
        `;
    }

    renderReplyContent = event => html`
        <div class='metadata-content'>
            <kosyma-room-chat-reply eventId=${event.id}></kosyma-room-chat-reply>
        </div>
    `;

    getDownloadUrl = event => {
        if (!event || !event.getType) {
            return null;
        }
        
        let mxcUrl = null;
        if (event.getType() === 'm.room.message' && event.getContent) {
            mxcUrl = event.getContent().url;
        } else if (event.getType() === 'm.room.encrypted' && event.getClearContent) {
            mxcUrl = event.getClearContent()?.url;
        }
        
        if (mxcUrl) {
            const client = getMatrixClient();
            if (client && client.mxcUrlToHttp) {
                return client.mxcUrlToHttp(mxcUrl);
            }
        }
        return null;
    }

    onToggleExpand = eventId => {
        // Get existing expanded messages array or initialize empty array
        const expanded = [...(this.state.chat.expanded_messages || [])];
        const index = expanded.indexOf(eventId);
        
        // Toggle the expanded state
        if (index !== -1) {
            // Already expanded, remove it
            expanded.splice(index, 1);
        } else {
            // Not expanded, add it
            expanded.push(eventId);
        }
        
        // Update the state
        setRoomChatState({
            name: 'expanded_messages',
            value: expanded
        });
    }

    onToggleReply = eventId => {
        const expanded = [...(this.state.chat.reply || [])];
        const index = expanded.indexOf(eventId);
        if (index !== -1) {
            expanded.splice(index, 1);
        } else {
            expanded.push(eventId);
        }
        setRoomChatState({
            name: 'reply',
            value: expanded
        });
    }

    isEmoteMessage(event) {
        if (!event || !event.getType) {
            return false;
        }
        
        if (event.getType() === 'm.room.message') {
            return event.getContent && event.getContent().msgtype === 'm.emote';
        } else if (event.getType() === 'm.room.encrypted') {
            return event.getClearContent && event.getClearContent()?.msgtype === 'm.emote';
        }
        return false;
    }

    isNoticeMessage(event) {
        if (!event || !event.getType) {
            return false;
        }
        
        if (event.getType() === 'm.room.message') {
            return event.getContent && event.getContent().msgtype === 'm.notice';
        } else if (event.getType() === 'm.room.encrypted') {
            return event.getClearContent && event.getClearContent()?.msgtype === 'm.notice';
        }
        return false;
    }

    isVideoMessage(event) {
        if (!event || !event.getType) {
            return false;
        }
        
        if (event.getType() === 'm.room.message') {
            return event.getContent && event.getContent().msgtype === 'm.video';
        } else if (event.getType() === 'm.room.encrypted') {
            return event.getClearContent && event.getClearContent()?.msgtype === 'm.video';
        }
        return false;
    }

    isAudioMessage(event) {
        if (!event || !event.getType) {
            return false;
        }
        
        if (event.getType() === 'm.room.message') {
            return event.getContent && event.getContent().msgtype === 'm.audio';
        } else if (event.getType() === 'm.room.encrypted') {
            return event.getClearContent && event.getClearContent()?.msgtype === 'm.audio';
        }
        return false;
    }

    isLocationMessage(event) {
        if (!event) {
            return false;
        }
        
        // Check event content for location
        if (event.event?.content && event.event.content.msgtype === 'm.location') {
            return true;
        }
        
        // Check for encrypted event content
        if (event.getType && event.getType() === 'm.room.encrypted') {
            const clearContent = event.getClearContent && event.getClearContent();
            return clearContent && clearContent.msgtype === 'm.location';
        }
        
        return false;
    }

    getVideoMimeType(event) {
        if (!event || !event.getType) {
            return 'video/mp4'; // Default
        }
        
        let info = null;
        if (event.getType() === 'm.room.message' && event.getContent) {
            info = event.getContent().info;
        } else if (event.getType() === 'm.room.encrypted' && event.getClearContent) {
            info = event.getClearContent()?.info;
        }
        
        // If no mimetype specified, try to determine from the URL
        if (!info?.mimetype && event.getContent && event.getContent().url) {
            const url = event.getContent().url;
            if (url.toLowerCase().endsWith('.mp4')) {
                return 'video/mp4';
            } else if (url.toLowerCase().endsWith('.webm')) {
                return 'video/webm';
            } else if (url.toLowerCase().endsWith('.ogg') || url.toLowerCase().endsWith('.ogv')) {
                return 'video/ogg';
            }
        }
        
        return info?.mimetype || 'video/mp4'; // Default to mp4 if not specified
    }

    getAudioMimeType(event) {
        if (!event || !event.getType) {
            return 'audio/mpeg'; // Default
        }
        
        let info = null;
        if (event.getType() === 'm.room.message' && event.getContent) {
            info = event.getContent().info;
        } else if (event.getType() === 'm.room.encrypted' && event.getClearContent) {
            info = event.getClearContent()?.info;
        }
        
        // If no mimetype specified, try to determine from the URL
        if (!info?.mimetype && event.getContent && event.getContent().url) {
            const url = event.getContent().url;
            if (url.toLowerCase().endsWith('.mp3')) {
                return 'audio/mpeg';
            } else if (url.toLowerCase().endsWith('.wav')) {
                return 'audio/wav';
            } else if (url.toLowerCase().endsWith('.ogg')) {
                return 'audio/ogg';
            } else if (url.toLowerCase().endsWith('.m4a')) {
                return 'audio/mp4';
            } else if (url.toLowerCase().endsWith('.webm')) {
                return 'audio/webm';
            }
        }
        
        return info?.mimetype || 'audio/mpeg'; // Default to MP3 if not specified
    }

    getBody(event) {
        if (!event) {
            return null;
        }
        
        const eventType = event.getType();
        if (eventType === 'm.room.message') {
            if (event.getClearContent && event.getClearContent()?.msgtype === 'm.bad.encrypted') {
                return 'Historical messages are not available on this device';
            }
            return event.getContent ? event.getContent().body : null;
        } else if (eventType === 'm.room.encrypted') {
            return event.getClearContent ? event.getClearContent()?.body : null;
        }
        return null;
    }

    getThumbnailUrl(event) {
        if (!event || !event.getType) {
            return null;
        }
        
        let info = null;
        if (event.getType() === 'm.room.message' && event.getContent) {
            info = event.getContent().info;
        } else if (event.getType() === 'm.room.encrypted' && event.getClearContent) {
            info = event.getClearContent()?.info;
        }
        
        if (info?.thumbnail_url) {
            const client = getMatrixClient();
            if (client && client.mxcUrlToHttp) {
                return client.mxcUrlToHttp(info.thumbnail_url);
            }
        }
        
        return null;
    }
    
    getVideoInfo(event) {
        if (!event || !event.getType) {
            return '';
        }
        
        let info = null;
        if (event.getType() === 'm.room.message' && event.getContent) {
            info = event.getContent().info;
        } else if (event.getType() === 'm.room.encrypted' && event.getClearContent) {
            info = event.getClearContent()?.info;
        }
        
        if (!info) return '';
        
        const parts = [];
        
        if (info.duration) {
            const minutes = Math.floor(info.duration / 60000);
            const seconds = Math.floor((info.duration % 60000) / 1000);
            parts.push(`${minutes}:${seconds.toString().padStart(2, '0')}`);
        }
        
        if (info.w && info.h) {
            parts.push(`${info.w}×${info.h}`);
        }
        
        if (info.size) {
            const sizeInMB = (info.size / (1024 * 1024)).toFixed(1);
            parts.push(`${sizeInMB} MB`);
        }
        
        return parts.join(' • ');
    }

    getAudioInfo(event) {
        if (!event || !event.getType) {
            return '';
        }
        
        let info = null;
        if (event.getType() === 'm.room.message' && event.getContent) {
            info = event.getContent().info;
        } else if (event.getType() === 'm.room.encrypted' && event.getClearContent) {
            info = event.getClearContent()?.info;
        }
        
        if (!info) return '';
        
        const parts = [];
        
        if (info.duration) {
            const minutes = Math.floor(info.duration / 60000);
            const seconds = Math.floor((info.duration % 60000) / 1000);
            parts.push(`${minutes}:${seconds.toString().padStart(2, '0')}`);
        }
        
        if (info.size) {
            const sizeInMB = (info.size / (1024 * 1024)).toFixed(1);
            parts.push(`${sizeInMB} MB`);
        }
        
        return parts.join(' • ');
    }

    handleVideoError(e) {
        console.error('Video playback error:', e);
        // Try to recover by removing the mime type
        const video = e.target;
        const source = video.querySelector('source');
        if (source && source.getAttribute('type')) {
            source.removeAttribute('type');
            video.load(); // Reload the video
        }
    }

    handleAudioError(e) {
        console.error('Audio playback error:', e);
        // Try to recover by removing the mime type
        const audio = e.target;
        const source = audio.querySelector('source');
        if (source && source.getAttribute('type')) {
            source.removeAttribute('type');
            audio.load(); // Reload the audio
        }
    }

    getLocationData(event) {
        if (!event) return null;
        
        // Get content from different possible sources
        let content = null;
        
        if (event.event?.content && event.event.content.msgtype === 'm.location') {
            content = event.event.content;
        } else if (event.getType && event.getType() === 'm.room.encrypted') {
            content = event.getClearContent && event.getClearContent();
        }
        
        if (!content || !content.geo_uri) return null;
        
        try {
            const geoUri = content.geo_uri;
            const match = geoUri.match(/geo:([-0-9.]+),([-0-9.]+)/);
            
            if (!match) return null;
            
            const latitude = parseFloat(match[1]);
            const longitude = parseFloat(match[2]);
            
            if (isNaN(latitude) || isNaN(longitude)) return null;
            
            return {
                latitude,
                longitude,
                description: content.body || 'Shared location'
            };
        } catch (error) {
            console.error('Error parsing location data:', error);
            return null;
        }
    }

    getLocationExternalUrl(locationData) {
        if (!locationData || !locationData.latitude || !locationData.longitude) {
            return '#';
        }
        
        return `https://www.openstreetmap.org/?mlat=${locationData.latitude}&mlon=${locationData.longitude}#map=14/${locationData.latitude}/${locationData.longitude}`;
    }

    updated() {
        super.updated();
        
        // Initialize maps after the component updates
        requestAnimationFrame(() => {
            this.initializeMapsInShadowDOM();
        });
    }
    
    initializeMapsInShadowDOM() {
        if (!this.shadowRoot) return;
        
        const eventId = this.getAttribute('eventId');
        const roomId = this.getAttribute('roomId');
        
        if (!eventId || !roomId) return;
        
        const event = this.findEvent(eventId);
        
        if (event && this.isLocationMessage(event)) {
            const locationData = this.getLocationData(event);
            if (!locationData) return;
            
            // Initialize map class to event ID map if not already done
            this._mapClassToEventId = this._mapClassToEventId || {};
            
            // Find map containers using classes instead of direct IDs
            const mapContainers = this.shadowRoot.querySelectorAll('.location-map');
            if (!mapContainers || mapContainers.length === 0) return;
            
            // Find the right container for this event
            let mapContainer = null;
            for (const container of mapContainers) {
                // Check if this container belongs to this event
                for (const className of container.classList) {
                    if (className.startsWith('map-container-') && 
                        this._mapClassToEventId[className] === eventId) {
                        mapContainer = container;
                        break;
                    }
                }
                if (mapContainer) break;
            }
            
            if (!mapContainer || !mapContainer.isConnected) return;
            
            // Check if we already initialized this map
            if (this._mapInstances[eventId]) return;
            
            // Create the map
            this.createMap(mapContainer, eventId, locationData);
        }
    }
    
    createMap(mapContainer, eventId, locationData) {
        try {
            // Simple map style that works with OpenStreetMap
            const mapStyle = {
                version: 8,
                sources: {
                    'osm': {
                        type: 'raster',
                        tiles: [
                            'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
                            'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
                            'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        ],
                        tileSize: 256,
                        attribution: 'MapLibre | © OpenStreetMap contributors'
                    }
                },
                layers: [
                    {
                        id: 'osm',
                        type: 'raster',
                        source: 'osm',
                        minzoom: 0,
                        maxzoom: 19
                    }
                ]
            };
            
            const map = new maplibregl.Map({
                container: mapContainer,
                style: mapStyle,
                center: [locationData.longitude, locationData.latitude],
                zoom: 13,
                interactive: false,
                attributionControl: false
            });
            
            // Disable interactions for a static map
            map.dragPan.disable();
            map.scrollZoom.disable();
            map.doubleClickZoom.disable();
            if (map.touchZoomRotate) map.touchZoomRotate.disable();
            
            // Add a marker when map is loaded
            map.once('load', () => {
                new maplibregl.Marker({
                    color: '#1E88E5'
                })
                .setLngLat([locationData.longitude, locationData.latitude])
                .addTo(map);
                
                // Force resize to ensure proper rendering
                map.resize();
            });
            
            // Store the map instance
            this._mapInstances[eventId] = map;
            
            return map;
        } catch (error) {
            console.error('Error creating map:', error);
            if (mapContainer) {
                mapContainer.innerHTML = '<div class="location-error">Failed to load map</div>';
            }
            return null;
        }
    }
    
    findEvent(eventId) {
        const roomId = this.getAttribute('roomId');
        const room = getMatrixClient().getRoom(roomId);
        if (!room) return null;
        
        // Find the event in the timeline
        let event = null;
        try {
            // Method 1: Check the room's timelines
            const timelines = room.getLiveTimeline().getTimelineSet().getTimelines();
            for (const timeline of timelines) {
                const foundEvent = timeline.getEvents().find(e => e.getId() === eventId);
                if (foundEvent) {
                    event = foundEvent;
                    break;
                }
            }
            
            // Method 2: If not found, try the room.timeline property as fallback
            if (!event && room.timeline) {
                event = room.timeline.find(e => e.getId() === eventId);
            }
            
            // Method 3: Try getting the event directly from the client
            if (!event) {
                const directEvent = getMatrixClient().getEventTimeline(
                    room.getUnfilteredTimelineSet(), 
                    eventId
                );
                if (directEvent) {
                    event = directEvent.getEvent(eventId);
                }
            }
        } catch (error) {
            console.error('Error finding event:', error);
        }
        
        return event;
    }
    
    disconnectedCallback() {
        // Clean up maps when component is destroyed
        Object.values(this._mapInstances).forEach(map => {
            try {
                if (map) map.remove();
            } catch (e) {
                console.error('Error removing map:', e);
            }
        });
        this._mapInstances = {};
        super.disconnectedCallback();
    }
}

customElements.define('kosyma-room-chat-message', RoomChatMessage);
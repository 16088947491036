import BElement from '../../BElement.js';
import {html} from 'lit-html';
import '../css/SecurityCheck.css';
import {resetSecurityCheckState, setSecurityCheckState} from '../control/SecurityCheckControl.js';

class SecurityCheckPassword extends BElement {
    constructor() {
        super();
        this.password = '';
        this.onContinue = (password) => {
            console.log('Default onContinue called with password:', password);
        };
        this.onCancel = () => {
            console.log('Default onCancel called');
        };
    }

    connectedCallback() {
        super.connectedCallback();
        
        // We need to wait for the component to render before accessing the input element
        setTimeout(() => {
            const element = this.querySelector('.form-control');
            if (element) {
                // Add has-content class if the input already has a value
                if (element.value) {
                    element.classList.add('has-content');
                }
                
                // Add event listeners to handle focus, blur, and input events
                element.addEventListener('input', () => {
                    element.value
                        ? element.classList.add('has-content')
                        : element.classList.remove('has-content');
                });
                
                element.addEventListener('focus', () => {
                    element.classList.add('has-content');
                });
                
                element.addEventListener('blur', () => {
                    if (!element.value) {
                        element.classList.remove('has-content');
                    }
                });
                
                // Auto-focus the password input
                element.focus();
            }
        }, 0);
    }

    extractState(state) {
        return {
            password: state?.securityCheck?.password || '',
        };
    }

    view() {
        const label = this.getAttribute('text') || 'Security Check';

        return html`
            <div class="securityCheck">
                <div class="content-area">
                    <div class="inner">
                        <h2>${label}</h2>
                        <div class='form-group'>
                            <input
                                class='form-control'
                                type='password'
                                id='password-input'
                                name='password'
                                @input=${this.handleInputChange}
                            />
                            <label for='password-input' class='form-label'>Password</label>
                        </div>
                        <div class="button-group">
                            <kosyma-button
                                    identifier="continue"
                                    label="Continue"
                                    variant="sky-blue"
                                    role="primary"
                                    @click=${this.handleContinue}
                            ></kosyma-button>
                            <kosyma-button
                                    identifier="cancel"
                                    label="Cancel"
                                    variant="light-grey"
                                    role="secondary"
                                    @click=${this.handleCancel}
                            ></kosyma-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    handleInputChange = (event) => {
        this.password = event.target.value;
        setSecurityCheckState({password: this.password});
    };

    handleContinue = () => {
        // Get the password directly from the input element
        const passwordInput = this.querySelector('#password-input');
        const passwordValue = passwordInput ? passwordInput.value : '';
        
        // Use the direct value from the input element
        this.onContinue(passwordValue || this.password);
        resetSecurityCheckState();
    };

    handleCancel = () => {
        resetSecurityCheckState();
        this.onCancel();
    };
}

customElements.define('kosyma-securitycheck-password', SecurityCheckPassword);
import {html} from 'lit-html';
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js';
import OrgAdminUsersClient from '../control/OrgAdminUsersClient.js';
import {setUserDisplayNameState} from '../control/OrgAdminControl.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';

class OrgAdminDisplayName extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix,
            user_display_name: state.orgAdmin.user_display_name
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Update Display Name</h2>
                    <div class='inner'>
                        <kosyma-input-text
                                identifier='first_name'
                                label='First Name'
                                name='first_name'
                                value=${this.state.user_display_name.first_name}
                                @keyup=${(e) => this.onChangeValue(e)}
                        ></kosyma-input-text>
                        <kosyma-input-text
                                identifier='last_name'
                                label='Last Name'
                                name='last_name'
                                value=${this.state.user_display_name.last_name}
                                @keyup=${(e) => this.onChangeValue(e)}
                        ></kosyma-input-text>
                        <div class='button-group' style="display: flex; gap: 10px;">
                            <kosyma-button
                                    identifier='back'
                                    label='Back'
                                    variant='light-grey'
                                    role='secondary'
                                    style="flex: 1;"
                                    @click=${() => Router.go('/messenger/orgadmin/users/list')}>
                            </kosyma-button>
                            <kosyma-button
                                    identifier='save'
                                    label='Save'
                                    variant='sky-blue'
                                    role='primary'
                                    style="flex: 1;"
                                    @click=${this.onSave}>
                            </kosyma-button>
                        </div>
                    </div>
                </div>
            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setUserDisplayNameState({name, value});
    }

    onSave = () => {
        const userId = this.location.params.id;
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token)
            .updateDisplayName(
                userId,
                this.state.user_display_name.first_name,
                this.state.user_display_name.last_name
            )
            .then(r => {
                addInformationMessage('Display name updated successfully');
                Router.go('/messenger/orgadmin/users/list');
            })
            .catch(err => addErrorMessage(err));
    }
}

customElements.define('kosyma-orgadmin-display-name', OrgAdminDisplayName); 
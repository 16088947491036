import { html } from 'lit-html';
import '../css/components.css';
import BElement from '../../BElement.js';

class InputText extends BElement {
    connectedCallback() {
        super.connectedCallback();

        const element = this.querySelector('.form-control');
        if (element.value) {
            element.classList.add('has-content');
        }

        element.addEventListener('input', () => {
            element.value
                ? element.classList.add('has-content')
                : element.classList.remove('has-content');
        });

        element.addEventListener('focus', () => {
            element.classList.add('has-content');
        });

        element.addEventListener('blur', () => {
            if (!element.value) {
                element.classList.remove('has-content');
            }
        });
        const autofocus = this.getAttribute('autofocus');
        const input = this.querySelector('input');
        if (!!autofocus) {
            input.focus();
        }
    }

    view() {
        const type = this.getAttribute('type');
        const label = this.getAttribute('label');
        const name = this.getAttribute('name');
        const identifier = this.getAttribute('identifier');
        const value = this.getAttribute('value');
        const readonly = this.getAttribute('readonly');
        const autocomplete = this.getAttribute('autocomplete');

        return html`
            <div class='form-group'>
                <input
                    class='form-control'
                    type=${type ? type : 'text'}
                    autocomplete=${autocomplete ? autocomplete : 'off'}
                    id=${identifier ? identifier : ''}
                    name=${name}
                    .value=${value}
                    ?readonly=${(!!readonly)}
                    ?aria-readonly=${(!!readonly)}
                />
                <label for='${identifier}' class='form-label'> ${label} </label>
            </div>
        `;
    }
}

customElements.define('kosyma-input-text', InputText);

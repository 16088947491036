import * as maplibregl from 'maplibre-gl';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';

/**
 * Find the appropriate map style URL based on client configuration
 * Fallback to OpenStreetMap if no style is configured
 */
export const findMapStyleUrl = () => {
    // Define a style that works well with OpenStreetMap tiles
    return {
        version: 8,
        sources: {
            'osm': {
                type: 'raster',
                tiles: [
                    // keep these tile layers for the map
                    'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
                ],
                tileSize: 256,
                attribution: 'MapLibre | © OpenStreetMap contributors'
            }
        },
        layers: [
            {
                id: 'osm',
                type: 'raster',
                source: 'osm',
                minzoom: 0,
                maxzoom: 19
            }
        ],
        // Germany-centered default view
        center: [10.4515, 51.1657],
        zoom: 7
    };
};

/**
 * Create a new MapLibre map instance
 * @param {string} containerId - DOM element ID for the map container
 * @param {boolean} interactive - Whether the map should be interactive
 * @param {object} options - Additional map options
 * @param {Function} onError - Error callback
 * @returns {maplibregl.Map} The created map instance
 */
export const createMap = (
    containerId,
    interactive = true,
    options = {},
    onError = null
) => {
    try {
        const container = document.getElementById(containerId);
        if (!container) {
            throw new Error(`Map container with ID "${containerId}" not found`);
        }
        
        const style = findMapStyleUrl();
        
        // Default map options
        const defaultOptions = {
            zoom: 6,
            center: [10.4515, 51.1657],
            attributionControl: true,
            trackResize: false,
            dragRotate: false
        };
        
        // Create and return the map
        const map = new maplibregl.Map({
            container,
            style,
            interactive,
            ...defaultOptions,
            ...options
        });
        
        // Disable rotation
        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
        
        // Add navigation controls in top-right corner only
        map.addControl(new maplibregl.NavigationControl({
            showCompass: false
        }), 'top-right');
        
        // Add error handling
        map.on("error", (e) => {
            console.error("Map error:", e);
            if (onError) onError(new Error("Failed to load map"));
            else addErrorMessage("Failed to load map. Please check your internet connection.");
        });
        
        // If no custom bounds were specified in options, fit to Germany bounds
        if (!options.bounds) {
            // Define Germany bounds (southwest corner, northeast corner)
            const germanyBounds = [
                [5.9, 47.3], // Southwest coordinates [lng, lat]
                [15.0, 55.0]  // Northeast coordinates [lng, lat]
            ];

            // Fit the map to Germany's bounds with some padding
            map.fitBounds(germanyBounds, {
                padding: 20,
                maxZoom: 7,
                duration: 0 // No animation on initial load
            });
        }
        
        return map;
    } catch (e) {
        console.error("Failed to render map", e);
        if (onError) onError(e);
        else addErrorMessage(`Error initializing map: ${e.message}`);
        throw e;
    }
};

/**
 * Create a marker on the map
 * @param {object} coords - Coordinates {latitude, longitude}
 * @param {boolean} draggable - Whether the marker should be draggable
 * @param {string} color - Color of the marker
 * @returns {maplibregl.Marker} The created marker
 */
export const createMarker = (coords, draggable = false, color = '#1E88E5') => {
    if (!coords || !coords.latitude || !coords.longitude) {
        throw new Error("Invalid coordinates provided");
    }
    
    const marker = new maplibregl.Marker({
        color,
        draggable
    }).setLngLat([coords.longitude, coords.latitude]);
    
    return marker;
};

/**
 * Add basic navigation controls to a map
 * @param {maplibregl.Map} map - The map instance
 */
export const addMapControls = (map) => {
    if (!map) return;
    
    // Add navigation controls (zoom in/out)
    map.addControl(new maplibregl.NavigationControl({
        showCompass: false // Hide rotation control
    }), 'top-right');
    
    // Don't add attribution control - we're using the attribution from the style
};

/**
 * Perform reverse geocoding to get location name from coordinates
 * @param {number} latitude 
 * @param {number} longitude 
 * @returns {Promise<string>} Location name
 */
export const reverseGeocode = async (latitude, longitude) => {
    try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
            {
                headers: {
                    'Accept-Language': 'en',
                    'User-Agent': 'TI-Messenger-Client'
                }
            }
        );
        
        if (!response.ok) {
            throw new Error(`Reverse geocoding failed: ${response.statusText}`);
        }
        
        const data = await response.json();
        
        if (data && data.display_name) {
            return data.display_name;
        }
        return "Unknown location";
    } catch (error) {
        console.error('Reverse geocoding error:', error);
        return "Location name unavailable";
    }
};

/**
 * Geocode a search query to get location coordinates
 * @param {string} query - Search query
 * @returns {Promise<Array>} Array of location results
 */
export const geocodeSearch = async (query) => {
    if (!query || !query.trim()) {
        return [];
    }
    
    try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&limit=5`,
            {
                headers: {
                    'Accept-Language': 'en',
                    'User-Agent': 'TI-Messenger-Client'
                }
            }
        );
        
        if (!response.ok) {
            throw new Error(`Search failed: ${response.statusText}`);
        }
        
        const results = await response.json();
        
        // Format results for display
        return results.map(item => ({
            id: item.place_id,
            place_name: item.display_name,
            place_type: [item.type, item.class].filter(Boolean),
            center: [parseFloat(item.lon), parseFloat(item.lat)],
            longitude: parseFloat(item.lon),
            latitude: parseFloat(item.lat)
        }));
    } catch (error) {
        console.error('Geocoding error:', error);
        throw error;
    }
};

/**
 * Create a map specifically for displaying a location message in chat
 * @param {string} containerId - DOM element ID for the map container
 * @param {object} coords - Coordinates {latitude, longitude}
 * @param {Function} onError - Error callback
 * @returns {object} - Object containing map instance and marker
 */
export const createMessageMap = (containerId, coords, onError = null) => {
    if (!coords || !coords.latitude || !coords.longitude) {
        const error = new Error("Invalid coordinates provided");
        if (onError) onError(error);
        return { map: null, marker: null };
    }
    
    try {
        // Get the container element directly
        const container = document.getElementById(containerId);
        if (!container) {
            const error = new Error(`Map container with ID "${containerId}" not found`);
            if (onError) onError(error);
            return { map: null, marker: null };
        }
        
        // Create map directly with container element
        const map = new maplibregl.Map({
            container: container,
            style: findMapStyleUrl(),
            center: [coords.longitude, coords.latitude],
            zoom: 14,
            interactive: false,
            attributionControl: {
                compact: false // Display full attribution
            },
            trackResize: true,
            dragRotate: false
        });
        
        // Disable rotation
        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
        
        // Add error handling
        map.on("error", (e) => {
            console.error("Map error:", e);
            if (onError) onError(new Error("Failed to load map tiles"));
        });
        
        // Set the marker variable for return
        let marker = null;
        
        // Add a marker when map is loaded
        map.once('load', () => {
            // Create and add a marker
            marker = new maplibregl.Marker({
                color: '#1E88E5'
            }).setLngLat([coords.longitude, coords.latitude])
              .addTo(map);
            
            // Resize the map to ensure it renders correctly
            map.resize();
        });
        
        return { map, marker };
    } catch (error) {
        console.error("Failed to create message map:", error);
        if (onError) onError(error);
        return { map: null, marker: null };
    }
}; 
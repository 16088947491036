import {createAction} from '@reduxjs/toolkit'
import store from '../../store.js'
import {generateUUID} from '../../UUIDGenerator.js';
import {appName} from '../../app.config.js'

export const addNotificationAction = createAction('addNotificationAction')
export const addNotificationState = state => {
    state.id = generateUUID();
    state.time = Date.now();
    // Set closeable to true by default if not explicitly set to false
    if (state.closeable === undefined) {
        state.closeable = true;
    }
    store.dispatch(addNotificationAction(state))
}

export const removeNotificationAction = createAction('removeNotificationAction')
export const removeNotificationState = state => {
    store.dispatch(removeNotificationAction(state))
}

export const LAST_SECURITY_NOTIFICATION_KEY = `lastSecurityNotificationShown`;
const NOTIFICATION_INTERVAL = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

/**
 * Adds a security-critical update notification that informs users about required updates.
 * The notification is shown only once per month.
 */
export const addSystemSecurityUpdateNotification = () => {
    const lastShown = localStorage.getItem(LAST_SECURITY_NOTIFICATION_KEY) || 0;
    const now = Date.now();

    if (!lastShown || (now - parseInt(lastShown)) > NOTIFICATION_INTERVAL) {
        addNotificationState({
            title: 'System Security Update Required',
            text: 'To ensure the secure operation of the TI-M client, please install all available security updates for your operating system. Regular system updates help protect your data and maintain the security of your communications.',
            type: 'security-critical',
            closeable: false // Security notifications cannot be closed
        });
        localStorage.setItem(LAST_SECURITY_NOTIFICATION_KEY, now.toString());
    }
}

/**
 * Example function to add a non-closeable notification
 * @param {string} title - The notification title
 * @param {string} text - The notification text
 * @param {string} type - The notification type (e.g., 'warning', 'info', 'security-critical')
 * @param {string} [link] - Optional link to navigate to when clicked
 */
export const addNonCloseableNotification = (title, text, type, link) => {
    addNotificationState({
        title,
        text,
        type,
        link,
        closeable: false // This notification cannot be closed by the user
    });
}

/**
 * Example function to add a standard closeable notification
 * @param {string} title - The notification title
 * @param {string} text - The notification text
 * @param {string} type - The notification type (e.g., 'warning', 'info')
 * @param {string} [link] - Optional link to navigate to when clicked
 */
export const addCloseableNotification = (title, text, type, link) => {
    addNotificationState({
        title,
        text,
        type,
        link
        // closeable is true by default, no need to specify
    });
}

/**
 * Debug function to log the current state of notifications
 */
export const debugNotifications = () => {
    const state = store.getState();
    console.log('Current notifications:', state.notifications);
    
    if (state.notifications && state.notifications.notifications) {
        state.notifications.notifications.forEach((notification, index) => {
            console.log(`Notification ${index}:`, notification);
            console.log(`- title: ${notification.title}`);
            console.log(`- text: ${notification.text}`);
            console.log(`- type: ${notification.type}`);
            console.log(`- link/action: ${notification.link || notification.action}`);
            console.log(`- closeable: ${notification.closeable}`);
        });
    }
}

/**
 * Test function to create sample notifications for testing
 * This function creates both closeable and non-closeable notifications
 */
export const createTestNotifications = () => {
    // Create a closeable notification
    addNonCloseableNotification(
        'Sample', 
        'Test', 
        'info', 
        '/messenger/rooms/'
    );
    
    // Create a non-closeable notification
    addCloseableNotification(
        'New Device Detected', 
        'A new device has been added to your account at ' + new Date().toLocaleTimeString(), 
        'warning', 
        '/messenger/devices/verification/receiver/'
    );
    
    console.log('Test notifications created');
}

/**
 * Test function to create a non-closeable notification for testing
 */
export const testNonCloseableNotification = () => {
    console.log('Creating test non-closeable notification');
    
    addNotificationState({
        title: 'New Device Detected (Test)',
        text: 'A new device has been added to your account at ' + new Date().toLocaleTimeString(),
        type: 'warning',
        link: '/messenger/devices/verification/receiver/',
        closeable: false
    });
    
    console.log('Test non-closeable notification created');
    debugNotifications();
}

/**
 * Test function to create a closeable notification for testing
 */
export const testCloseableNotification = () => {
    console.log('Creating test closeable notification');
    
    addNotificationState({
        title: 'Sample Notification (Test)',
        text: 'This is a test notification that can be closed',
        type: 'info',
        link: '/messenger/rooms/',
        closeable: true
    });
    
    console.log('Test closeable notification created');
    debugNotifications();
}

// Export the function for use in the browser console
window.testNonCloseableNotification = testNonCloseableNotification;
window.testCloseableNotification = testCloseableNotification;

// Call debug function on next tick to see initial state
// setTimeout(debugNotifications, 0);


import {html} from 'lit-html'
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js'
import {resetState, setBaseUrlState, setChangeServerState} from '../control/MatrixControl.js';
import {getMatrixClient} from '../control/MatrixClient.js';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';

class ChangeServer extends BElement {
    allowedDomains = [
        'gesundheitsmessenger.com',
        'ujumbelabs.com',
        'ujumbe.io'
    ];

    connectedCallback() {
        super.connectedCallback();
        setChangeServerState({name: 'url', value: this.state.base_url})
    }

    extractState(state) {
        return {
            base_url: state.matrix.base_url,
            change_server: state.matrix.change_server,
        }
    }

    validateDomain(url) {
        try {
            const hostname = new URL(url).hostname;
            return this.allowedDomains.some(domain => 
                hostname === domain || hostname.endsWith('.' + domain)
            );
        } catch (e) {
            return false;
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Change Server (${this.state.base_url})</h2>
                    <kosyma-input-text
                            identifier='url'
                            label='URL'
                            name='url'
                            autofocus='true'
                            value=${this.state.change_server.url}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <div style="color: #666; font-size: 0.9em; margin: 8px 0;">
                        Allowed domains: ${this.allowedDomains.join(', ')} and their subdomains
                    </div>
                    <div class='button-group' style="display: inline-flex; gap: 10px;">
                        <kosyma-button
                                identifier='back'
                            label='Back'
                            variant='light-grey'
                            role='secondary'
                            style="flex: 1;"
                            @click=${() => Router.go('/login/')}>
                        </kosyma-button>
                        <kosyma-button
                            identifier='change'
                            label='Change'
                            variant='sky-blue'
                            role='primary'
                            style="flex: 1;"
                            @click=${() => this.onChange()}
                        ></kosyma-button>
                    </div>
                </div>
            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setChangeServerState({name, value});
    }

    onChange = () => {
        const url = this.state.change_server.url;
        if (!this.validateDomain(url)) {
            addErrorMessage('Invalid domain. Only gesundheitsmessenger.com, ujumbelabs.com, ujumbe.io and their subdomains are allowed.');
            return;
        }

        setBaseUrlState(url);
        resetState();
        if (getMatrixClient()) {
            getMatrixClient().logout().then(res => console.log('res'));
        }
        Router.go('/login')
    }
}

customElements.define('kosyma-change-server', ChangeServer)

import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';

class Content extends BElement {
   extractState(state) {
  }

   view() {
      return html`
          <kosyma-header></kosyma-header>
          <kosyma-matrix></kosyma-matrix>
          <main class='content-box' id='outlet'></main>
          <kosyma-messages></kosyma-messages>
      `;
   }
}

customElements.define('kosyma-content', Content);

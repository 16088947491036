import {html, nothing} from 'lit-html'
import {Router} from '@vaadin/router'
import BElement from '../../BElement.js'
import {getMatrixClient} from '../../matrix/control/MatrixClient.js'
import {setDeviceListState} from '../control/DevicesControl.js'
import {addInformationMessage, addErrorMessage} from '../../messages/control/MessagesControl.js';
import {getDevices, deleteDevice, checkForNewDevicesManually} from '../control/DevicesClient.js';
import './DeviceList.css';

class DeviceList extends BElement {
    connectedCallback() {
        super.connectedCallback();
        this.updateDeviceList();
    }

    extractState(state) {
        return {
            device_id: state.matrix.session.device_id,
            list: state.devices.list,
            matrix: state.matrix
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Devices</h2>
                    <div class="device-actions">
                        <kosyma-button
                                identifier='refresh'
                                label='Refresh List'
                                variant='light-grey'
                                role='secondary'
                                @click=${this.updateDeviceList}
                        ></kosyma-button>
                        <kosyma-button
                                identifier='check-new'
                                label='Check for New Devices'
                                variant='sky-blue'
                                role='primary'
                                @click=${this.onCheckNewDevices}
                        ></kosyma-button>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>User</th>
                            <th>Verified</th>
                            <th>Last Seen</th>
                            <th>IP Address</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        ${this.state.list.results.map(e => html`
                            <tr class="${e.device_id === this.state.matrix.session.device_id ? 'current-device' : ''}">
                                <td>${e.device_id}</td>
                                <td>${e.display_name || 'Unnamed Device'}</td>
                                <td>${e.user_id}</td>
                                <td>
                                    ${e.is_verified ? 
                                        html`<span class="verified">✓ Verified</span>` : 
                                        html`<span class="unverified">✗ Unverified</span>`}
                                </td>
                                <td>${e.last_seen_ts ? this.formatDate(e.last_seen_ts) : 'Unknown'}</td>
                                <td>${e.last_seen_ip || 'Unknown'}</td>
                                <td>
                                    ${e.device_id !== this.state.matrix.session.device_id ?
                                            html`
                                                <kosyma-button
                                                        identifier='delete'
                                                        label='Delete'
                                                        variant='warm-red'
                                                        role='destructive'
                                                        @click=${() => setDeviceListState({
                                                            name: 'delete_id',
                                                            value: e.device_id
                                                        })}
                                                ></kosyma-button>
                                            `
                                            : html`<span class="current-device-label">Current Device</span>`}
                                    <kosyma-button 
                                                   label='Verify'
                                                   variant='sky-blue'
                                                   role='primary'
                                                   @click=${() => this.onVerify(e.device_id)}
                                                   identifier='verify'
                                                   ?disabled=${e.is_verified || e.device_id === this.state.matrix.session.device_id}
                                    ></kosyma-button>
                                </td>
                            </tr>
                        `)}
                        </tbody>
                    </table>
                    ${this.state.list.delete_id ? html`
                        <kosyma-securitycheck-password
                                text='Delete Device ${this.state.list.delete_id}'
                                .onCancel=${() => setDeviceListState({name: 'delete_id', value: null})}
                                .onContinue=${password => {
                                    // Store the password in a component property to ensure it's not lost
                                    this._deletePassword = password;
                                    this.onDelete(this.state.list.delete_id, this._deletePassword);
                                }}
                        ></kosyma-securitycheck-password>` : nothing}
                </div>
            </div>
        `
    }

    formatDate(timestamp) {
        if (!timestamp) return 'Unknown';
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    onVerify = deviceId => {
        Router.go('/messenger/devices/verification/sender/' + deviceId + '/send')
    }

    onDelete = async (deviceId, password) => {
        try {
            // Try to delete without auth first - pass undefined instead of empty object
            await deleteDevice(deviceId, undefined);
            setDeviceListState({name: 'delete_id', value: null});
            addInformationMessage('Device ' + deviceId + ' successfully deleted!');
            this.updateDeviceList();
        } catch (err) {
            // If authentication is required
            if (err.httpStatus === 401 && err.data.flows) {
                try {
                    // Create auth object with the correct format
                    const authObject = this.createAuthObject(err.data.session, password);
                    await deleteDevice(deviceId, authObject);
                    setDeviceListState({name: 'delete_id', value: null});
                    addInformationMessage('Device ' + deviceId + ' successfully deleted!');
                    this.updateDeviceList();
                } catch (authError) {
                    console.error('Error deleting device with auth:', authError);
                    addErrorMessage(authError.message || 'Failed to delete device');
                }
            } else {
                console.error('Error deleting device:', err);
                addErrorMessage(err.message || 'Failed to delete device');
            }
        }
    }

    updateDeviceList = async () => {
        try {
            const devices = await getDevices();
            setDeviceListState({name: 'results', value: devices.reverse()});
        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    }

    onCheckNewDevices = async () => {
        try {
            const newDevices = await checkForNewDevicesManually();
            if (newDevices.length > 0) {
                addInformationMessage(`${newDevices.length} new device(s) detected!`);
                this.updateDeviceList();
            } else {
                addInformationMessage('No new devices detected.');
            }
        } catch (error) {
            console.error('Error checking for new devices:', error);
        }
    }

    createAuthObject = (session, password) => {
        // Ensure password is a non-empty string
        if (!password || password.length === 0) {
            console.error('Password is empty or undefined!');
            // Use the stored password if available
            if (this._deletePassword) {
                password = this._deletePassword;
            }
        }
        
        // Create auth object according to Matrix specification
        const authObject = {
            type: 'm.login.password',
            session: session,
            identifier: {
                type: 'm.id.user',
                user: getMatrixClient().getUserId(),
            },
            password: password,
        };
        
        return authObject;
    }
}

customElements.define('kosyma-device-list', DeviceList)
import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/RoomChat.css';

class RoomChatReply extends BElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        
        // Add styles to the shadow DOM
        const style = document.createElement('style');
        style.textContent = `
            .input-group {
                display: flex;
                padding: 8px;
                margin-top: 4px;
            }
            
            input {
                flex: 1;
                padding: 8px 12px;
                border: 1px solid #ddd;
                border-radius: 18px;
                margin-right: 8px;
                font-size: 14px;
                outline: none;
            }
            
            input:focus {
                border-color: #1976d2;
                box-shadow: 0 0 0 1px rgba(25, 118, 210, 0.1);
            }
            
            .send-button {
                background-color: #1E88E5;
                color: white;
                border: none;
                border-radius: 18px;
                padding: 8px 16px;
                font-size: 14px;
                cursor: pointer;
                transition: background-color 0.2s;
            }
            
            .send-button:hover {
                background-color: #1976D2;
            }
        `;
        this.shadowRoot.appendChild(style);
    }
    
    // Override getRenderTarget to use shadowRoot
    getRenderTarget() {
        return this.shadowRoot;
    }
    
    extractState(state) {
        return {
            room: state.rooms.room,
            chat: state.rooms.room.chat,
        }
    }

    view() {
        const eventId = this.getAttribute('eventId');

        return html`
            <div class='input-group'>
                <input type='text' placeholder='Type a reply...' autofocus='autofocus'>
                <button class="send-button">Reply</button>
            </div>
        `;
    }
}

customElements.define('kosyma-room-chat-reply', RoomChatReply);
import {html, nothing} from 'lit-html';
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js';
import OrgAdminUsersClient from '../control/OrgAdminUsersClient.js';
import {setUserEditState, userEditPvsCustomValueState} from '../control/OrgAdminControl.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';


class OrgAdminUsersEdit extends BElement {
    connectedCallback() {
        super.connectedCallback();
        this.onUpdate();
    }

    extractState(state) {
        return {
            matrix: state.matrix,
            user_edit: state.orgAdmin.user_edit
        }
    }

    view() {
        if (!this.state.user_edit) {
            return nothing;
        }

        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>${this.location.params.id}</h2>
                    <div class='inner'>
                        <h2>Details</h2>
                        <kosyma-input-text
                                identifier='organisation_name'
                                label='Organization'
                                name='organisation_name'
                                autofocus='true'
                                value=${this.state.user_edit.pvs_custom.organisation_name}
                                @keyup=${(e) => this.onChangePvsValue(e)}
                        ></kosyma-input-text>
                        <kosyma-input-text
                                identifier='knr'
                                label='Customer ID'
                                name='knr'
                                value=${this.state.user_edit.pvs_custom.knr}
                                @keyup=${(e) => this.onChangePvsValue(e)}
                        ></kosyma-input-text>
                        <kosyma-input-text
                                identifier='first_name'
                                label='First Name'
                                name='first_name'
                                value=${this.state.user_edit.pvs_custom.first_name}
                                @keyup=${(e) => this.onChangePvsValue(e)}
                        ></kosyma-input-text>
                        <kosyma-input-text
                                identifier='last_name'
                                label='Last Name'
                                name='last_name'
                                value=${this.state.user_edit.pvs_custom.last_name}
                                @keyup=${(e) => this.onChangePvsValue(e)}
                        ></kosyma-input-text>
                        <kosyma-input-text
                                identifier='email'
                                label='Email'
                                name='email'
                                value=${this.state.user_edit.pvs_custom.email}
                                @keyup=${(e) => this.onChangePvsValue(e)}
                        ></kosyma-input-text>
                        <kosyma-button
                                identifier='save'
                                label='Save'
                                @click=${this.onSave}
                        ></kosyma-button>

                        <h2 style='margin:40px 0 20px 0;'>Devices</h2>
                        <table>
                            <thead>
                            <tr>
                                <th>Device-ID</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            ${this.state.user_edit.devices.map(e => html`
                                <tr>
                                    <td>${e.device_id}</td>
                                    <td>
                                        ${e.device_id !== this.state.matrix.session.device_id
                                                ? html`
                                                    <kosyma-button
                                                            identifier=${'delete_' + e.device_id}
                                                            label='Delete'
                                                            @click=${() => this.onDeviceDelete(e.device_id)}
                                                    ></kosyma-button>
                                                ` : nothing
                                        }
                                    </td>
                                </tr>
                            `)}
                            </tbody>
                        </table>
                        
                        <h2 style='margin:40px 0 20px 0;'>Raw User Data</h2>
                        <kosyma-formatted-json
                                data-json=${JSON.stringify(this.state.user_edit)}></kosyma-formatted-json>
                        </kosyma-formatted-json>
                    </div>


                </div>
            </div>
        `
    }

    onUpdate = _ => {
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token)
            .getMatrixUser(this.location.params.id)
            .then(r => setUserEditState(r[0]));
    }

    onDeviceDelete = deviceId => {
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token)
            .deleteDevice(this.location.params.id, deviceId)
            .then(r => {
                addInformationMessage('Delete successfully');
                this.onUpdate();
            }).catch(err => addErrorMessage(err));
    }

    onChangePvsValue = ({target: {name, value}}) => {
        userEditPvsCustomValueState({name, value});
    }

    onSave = () => {
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token)
            .updateUser(this.location.params.id, this.state.user_edit.pvs_custom)
            .then(r => {
                addInformationMessage('Update successfully');
                Router.go('/messenger/orgadmin/users/list')
            }).catch(err => addErrorMessage(err));
    }
}

customElements.define('kosyma-orgadmin-users-edit', OrgAdminUsersEdit);

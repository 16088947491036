import { createReducer } from '@reduxjs/toolkit';
import { setSettingsAction } from '../control/SettingsControl.js';

const initialState = {
    readReceiptsEnabled: false, // Disabled by default for privacy
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
};

export const settings = createReducer(initialState, (builder) => {
    builder
        .addCase(setSettingsAction, (state, { payload }) => {
            state[payload.name] = payload.value;
        });
}); 
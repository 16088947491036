import { createAction } from '@reduxjs/toolkit';
import store from '../../store.js';
import { getMatrixClient } from '../../matrix/control/MatrixClient.js';
import { addErrorMessage } from '../../messages/control/MessagesControl.js';

export const userDirectorySearchAction = createAction('userDirectorySearchAction');

export const setUserDirectorySearchState = state => {
    store.dispatch(userDirectorySearchAction(state));
}

export const searchUserDirectory = async (searchTerm) => {
    setUserDirectorySearchState({ name: 'isLoading', value: true });
    setUserDirectorySearchState({ name: 'error', value: null });
    
    try {
        const client = getMatrixClient();
        if (!client) {
            throw new Error('Matrix client not available');
        }
        
        const response = await client.searchUserDirectory({
            term: searchTerm,
            limit: 50
        });
        
        if (!response || !Array.isArray(response.results)) {
            console.warn('Unexpected response format from user directory search:', response);
            setUserDirectorySearchState({ 
                name: 'results', 
                value: [] 
            });
            return;
        }
        
        // Add information about existing direct message rooms
        const enhancedResults = [];
        
        for (const user of response.results) {
            try {
                const existingRoomId = findExistingDirectMessageRoom(user.user_id);
                enhancedResults.push({
                    ...user,
                    has_direct_message: !!existingRoomId,
                    direct_message_room_id: existingRoomId
                });
            } catch (error) {
                console.error(`Error enhancing user ${user.user_id}:`, error);
                // Still include the user but without direct message info
                enhancedResults.push({
                    ...user,
                    has_direct_message: false,
                    direct_message_room_id: null
                });
            }
        }
        
        setUserDirectorySearchState({ name: 'results', value: enhancedResults });
    } catch (error) {
        console.error('Error searching user directory:', error);
        setUserDirectorySearchState({ name: 'error', value: error.message });
        addErrorMessage('Failed to search user directory: ' + error.message);
    } finally {
        setUserDirectorySearchState({ name: 'isLoading', value: false });
    }
}

export const createDirectMessageRoom = async (userId) => {
    try {
        const client = getMatrixClient();
        if (!client) {
            throw new Error('Matrix client not available');
        }
        
        // Check if a direct message room already exists with this user
        const existingRoomId = findExistingDirectMessageRoom(userId);
        if (existingRoomId) {
            return existingRoomId; // Return existing room instead of creating a new one
        }
        
        // Create room with specific options for direct messaging
        const createRoomOptions = {
            preset: 'private_chat',
            visibility: 'private',
            is_direct: true,
            invite: [userId],
            initial_state: [
                {
                    type: 'm.room.encryption',
                    state_key: '',
                    content: {
                        algorithm: 'm.megolm.v1.aes-sha2'
                    }
                }
            ]
        };
        
        const { room_id } = await client.createRoom(createRoomOptions);
        
        try {
            // Set the room as a direct message in account data
            // This ensures the client treats it as a DM
            let directContent = {};
            
            // Safely get the existing direct message content
            try {
                const directEvent = await client.getAccountData('m.direct');
                if (directEvent && directEvent.getContent) {
                    directContent = directEvent.getContent() || {};
                }
            } catch (err) {
                console.warn('Failed to get existing direct message data, creating new:', err);
            }
            
            // Update the direct chats data for this user
            if (!directContent[userId]) {
                directContent[userId] = [];
            }
            
            if (!directContent[userId].includes(room_id)) {
                directContent[userId].push(room_id);
                await client.setAccountData('m.direct', directContent);
            }
        } catch (accountDataError) {
            // If setting account data fails, log but don't fail the room creation
            console.error('Failed to set direct message account data:', accountDataError);
        }
        
        return room_id;
    } catch (error) {
        console.error('Error creating direct message room:', error);
        addErrorMessage('Failed to create direct message: ' + error.message);
        throw error;
    }
}

// Helper function to find an existing direct message room with a specific user
export const findExistingDirectMessageRoom = (userId) => {
    const client = getMatrixClient();
    if (!client) return null;

    // Get the direct message map from account data
    const directEvent = client.getAccountData('m.direct');
    if (!directEvent || !directEvent.getContent()) return null;

    const directContent = directEvent.getContent();
    
    // Check if we have direct rooms with this user
    const roomIds = directContent[userId] || [];
    
    // Find the first valid room
    for (const roomId of roomIds) {
        const room = client.getRoom(roomId);
        // Make sure the room still exists and we're still in it
        if (room && room.getMyMembership() === 'join') {
            return roomId;
        }
    }
    
    // Also check other rooms to see if this might be a DM that wasn't properly tagged
    const rooms = client.getRooms();
    for (const room of rooms) {
        if (!room) continue;
        
        // Check if this is a private room with exactly 2 members
        const memberCount = room.getJoinedMemberCount();
        if (typeof memberCount === 'number' && memberCount === 2) {
            // Check if the other member is the requested user
            try {
                const members = room.getJoinedMembers();
                if (!Array.isArray(members)) continue;
                
                for (const member of members) {
                    if (member && member.userId === userId) {
                        return room.roomId;
                    }
                }
            } catch (e) {
                console.error('Error checking room members:', e);
                continue;
            }
        }
    }
    
    return null;
} 
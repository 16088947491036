import {html} from 'lit-html'
import {Router} from '@vaadin/router'
import BElement from '../../BElement.js'
import '../css/header.css'
import logo from '../../assets/kosyma-logo.png'
import {resetState} from '../../matrix/control/MatrixControl.js';
import {getMatrixClient, setMatrixClient, deleteLocalStorage} from '../../matrix/control/MatrixClient.js';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';

class Header extends BElement {
    connectedCallback() {
        super.connectedCallback();
        this.inactivityLimit = 30 * 60 * 1000;
        this.inactivityTimer = null;
        this.resetInactivityTimer = this.resetInactivityTimer.bind(this);

        ['mousemove', 'keydown', 'scroll', 'click', 'touchstart'].forEach(evt =>
            window.addEventListener(evt, this.resetInactivityTimer)
        );

        this.resetInactivityTimer();
    }

    disconnectedCallback() {
        ['mousemove', 'keydown', 'scroll', 'click', 'touchstart'].forEach(evt =>
            window.removeEventListener(evt, this.resetInactivityTimer)
        );
        clearTimeout(this.inactivityTimer);
    }

    extractState(state) {
        return {
            session: state.matrix.session
        }
    }

    view() {
        return html`
            <header>
                <div class='logo'><a href='/messenger/' alt='home'><img alt='logo' src=${logo}></a></div>
                ${this.state.session && html`
                    <nav>
                        <a id='create-room' @click=${() => Router.go('/messenger/rooms/create')}>Create Room</a>
                        <a id='room-list' @click=${() => Router.go('/messenger/rooms/')}>Rooms</a>
                        <a id='users-directory' @click=${() => Router.go('/messenger/users/directory')}>Users</a>
                        <a id='orgadmin-menu' @click=${() => Router.go('/messenger/orgadmin/menu')}>Org-Admin</a>
                        <a id='devices-list' @click=${() => Router.go('/messenger/devices/list')}>Devices
                            <kosyma-icon icon='desktop'></kosyma-icon>
                        </a>
                        <a id='settings' @click=${() => Router.go('/messenger/settings')}>Settings
                            <kosyma-icon icon='cog'></kosyma-icon>
                        </a>
                        <a id='logout' @click=${this.onLogout}>Logout</a>
                    </nav>
                    <div class='menu-toggle'>☰</div>`
                }
            </header>`
    }

    resetInactivityTimer = () => {
        clearTimeout(this.inactivityTimer);
        this.inactivityTimer = setTimeout(this.onLogout, this.inactivityLimit);
    }

    onLogout = async () => {
        const client = getMatrixClient();
        if (!client) {
            Router.go('/');
            resetState();
            return;
        }

        try {
            await client.logout(true);
        } catch (err) {
            addErrorMessage(err);
        } finally {
            // Clean up regardless of whether logout succeeded or failed
            await deleteLocalStorage(true);
            setMatrixClient(null);
            Router.go('/');
            resetState();
        }
    }

    toggleMenu = () => {
        const nav = document.querySelector('header nav')
        nav.classList.toggle('active')
    }
}

customElements.define('kosyma-header', Header)

import { html } from 'lit-html';
import BElement from '../../BElement.js';
import { toggleReadReceipts, setSettingsState, updatePassword } from '../control/SettingsControl.js';
import { addErrorMessage, addInformationMessage } from '../../messages/control/MessagesControl.js';
import { getMatrixClient } from '../../matrix/control/MatrixClient.js';
import '../css/Settings.css';

class Settings extends BElement {
    constructor() {
        super();
        this.displayName = '';
        this.isLoadingDisplayName = true;
    }

    connectedCallback() {
        super.connectedCallback();
        this.fetchDisplayName();
    }

    extractState(state) {
        return {
            settings: state.settings,
            matrix: state.matrix
        };
    }

    async fetchDisplayName() {
        const client = getMatrixClient();
        const userId = client?.getUserId();
        if (!userId || !this.state.matrix.base_url) {
            this.isLoadingDisplayName = false;
            this.triggerViewUpdate();
            return;
        }

        try {
            const response = await fetch(`${this.state.matrix.base_url}/_matrix/client/v3/profile/${encodeURIComponent(userId)}`, {
                headers: {
                    'Authorization': `Bearer ${this.state.matrix.session.access_token}`
                }
            });
            
            if (response.ok) {
                const data = await response.json();
                this.displayName = data.displayname || '';
            }
        } catch (err) {
            console.error('Failed to fetch display name:', err);
        } finally {
            this.isLoadingDisplayName = false;
            this.triggerViewUpdate();
        }
    }

    view() {
        const client = getMatrixClient();
        const userId = client?.getUserId();

        return html`
            <div class="settings-container">
                <div class="settings-section">
                    <h2>User Profile</h2>
                    <div class="setting-item">
                        <div class="setting-info">
                            <label>User ID</label>
                            <p class="setting-description">${userId || 'Not available'}</p>
                        </div>
                    </div>
                    <div class="setting-item">
                        <div class="setting-info">
                            <label>Display Name</label>
                            <p class="setting-description">
                                ${this.isLoadingDisplayName 
                                    ? html`<span class="loading">Loading...</span>` 
                                    : this.displayName || 'Not set'}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="settings-section">
                    <h2>Privacy Settings</h2>
                    <div class="setting-item">
                        <div class="setting-info">
                            <label for="read-receipts">Read Receipts</label>
                            <p class="setting-description">
                                When enabled, other users can see when you've read their messages.
                                This setting is disabled by default for privacy.
                            </p>
                        </div>
                        <div class="setting-control">
                            <label class="switch">
                                <input type="checkbox"
                                       id="read-receipts"
                                       .checked=${this.state.settings?.readReceiptsEnabled ?? false}
                                       @change=${this.onToggleReadReceipts}>
                                <span class="slider"></span>
                            </label>
                        </div>
                    </div>
                </div>
                
                <div class="settings-section">
                    <h2>Security Settings</h2>
                    <div class="setting-item">
                        <div class="setting-info">
                            <h3>Change Password</h3>
                            <kosyma-input-text
                                identifier='currentPassword'
                                label='Current Password'
                                name='currentPassword'
                                type='password'
                                autocomplete='password'
                                .value=${this.state.settings?.currentPassword ?? ''}
                                @input=${e => this.onPasswordInputChange(e)}
                            ></kosyma-input-text>
                            <kosyma-input-text
                                identifier='newPassword'
                                label='New Password'
                                name='newPassword'
                                type='password'
                                autocomplete='newPassword'
                                .value=${this.state.settings?.newPassword ?? ''}
                                @input=${e => this.onPasswordInputChange(e)}
                            ></kosyma-input-text>
                            <kosyma-input-text
                                identifier='confirmPassword'
                                label='Confirm New Password'
                                name='confirmPassword'
                                type='password'
                                autocomplete='confirmPassword'
                                .value=${this.state.settings?.confirmPassword ?? ''}
                                @input=${e => this.onPasswordInputChange(e)}
                            ></kosyma-input-text>
                            <kosyma-button
                                identifier='update_password'
                                label='Update Password'
                                @click=${this.onUpdatePassword}
                            ></kosyma-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    onToggleReadReceipts(e) {
        toggleReadReceipts(e.target.checked);
    }

    onPasswordInputChange({target: {name, value}}) {
        setSettingsState(name, value);
    }

    onUpdatePassword = async () => {
        const { currentPassword, newPassword, confirmPassword } = this.state.settings;
        
        if (!currentPassword || !newPassword || !confirmPassword) {
            addErrorMessage('All password fields are required');
            return;
        }

        if (newPassword !== confirmPassword) {
            addErrorMessage('New passwords do not match');
            return;
        }

        try {
            await updatePassword(currentPassword, newPassword);
            addInformationMessage('Password updated successfully');
            // Clear password fields
            setSettingsState('currentPassword', '');
            setSettingsState('newPassword', '');
            setSettingsState('confirmPassword', '');
        } catch (err) {
            addErrorMessage('Failed to update password: ' + err.message);
        }
    }
}

customElements.define('settings-view', Settings); 
import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/RoomChat.css';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {setRoomChatState} from '../control/RoomControl.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';
import {calculateBackoff, getRetryMessage} from '../../utils/RetryUtils.js';
import './LocationPicker.js';
import './AudioRecorder.js';
import 'maplibre-gl/dist/maplibre-gl.css';
import {uploadSmartContent} from '../control/UploadClient.js';

// Check if components are defined
if (!customElements.get('kosyma-location-picker')) {
    console.error('LocationPicker component not defined! Trying to import it again.');
    import('./LocationPicker.js').catch(err => console.error('Failed to import LocationPicker:', err));
}

if (!customElements.get('kosyma-audio-recorder')) {
    console.error('AudioRecorder component not defined! Trying to import it again.');
    import('./AudioRecorder.js').catch(err => console.error('Failed to import AudioRecorder:', err));
}

class RoomChatInputField extends BElement {
    constructor() {
        super();
        this.showLocationPicker = false;
        this.showAudioRecorder = false;
    }

    extractState(state) {
        return {
            room: state.rooms.room,
            chat: state.rooms.room.chat,
        }
    }

    view() {
        const isSending = this.state.chat.isRetrying || this.state.chat.isSending;

        // Create location picker content
        const locationPickerContent = this.showLocationPicker ? html`
            <div class="location-picker-backdrop" @click=${this.onLocationCanceled}></div>
            <div class="location-picker-wrapper">
                <kosyma-location-picker
                        id="location-picker"
                        @location-selected=${this.onLocationSelected}
                        @location-canceled=${this.onLocationCanceled}>
                </kosyma-location-picker>
            </div>
        ` : null;

        // Create audio recorder content
        const audioRecorderContent = this.showAudioRecorder ? html`
            <div class="audio-recorder-backdrop" @click=${(e) => {
                // Only handle clicks directly on the backdrop, not on its children
                if (e.target.classList.contains('audio-recorder-backdrop')) {
                    this.onAudioCanceled();
                }
                e.stopPropagation();
            }}></div>
            <div class="audio-recorder-wrapper">
                <kosyma-audio-recorder
                        id="audio-recorder"
                        @audio-ready=${this.onAudioReady}
                        @cancel=${this.onAudioCanceled}
                        @recording-error=${this.onAudioError}>
                </kosyma-audio-recorder>
            </div>
        ` : null;

        // Create options menu content
        const showOptionsMenu = this.state.chat.showOptionsMenu || false;
        const optionsMenuContent = showOptionsMenu ? html`
            <div class="options-menu">
                <div class="option-item" @click=${this.onOpenFileDialog}>
                    <i class="fa-solid fa-paperclip option-icon"></i> Upload
                </div>
                <div class="option-item" @click=${this.onOpenLocationPicker}>
                    <i class="fa-solid fa-location-dot option-icon"></i> Location
                </div>
                <div class="option-item" @click=${this.onOpenAudioRecorder}>
                    <i class="fa-solid fa-microphone option-icon"></i> Record Audio
                </div>
            </div>
        ` : null;

        return html`
            <div class='input-group'>
                <input type='text' placeholder='Type a message, use /me for emotes or /notice for announcements...'
                       autofocus
                       ?disabled=${isSending}
                       .value='${this.state.chat.text}'
                       @keyup=${(e) => e.key === 'Enter' ? this.onSendMessage() :
                               setRoomChatState({
                                   name: 'text',
                                   value: e.target.value
                               })}/>

                <button @click=${this.onSendMessage}
                        class="send-button"
                        ?disabled=${isSending}
                        title="${isSending ?
                                `${this.state.chat.isRetrying ?
                                        `Retrying (${this.state.chat.retryAttempt}/5)...` :
                                        'Sending...'}` :
                                'Send message'}">
                    ${isSending ?
                            `${this.state.chat.isRetrying ?
                                    `${this.state.chat.retryAttempt}/5` :
                                    'Sending...'}` :
                            'Send'}
                </button>

                <button class='options-btn'
                        @click=${this.toggleOptionsMenu}
                        ?disabled=${isSending}
                        title="More options">
                    <i class="fa-solid fa-ellipsis"></i>
                </button>

                ${optionsMenuContent}
                ${locationPickerContent}
                ${audioRecorderContent}

                <input type='file' class='fileInput' @change=${this.onUpload}
                       style='display: none;' accept='image/*,video/*,audio/*,application/pdf'/>
            </div>
        `;
    }

    async sendMessageWithRetry(content, attempt = 0) {
        try {
            setRoomChatState({name: 'isSending', value: true});
            setRoomChatState({name: 'isRetrying', value: attempt > 0});
            setRoomChatState({name: 'retryAttempt', value: attempt});

            const res = await getMatrixClient().sendMessage(this.state.room.id, content);

            // Success - reset all states
            setRoomChatState({name: 'isSending', value: false});
            setRoomChatState({name: 'isRetrying', value: false});
            setRoomChatState({name: 'retryAttempt', value: 0});
            setRoomChatState({name: 'text', value: ''});

            if (attempt > 0) {
                addInformationMessage('Message sent successfully after retry');
            }

            return res;
        } catch (err) {
            if (attempt >= 5) {
                setRoomChatState({name: 'isSending', value: false});
                setRoomChatState({name: 'isRetrying', value: false});
                setRoomChatState({name: 'retryAttempt', value: 0});
                addErrorMessage('Could not send message. Please try again later.');
                throw err;
            }

            const backoff = calculateBackoff(attempt);
            const timeMessage = getRetryMessage(backoff / 1000);
            addInformationMessage(`Connection issue - will retry in ${timeMessage}... (Attempt ${attempt + 1} of 5)`);

            await new Promise(resolve => setTimeout(resolve, backoff));
            return this.sendMessageWithRetry(content, attempt + 1);
        }
    }

    onSendMessage = async () => {
        if (!this.state.chat.text || this.state.chat.text.trim().length === 0) {
            return;
        }

        let messageText = this.state.chat.text;
        let msgtype = 'm.text';

        // Check if the message is an emote (/me command)
        if (messageText.startsWith('/me ')) {
            msgtype = 'm.emote';
            messageText = messageText.substring(4); // Remove the '/me ' prefix
        }
        // Check if the message is a notice (/notice command)
        else if (messageText.startsWith('/notice ')) {
            msgtype = 'm.notice';
            messageText = messageText.substring(8); // Remove the '/notice ' prefix
        }

        const content = {
            body: messageText,
            'msgtype': msgtype
        };

        try {
            await this.sendMessageWithRetry(content);
        } catch (err) {
            console.error(err);
        }
    }

    onOpenFileDialog = () => {
        document.querySelector('.fileInput').click();
    }

    onOpenLocationPicker = () => {

        // Make sure the location picker component is defined
        if (!customElements.get('kosyma-location-picker')) {
            import('./LocationPicker.js')
                .then(() => {
                    // After component is imported, show the picker
                    this.showLocationPicker = true;
                    this.triggerViewUpdate();
                })
                .catch(err => {
                    console.error('Failed to import LocationPicker:', err);
                    addErrorMessage('Could not open location picker. Please try again.');
                });
        } else {
            // Component already defined, show picker
            this.showLocationPicker = true;
            this.triggerViewUpdate();
        }
    }

    onLocationCanceled = (e) => {
        this.showLocationPicker = false;
        this.triggerViewUpdate();
    }

    onLocationSelected = async (e) => {
        const locationData = e.detail;

        // Format the geo URI according to Matrix spec
        const geoUri = `geo:${locationData.latitude},${locationData.longitude}`;

        // Create a location message content following the Matrix spec
        const content = {
            body: locationData.description || `Location: ${locationData.latitude}, ${locationData.longitude}`,
            geo_uri: geoUri,
            msgtype: 'm.location',
            // Add additional info to improve rendering
            info: {
                description: locationData.description
            }
        };

        try {
            await this.sendMessageWithRetry(content);
            addInformationMessage('Location shared successfully');
        } catch (err) {
            console.error('Failed to send location:', err);
        } finally {
            this.showLocationPicker = false;
            this.triggerViewUpdate();
        }
    }

    onUpload = async e => {
        const file = e.target.files[0];
        if (!file) {
            return; // No file selected
        }

        // Determine the message type based on file type
        const isVideo = file.type.startsWith('video/');
        const isImage = file.type.startsWith('image/');

        // Verify supported formats
        if (isVideo) {
            const supportedFormats = ['video/mp4', 'video/webm', 'video/ogg'];
            if (!supportedFormats.includes(file.type)) {
                addErrorMessage(`Unsupported video format: ${file.type}. Please use MP4, WebM, or Ogg.`);
                e.target.value = '';
                return;
            }
        }

        // Show upload in progress message
        addInformationMessage(`Uploading ${isVideo ? 'video' : isImage ? 'image' : 'file'}: ${file.name}...`);

        // Determine message type
        let msgtype = 'm.file';
        if (isImage) {
            msgtype = 'm.image';
        } else if (isVideo) {
            msgtype = 'm.video';
        }

        try {
            // Get the matrix client
            const client = getMatrixClient();

            const res = await uploadSmartContent(client, file);

            if (!res || !res.content_uri) {
                throw new Error('Upload failed: No content URI returned');
            }

            // Create message content with improved metadata
            const messageContent = {
                'body': file.name,
                'info': {
                    'size': file.size,
                    'mimetype': file.type
                },
                'msgtype': msgtype,
                'm.mentions': {},
                'url': res.content_uri
            };

            // For videos, try to extract dimensions and duration
            if (isVideo) {
                try {
                    const videoElement = document.createElement('video');
                    const loadPromise = new Promise((resolve) => {
                        videoElement.onloadedmetadata = () => {
                            if (videoElement.videoWidth && videoElement.videoHeight) {
                                messageContent.info.w = videoElement.videoWidth;
                                messageContent.info.h = videoElement.videoHeight;
                            }

                            if (!isNaN(videoElement.duration)) {
                                messageContent.info.duration = Math.floor(videoElement.duration * 1000);
                            }

                            resolve();
                        };

                        // Set a timeout in case metadata loading takes too long
                        setTimeout(resolve, 2000);
                    });

                    // Set the video source and wait for metadata
                    videoElement.src = URL.createObjectURL(file);
                    await loadPromise;

                    // Clean up
                    URL.revokeObjectURL(videoElement.src);
                } catch (err) {
                    console.warn('Failed to extract video metadata, continuing anyway:', err);
                }
            }

            // Send the message with uploaded content
            await client.sendMessage(this.state.room.id, messageContent);

            addInformationMessage(`${file.name} uploaded successfully`);
        } catch (err) {
            console.error('Error with file upload:', err);

            // Format a user-friendly error message
            let errorMessage = 'Upload failed';

            if (err.httpStatus === 413) {
                errorMessage = `File too large for the server. Try a smaller file.`;
            } else if (err.httpStatus === 401 || err.httpStatus === 403) {
                errorMessage = 'Authentication error. You may need to log in again.';
            } else if (err.name === 'ConnectionError' || err.httpStatus === 0) {
                errorMessage = 'Connection error. Please check your internet connection.';
            } else if (err.data?.error) {
                errorMessage = `Upload failed: ${err.data.error}`;
            } else if (err.message) {
                errorMessage = `Upload failed: ${err.message}`;
            }

            addErrorMessage(errorMessage);
        }

        // Clear the input field
        e.target.value = '';
    }


    toggleOptionsMenu = (e) => {
        e.stopPropagation();
        const currentState = this.state.chat.showOptionsMenu || false;

        // Add a click handler to the document to close the menu when clicking outside
        if (!currentState) {
            setTimeout(() => {
                document.addEventListener('click', this.closeOptionsMenu);
            }, 0);
        }

        setRoomChatState({
            name: 'showOptionsMenu',
            value: !currentState
        });
    }

    closeOptionsMenu = () => {
        document.removeEventListener('click', this.closeOptionsMenu);
        setRoomChatState({
            name: 'showOptionsMenu',
            value: false
        });
    }

    onOpenAudioRecorder = () => {
        // Close options menu
        this.closeOptionsMenu();

        console.log('Audio recorder requested');

        // Check if MediaRecorder is supported by the browser
        if (!window.MediaRecorder) {
            console.error('MediaRecorder API not available in this browser');
            addErrorMessage('Your browser does not support audio recording. Please try a modern browser like Chrome or Firefox.');
            return;
        }

        // Test if getUserMedia is available
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.error('getUserMedia is not supported in this browser');
            addErrorMessage('Microphone access not supported in this browser. Please try a different browser.');
            return;
        }

        console.log('MediaRecorder is available, showing recorder UI');

        // Make sure the audio recorder component is defined
        if (!customElements.get('kosyma-audio-recorder')) {
            console.log('AudioRecorder component not defined, importing...');
            import('./AudioRecorder.js')
                .then(() => {
                    this.showAudioRecorder = true;
                    this.triggerViewUpdate();
                    console.log('AudioRecorder component imported and showing UI');
                })
                .catch(err => {
                    console.error('Failed to import AudioRecorder:', err);
                    addErrorMessage('Could not open audio recorder. Please try again.');
                });
        } else {
            console.log('AudioRecorder component already defined, showing UI');
            this.showAudioRecorder = true;
            this.triggerViewUpdate();
        }
    }

    onAudioCanceled = () => {
        this.showAudioRecorder = false;
        this.triggerViewUpdate();
    }

    onAudioError = (e) => {
        const error = e.detail.error;
        addErrorMessage(`Microphone error: ${error}`);
        this.showAudioRecorder = false;
        this.triggerViewUpdate();
    }

    onAudioReady = async (e) => {
        const audioBlob = e.detail.blob;

        if (!audioBlob) {
            addErrorMessage('No audio data available to send');
            return;
        }

        // Show upload in progress message
        addInformationMessage('Preparing audio message...');

        try {
            // Get the matrix client
            const client = getMatrixClient();

            // Create a reasonable filename
            const now = new Date();
            const filename = `audio_${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}.webm`;

            // Create upload options
            const options = {
                // Use v3 endpoint instead of r0
                urlPath: '/_matrix/media/v3/upload',

                // Add filename as query parameter, properly encoded
                extraParams: {
                    filename: encodeURIComponent(filename)
                }
            };

            // Upload the audio blob
            const res = await client.uploadContent(audioBlob, options);

            if (!res || !res.content_uri) {
                throw new Error('Upload failed: No content URI returned');
            }

            // Create message content for audio
            let duration = 0;
            // Try to get audio duration
            try {
                duration = await this.getAudioDuration(audioBlob);
            } catch (err) {
                console.warn('Could not get audio duration', err);
            }

            // Create the message content according to Matrix spec
            const messageContent = {
                'body': filename,
                'info': {
                    'duration': duration,
                    'mimetype': audioBlob.type,
                    'size': audioBlob.size
                },
                'msgtype': 'm.audio',
                'm.mentions': {},
                'url': res.content_uri
            };

            // Send the audio message
            await this.sendMessageWithRetry(messageContent);

            // Close the audio recorder
            this.showAudioRecorder = false;
            this.triggerViewUpdate();

            addInformationMessage('Audio message sent successfully');
        } catch (err) {
            console.error('Error sending audio message:', err);

            // Format a user-friendly error message
            let errorMessage = 'Failed to send audio message';

            if (err.httpStatus === 413) {
                errorMessage = `Audio file too large for the server.`;
            } else if (err.httpStatus === 401 || err.httpStatus === 403) {
                errorMessage = 'Authentication error. You may need to log in again.';
            } else if (err.name === 'ConnectionError' || err.httpStatus === 0) {
                errorMessage = 'Connection error. Please check your internet connection.';
            } else if (err.data?.error) {
                errorMessage = `Upload failed: ${err.data.error}`;
            } else if (err.message) {
                errorMessage = `Upload failed: ${err.message}`;
            }

            addErrorMessage(errorMessage);

            // Close the audio recorder on error
            this.showAudioRecorder = false;
            this.triggerViewUpdate();
        }
    }

    getAudioDuration(blob) {
        return new Promise((resolve, reject) => {
            const audioElement = new Audio();
            audioElement.src = URL.createObjectURL(blob);

            // Set up event listeners
            audioElement.addEventListener('loadedmetadata', () => {
                const duration = Math.floor(audioElement.duration * 1000); // Convert to ms
                URL.revokeObjectURL(audioElement.src); // Clean up
                resolve(duration);
            });

            audioElement.addEventListener('error', (error) => {
                URL.revokeObjectURL(audioElement.src); // Clean up
                reject(error);
            });

            // Set a timeout in case it gets stuck
            setTimeout(() => {
                URL.revokeObjectURL(audioElement.src);
                reject(new Error('Timeout getting audio duration'));
            }, 5000);
        });
    }
}

customElements.define('kosyma-room-chat-input-field', RoomChatInputField);
import { createReducer } from '@reduxjs/toolkit';
import { userDirectorySearchAction } from '../control/UserDirectoryControl.js';

const initialState = {
    search: {
        filter: '',
        results: [],
        isLoading: false,
        error: null
    }
};

export const userDirectoryReducer = createReducer(initialState, builder => {
    builder.addCase(userDirectorySearchAction, (state, action) => {
        const { name, value } = action.payload;
        if (name === 'filter') {
            state.search.filter = value;
        } else if (name === 'results') {
            state.search.results = value;
        } else if (name === 'isLoading') {
            state.search.isLoading = value;
        } else if (name === 'error') {
            state.search.error = value;
        }
    });
}); 
import {createReducer} from '@reduxjs/toolkit';
import {
    clearContactFormAction,
    contactAddAction,
    contactAddedAction,
    contactEditAction,
    contactListAction,
    fhirSearchValueAction,
    userEditAction,
    userEditPvsCustomValueAction,
    userEditValueAction,
    userListAction,
    userDisplayNameAction
} from '../control/OrgAdminControl.js';

const initialState = {
    user_list: {
        results: [],
        delete_id: null,
    },
    user_edit: null,
    user_display_name: {
        first_name: '',
        last_name: ''
    },
    fhir_search: {
        filter: '',
        results: null
    },
    contact_mgmt: {
        list: [],
        add_contact: {
            mxid: '',
            display_name: '',
            start: '',
            end: ''
        },
        added_contact: {}
    }
};

export const orgAdmin = createReducer(initialState, (builder) => {
    builder.addCase(userListAction, (state, {payload}) => {
        state.user_list[payload.name] = payload.value;
    }).addCase(userEditAction, (state, {payload}) => {
        state.user_edit = payload;
    }).addCase(userEditValueAction, (state, {payload}) => {
        state.user_edit[payload.name] = payload.value;
    }).addCase(userEditPvsCustomValueAction, (state, {payload}) => {
        state.user_edit.pvs_custom[payload.name] = payload.value;
    }).addCase(fhirSearchValueAction, (state, {payload}) => {
        state.fhir_search[payload.name] = payload.value;
    }).addCase(contactListAction, (state, {payload}) => {
        state.contact_mgmt.list = payload;
    }).addCase(contactAddAction, (state, {payload}) => {
        state.contact_mgmt.add_contact[payload.name] = payload.value;
    }).addCase(contactEditAction, (state, {payload}) => {
        state.contact_mgmt.add_contact = payload;
    }).addCase(clearContactFormAction, (state, {payload}) => {
        state.contact_mgmt.add_contact = initialState.contact_mgmt.add_contact;
    }).addCase(contactAddedAction, (state, {payload}) => {
        state.contact_mgmt.added_contact = payload;
    }).addCase(userDisplayNameAction, (state, {payload}) => {
        state.user_display_name[payload.name] = payload.value;
    });
});

/**
 * Device Verification implementation according to Matrix specification v1.11
 * https://spec.matrix.org/v1.11/client-server-api/#device-verification
 */

import {getMatrixClient} from './MatrixClient.js';
import {setDeviceVerificationState} from '../../devices/control/DevicesControl.js';
import {addNotificationState} from '../../notification/control/NotificationsControl.js';

// Store the known devices to detect new ones
let knownDevices = new Map();
const DEBUG_DEVICE_VERIFICATION = false;

// Store the active SAS verification object for later use
let activeSASVerification = null;

/**
 * Initiates a device verification request to verify another device
 * @param {string} deviceId - The ID of the device to verify
 * @returns {Promise<Object>} - The verification request object
 */
export const requestDeviceVerification = async (deviceId) => {
    try {
        const client = getMatrixClient();
        const userId = client.getUserId();
        
        // Request device verification using the crypto API
        const verification = await client.getCrypto().requestDeviceVerification(userId, deviceId);
        
        // Set up event listeners for verification state changes
        setupVerificationListeners(verification);
        
        return verification;
    } catch (error) {
        console.error('Error requesting device verification:', error);
        throw error;
    }
};

/**
 * Sets up listeners for verification state changes
 * @param {Object} verification - The verification request object
 */
const setupVerificationListeners = (verification) => {
    verification.on('change', () => {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Verification status changed:', verification);
        }
        
        // Update state based on verification status
        if (verification.ready && !verification.done && !verification.cancelled) {
            setDeviceVerificationState({name: 'request_state', value: 'ready'});
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Verification is ready.');
            }
        }
        
        if (verification.done) {
            setDeviceVerificationState({name: 'request_state', value: 'done'});
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Verification completed successfully!');
            }
        }
        
        if (verification.cancelled) {
            setDeviceVerificationState({name: 'request_state', value: 'cancelled'});
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Verification was cancelled.');
            }
        }
    });
};

/**
 * Starts the SAS verification process
 * @param {Object} verification - The verification request object
 * @returns {Promise<Object>} - The SAS verification object
 */
export const startSASVerification = async (verification) => {
    try {
        // Start the SAS verification process
        const sas = await verification.startVerification('m.sas.v1');
        
        // Set up event listeners for SAS verification
        setupSASListeners(sas);
        
        return sas;
    } catch (error) {
        console.error('Error starting SAS verification:', error);
        throw error;
    }
};

/**
 * Normalizes emoji data to ensure it's in the expected format
 * @param {Array|Object} emojiData - The emoji data from the SAS verification
 * @returns {Array} - Normalized emoji data in the format [[emoji, name], ...]
 */
const normalizeEmojiData = (emojiData) => {
    if (DEBUG_DEVICE_VERIFICATION) {
        console.log('Normalizing emoji data:', JSON.stringify(emojiData));
    }
    
    if (!emojiData) {
        console.warn('No emoji data provided');
        return [];
    }
    
    if (!Array.isArray(emojiData)) {
        console.warn('Emoji data is not an array');
        return [];
    }
    
    // If the array is empty, return empty array
    if (emojiData.length === 0) {
        console.warn('Emoji data array is empty');
        return [];
    }
    
    // Check the format of the first item to determine the structure
    const firstItem = emojiData[0];
    if (DEBUG_DEVICE_VERIFICATION) {
        console.log('First emoji item:', JSON.stringify(firstItem), 'Type:', typeof firstItem);
    }
    
    // Handle the specific format we're seeing in the logs
    // Where each item is an object with numeric keys like '0', '1'
    if (typeof firstItem === 'object' && firstItem !== null && '0' in firstItem && '1' in firstItem) {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Found emoji format with numeric keys');
        }
        return emojiData.map(item => {
            return [item['0'] || '🔒', item['1'] || 'Lock'];
        });
    }
    
    // If it's already in the expected format, return as is
    if (Array.isArray(firstItem) && firstItem.length >= 2) {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Emoji data is already in the expected format');
        }
        return emojiData;
    }
    
    // If it's an object with emoji and name properties
    if (typeof firstItem === 'object' && firstItem !== null) {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Emoji data is in object format, converting to array format');
            console.log('Object properties:', Object.keys(firstItem));
        }
        
        // Matrix JS SDK format: objects with 'description' and 'symbol' properties
        if ('symbol' in firstItem && 'description' in firstItem) {
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Found Matrix SDK emoji format with symbol and description');
            }
            return emojiData.map(item => {
                return [item.symbol || '🔒', item.description || 'Lock'];
            });
        }
        
        // Alternative format with emoji and name properties
        if ('emoji' in firstItem || 'name' in firstItem) {
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Found emoji format with emoji and name properties');
            }
            return emojiData.map(item => {
                return [item.emoji || '🔒', item.name || 'Lock'];
            });
        }
        
        // Log all properties to help debug
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Object does not have expected properties, checking all properties:');
            for (const key in firstItem) {
                console.log(`Property ${key}:`, firstItem[key]);
            }
        }
        
        // Try to extract any properties that might contain emoji data
        const keys = Object.keys(firstItem);
        if (keys.length >= 2) {
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Using first two properties as emoji and name');
            }
            return emojiData.map(item => {
                const itemKeys = Object.keys(item);
                return [String(item[itemKeys[0]]) || '🔒', String(item[itemKeys[1]]) || 'Lock'];
            });
        }
    }
    
    // If it's just strings, add generic names
    if (typeof firstItem === 'string') {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Emoji data is in string format, adding generic names');
        }
        return emojiData.map((emoji, index) => {
            return [emoji, `Emoji ${index + 1}`];
        });
    }
    
    // If we can't determine the format, return empty array
    console.warn('Unknown emoji data format');
    return [];
};

/**
 * Sets up listeners for SAS verification events
 * @param {Object} sas - The SAS verification object
 */
const setupSASListeners = (sas) => {
    // Store the SAS object globally for later access
    activeSASVerification = sas;
    
    // Event for when SAS codes are ready to be compared
    sas.on('show_sas', (sasEvent) => {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('SAS verification codes received:', sasEvent);
            console.log('Raw emoji data:', sasEvent.sas.emoji);
            console.log('Emoji data structure:', JSON.stringify(sasEvent.sas.emoji));
            console.log('Emoji data type:', typeof sasEvent.sas.emoji, Array.isArray(sasEvent.sas.emoji));
        }
        
        // Check if the SDK provides emoji data
        let emojiData = [];
        
        if (sasEvent.sas.emoji && Array.isArray(sasEvent.sas.emoji) && sasEvent.sas.emoji.length > 0) {
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Using emoji data from Matrix SDK');
            }
            
            // Convert the SDK emoji data to the format we expect
            // The Matrix SDK provides emoji data as an array of objects with indices as keys
            // and each object has properties like '0': '🐓', '1': 'rooster'
            emojiData = [];
            
            for (let i = 0; i < sasEvent.sas.emoji.length; i++) {
                const emojiObj = sasEvent.sas.emoji[i];
                if (DEBUG_DEVICE_VERIFICATION) {
                    console.log(`Emoji object ${i}:`, emojiObj, 'Keys:', Object.keys(emojiObj));
                }
                
                // Check if the emoji object has numeric keys (0, 1)
                if ('0' in emojiObj && '1' in emojiObj) {
                    emojiData.push([emojiObj['0'], emojiObj['1']]);
                    if (DEBUG_DEVICE_VERIFICATION) {
                        console.log(`Processed emoji ${i}: [${emojiObj['0']}, ${emojiObj['1']}]`);
                    }
                }
            }
            
            // If we couldn't extract any emoji data, use the normalizeEmojiData function
            if (emojiData.length === 0) {
                if (DEBUG_DEVICE_VERIFICATION) {
                    console.log('Could not extract emoji data directly, using normalizer');
                }
                emojiData = normalizeEmojiData(sasEvent.sas.emoji);
            }
            
            // Log the final emoji data
            if (DEBUG_DEVICE_VERIFICATION) {
                console.log('Final emoji data:', JSON.stringify(emojiData));
            }
        }
        
        // Update state with emoji and decimal codes
        setDeviceVerificationState({name: 'request_state', value: 'show_sas'});
        setDeviceVerificationState({name: 'saas_emoji', value: emojiData});
        setDeviceVerificationState({name: 'saas_short_auth_string', value: sasEvent.sas.decimal ? sasEvent.sas.decimal.join(' ') : ''});
    });
    
    // Event for when verification is completed
    sas.on('done', () => {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('SAS verification completed successfully!');
        }
        setDeviceVerificationState({name: 'request_state', value: 'done'});
    });
    
    // Event for when verification is cancelled
    sas.on('cancel', (reason) => {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.error('SAS verification cancelled:', reason);
        }
        setDeviceVerificationState({name: 'request_state', value: 'cancelled'});
    });
};

/**
 * Confirms that the SAS codes match
 * @returns {Promise<void>}
 */
export const confirmSASMatch = async () => {
    try {
        if (!activeSASVerification && !activeSASVerification.hasOwnProperty('sasEvent')) {
            console.error('No active SAS verification found');
            throw new Error('No active SAS verification found');
        }
        
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Confirming SAS match with:', activeSASVerification.sasEvent);
            console.dir(activeSASVerification);
        }
        await activeSASVerification.sasEvent.confirm();
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('SAS verification confirmed');
        }
    } catch (error) {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.error('Error confirming SAS match:', error);
        }
        throw error;
    }
};

/**
 * Cancels a verification request
 * @param {Object} verification - The verification request object
 * @param {string} reason - The reason for cancellation
 * @returns {Promise<void>}
 */
export const cancelVerification = async (verification, reason = 'User cancelled') => {
    try {
        await verification.cancel(reason);
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Verification cancelled:', reason);
        }
    } catch (error) {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.error('Error cancelling verification:', error);
        }
        throw error;
    }
};

/**
 * Accepts an incoming verification request
 * @param {Object} verificationRequest - The incoming verification request
 * @returns {Promise<Object>} - The accepted verification request
 */
export const acceptVerificationRequest = async (verificationRequest) => {
    try {
        await verificationRequest.accept();
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Verification request accepted');
        }
        return verificationRequest;
    } catch (error) {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.error('Error accepting verification request:', error);
        }
        throw error;
    }
};

/**
 * Checks if a device is verified
 * @param {string} deviceId - The ID of the device to check
 * @returns {Promise<boolean>} - Whether the device is verified
 */
export const isDeviceVerified = async (deviceId) => {
    try {
        const client = getMatrixClient();
        return await client.checkIfOwnDeviceCrossSigned(deviceId);
    } catch (error) {
        console.error('Error checking device verification status:', error);
        return false;
    }
};

/**
 * Initializes the known devices map with current devices
 * @returns {Promise<void>}
 */
export const initializeKnownDevices = async () => {
    try {
        const client = getMatrixClient();
        const response = await client.getDevices();
        
        // Clear the map first
        knownDevices.clear();
        
        // Add all current devices to the known devices map
        response.devices.forEach(device => {
            knownDevices.set(device.device_id, {
                display_name: device.display_name,
                last_seen_ip: device.last_seen_ip,
                last_seen_ts: device.last_seen_ts
            });
        });
        
        //console.log('Known devices initialized:', knownDevices);
    } catch (error) {
        console.error('Error initializing known devices:', error);
    }
};

/**
 * Checks for new devices and notifies the user if any are found
 * @returns {Promise<Array>} - Array of new devices if any are found
 */
export const checkForNewDevices = async () => {
    try {
        const client = getMatrixClient();
        const response = await client.getDevices();
        const newDevices = [];
        
        // Check for new devices
        response.devices.forEach(device => {
            if (!knownDevices.has(device.device_id)) {
                newDevices.push(device);
                
                // Add to known devices
                knownDevices.set(device.device_id, {
                    display_name: device.display_name,
                    last_seen_ip: device.last_seen_ip,
                    last_seen_ts: device.last_seen_ts
                });
                
                // Notify the user about the new device
                notifyNewDevice(device);
            }
        });
        
        if (newDevices.length > 0) {
            console.log('New devices detected:', newDevices);
        }
        
        return newDevices;
    } catch (error) {
        console.error('Error checking for new devices:', error);
        return [];
    }
};

/**
 * Notifies the user about a new device
 * @param {Object} device - The new device
 */
const notifyNewDevice = (device) => {
    const deviceName = device.display_name || 'Unnamed device';
    const message = `A new device has been added to your account at ${new Date().toLocaleTimeString()}`;
    
    if (DEBUG_DEVICE_VERIFICATION) {
        console.log('Creating new device notification with closeable: false');
    }
    
    // Add a notification
    addNotificationState({
        title: 'New Device Detected',
        text: message,
        type: 'warning',
        link: '/messenger/devices/verification/receiver/',
        closeable: false // Security-related notifications should not be closeable by default
    });
    
    // Create and show the custom notification component
    showNewDeviceNotification(device);
    
    if (DEBUG_DEVICE_VERIFICATION) {
        console.log('New device notification:', message);
    }
};

/**
 * Shows a custom notification for a new device
 * @param {Object} device - The new device
 */
const showNewDeviceNotification = (device) => {
    // Import the notification component
    import('../../notification/boundary/NewDeviceNotification.js').then(() => {
        // Create the notification element
        const notification = document.createElement('kosyma-new-device-notification');
        notification.device = device;
        notification.onClose = () => {
            if (notification.parentNode) {
                notification.parentNode.removeChild(notification);
            }
        };
        
        // Create a container for the notification if it doesn't exist
        let container = document.getElementById('new-device-notifications');
        if (!container) {
            container = document.createElement('div');
            container.id = 'new-device-notifications';
            container.style.position = 'fixed';
            container.style.top = '20px';
            container.style.right = '20px';
            container.style.zIndex = '9999';
            container.style.display = 'flex';
            container.style.flexDirection = 'column';
            container.style.gap = '10px';
            document.body.appendChild(container);
        }
        
        // Add the notification to the container
        container.appendChild(notification);
        
        // Auto-remove after 30 seconds
        setTimeout(() => {
            if (notification.parentNode) {
                notification.parentNode.removeChild(notification);
            }
        }, 30000);
    });
};

/**
 * Sets up global listeners for incoming verification requests
 */
export const setupGlobalVerificationListeners = () => {
    const client = getMatrixClient();
    
    // Listen for incoming verification requests
    client.on('crypto.verificationRequestReceived', (verificationRequest) => {
        if (DEBUG_DEVICE_VERIFICATION) {
            console.log('Verification request received:', verificationRequest);
        }
        
        // Store the verification request in a global array for access in components
        if (!globalThis.verificationRequests) {
            globalThis.verificationRequests = [];
        }
        
        globalThis.verificationRequests.push(verificationRequest);
        
        // Dispatch an event to notify the UI
        const event = new CustomEvent('verification-request-received', {
            detail: { verificationRequest }
        });
        window.dispatchEvent(event);
    });
    
    // Initialize known devices
    initializeKnownDevices();
    
    // Set up periodic check for new devices (every 5 minutes)
    setInterval(checkForNewDevices, 5 * 60 * 1000);
}; 
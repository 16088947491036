import {html, nothing} from 'lit-html';
import BElement from '../../BElement';
import {setDeviceVerificationState} from '../control/DevicesControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {
    requestDeviceVerification,
    startSASVerification,
    confirmSASMatch,
    cancelVerification
} from '../../matrix/control/DeviceVerification.js';
import './DeviceVerification.css';

class DeviceVerificationSender extends BElement {
    connectedCallback() {
        super.connectedCallback();
        setDeviceVerificationState({name: 'device_id', value: this.location.params.deviceId});
        setDeviceVerificationState({name: 'request_state', value: null});
        setDeviceVerificationState({name: 'saas_emoji', value: null});
        setDeviceVerificationState({name: 'saas_short_auth_string', value: null});
    }

    extractState(state) {
        return {
            verification: state.devices.verification,
        }
    }

    view() {
        if (this.state.verification.request_state === null) {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device ${this.state.verification.device_id}</h2>
                        <div>
                            With this, you are initiating the verification process, which consists of several steps. To
                            begin, simply click 'Send Request'.
                        </div>
                        <kosyma-button
                                identifier='Send'
                                label='Send Request'
                                @click=${this.onSendVerificationRequest}
                        ></kosyma-button>
                    </div>
                </div>`
        } else if (this.state.verification.request_state === 'ready') {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device ${this.state.verification.device_id}</h2>
                        <div>
                            The verification process has started. Please wait for the other device to accept the request.
                        </div>
                    </div>
                </div>`
        } else if (this.state.verification.request_state === 'show_sas') {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device ${this.state.verification.device_id}</h2>
                        <div>
                            Compare the emojis below with those shown on the other device. If they match, click 'They Match'.
                            If they don't match, click 'They Don't Match' to cancel the verification.
                        </div>
                        <div class="emoji-container">
                            ${this.state.verification.saas_emoji ? this.state.verification.saas_emoji.map((emoji, index) => {
                                // Handle different possible emoji data structures
                                let emojiChar = '';
                                let emojiName = '';
                                
                                if (Array.isArray(emoji) && emoji.length >= 2) {
                                    emojiChar = emoji[0];
                                    emojiName = emoji[1];
                                } else if (typeof emoji === 'object' && emoji !== null) {
                                    emojiChar = emoji.emoji || '🔒';
                                    emojiName = emoji.name || 'Lock';
                                } else if (typeof emoji === 'string') {
                                    emojiChar = emoji;
                                    emojiName = `Emoji ${index + 1}`;
                                }
                                
                                return html`
                                    <div class="emoji-item">
                                        <span class="emoji">${emojiChar}</span>
                                        <span class="emoji-name">${emojiName}</span>
                                    </div>
                                `;
                            }) : nothing}
                        </div>
                        <div class="decimal-container">
                            ${this.state.verification.saas_short_auth_string ? html`
                                <div>Decimal code: ${this.state.verification.saas_short_auth_string}</div>
                            ` : nothing}
                        </div>
                        <div class="button-container">
                            <kosyma-button
                                    identifier='Match'
                                    label='They Match'
                                    @click=${this.onConfirmMatch}
                            ></kosyma-button>
                            <kosyma-button
                                    identifier='NoMatch'
                                    label='They Don\'t Match'
                                    @click=${this.onRejectMatch}
                            ></kosyma-button>
                        </div>
                    </div>
                </div>`
        } else if (this.state.verification.request_state === 'done') {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verification Complete</h2>
                        <div>
                            The device has been successfully verified.
                        </div>
                        <kosyma-button
                                identifier='Back'
                                label='Back to Devices'
                                @click=${() => window.history.back()}
                        ></kosyma-button>
                    </div>
                </div>`
        } else if (this.state.verification.request_state === 'cancelled') {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verification Cancelled</h2>
                        <div>
                            The verification process was cancelled.
                        </div>
                        <kosyma-button
                                identifier='Back'
                                label='Back to Devices'
                                @click=${() => window.history.back()}
                        ></kosyma-button>
                    </div>
                </div>`
        }
    }

    onSendVerificationRequest = async () => {
        try {
            // Store the verification object for later use
            this.verification = await requestDeviceVerification(this.state.verification.device_id);
            
            // Start the SAS verification when ready
            this.verification.on('change', () => {
                if (this.verification.ready && !this.verification.done && !this.verification.cancelled) {
                    this.startVerification(this.verification);
                }
            });
            
            console.log('Verification request sent.');
        } catch (error) {
            console.error('Error sending verification request:', error);
        }
    }

    // Start the SAS verification process
    startVerification = async verification => {
        try {
            // Start the SAS verification
            this.sas = await startSASVerification(verification);
        } catch (error) {
            console.error('Error starting verification:', error);
        }
    }
    
    // Confirm that the SAS codes match
    onConfirmMatch = async () => {
        try {
            await confirmSASMatch();
            setDeviceVerificationState({name: 'request_state', value: 'done'});
        } catch (error) {
            console.error('Error confirming match:', error);
        }
    }
    
    // Reject the SAS codes as not matching
    onRejectMatch = async () => {
        try {
            if (this.verification) {
                await cancelVerification(this.verification, 'Codes did not match');
                setDeviceVerificationState({name: 'request_state', value: 'cancelled'});
            }
        } catch (error) {
            console.error('Error rejecting match:', error);
        }
    }
}

customElements.define('kosyma-device-verification-sender', DeviceVerificationSender); 
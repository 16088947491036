import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {isDeviceVerified, checkForNewDevices} from '../../matrix/control/DeviceVerification.js';

export const getDevices = async () => {
    const {devices} = await getMatrixClient().getDevices();

    return await Promise.all(
        devices.map(async (device) => {
            const isVerified = await isDeviceVerified(device.device_id);
            return {
                display_name: device.display_name,
                user_id: device.user_id,
                device_id: device.device_id,
                is_verified: isVerified,
                last_seen_ip: device.last_seen_ip,
                last_seen_ts: device.last_seen_ts
            };
        })
    );
};

/**
 * Deletes a device with the given ID
 * @param {string} deviceId - The ID of the device to delete
 * @param {Object|undefined} authObj - Authentication object for the request, undefined for no auth
 * @returns {Promise<Object>} - The response from the server
 */
export const deleteDevice = async (deviceId, authObj) => {
    const client = getMatrixClient();
    
    // Use the Matrix client's deleteMultipleDevices method which sends a POST request
    // to /_matrix/client/v3/delete_devices with an array of device IDs
    // Pass authObj directly - if it's undefined, the SDK will handle it correctly
    return await client.deleteMultipleDevices([deviceId], authObj);
};

/**
 * Manually checks for new devices
 * @returns {Promise<Array>} - Array of new devices if any are found
 */
export const checkForNewDevicesManually = async () => {
    return await checkForNewDevices();
};
import Olm from '@matrix-org/olm';
import {Router} from '@vaadin/router';
import store from './store.js';
import {testVersion} from './versionChecker.js';
import './form/boundary/Button.js';
import './form/boundary/InputText.js';
import './form/boundary/Checkbox.js';
import './form/boundary/DatePicker.js';
import './form/boundary/FormattedJson.js';
import './form/boundary/Icon.js';
import './securitycheck/boundary/SecurityCheckPassword.js';
import './messages/boundary/Messages.js';
import './login/boundary/Login.js';
import './login/boundary/LoginDetectBackend.js';
import './login/boundary/LoginByPassword.js';
import './login/boundary/LoginUnknownServer.js';
import './login/boundary/LoginByOrgAdmin.js';
import './register/boundary/Register.js';
import './register/boundary/RegisterByAdminOrg.js';
import './register/boundary/RegisterByPassword.js';
import './room/boundary/RoomList.js';
import './room/boundary/RoomCreate.js';
import './home/boundary/Home.js';
import './home/boundary/Invitations.js';
import './footer/boundary/Footer.js';
import './header/boundary/Header.js';
import './matrix/boundary/Matrix.js';
import './matrix/boundary/ChangeServer.js';
import './content/boundary/Main.js';
import './content/boundary/Container.js';
import './content/boundary/Content.js';
import './room/boundary/Room.js';
import './room/boundary/RoomChat.js';
import './room/boundary/RoomInvitation.js';
import './room/boundary/RoomAdmin.js';
import './room/boundary/RoomMembers.js';
import './room/boundary/InitialsCircle.js';
import './room/boundary/RoomChatMessage.js';
import './room/boundary/RoomChatInputField.js';
import './room/boundary/RoomChatReply.js';
import './room/boundary/RoomVzdSearch.js';
import './devices/boundary/DeviceList.js';
import './devices/boundary/DeviceVerificationError.js';
import './devices/boundary/DeviceVerificationSender.js';
import './devices/boundary/DeviceVerificationSenderStart.js';
import './devices/boundary/DeviceVerificationReceiver.js';
import './notification/boundary/Notifications.js';
import './notification/boundary/NewDeviceNotification.js';
import './orgadmin/boundary/OrgAdminMenu.js';
import './orgadmin/boundary/OrgAdminUsersList.js';
import './orgadmin/boundary/OrgAdminUsersEdit.js';
import './orgadmin/boundary/OrgAdminDisplayName.js';
import './orgadmin/boundary/OrgAdminFhirSearch.js';
import './orgadmin/boundary/OrgAdminContactMgmtList.js';
import './users/boundary/UserDirectory.js';
import {extractParamHref} from './AppParamExtractor.js';
import {loginByToken} from './login/control/LoginClient.js';
import {addErrorMessage} from './messages/control/MessagesControl.js';
import {setMatrixUpdateState, setSessionState} from './matrix/control/MatrixControl.js';
import {createMatrixClient, getMatrixClient} from './matrix/control/MatrixClient.js';
import {addSystemSecurityUpdateNotification} from './notification/control/NotificationsControl.js';
import {initializeSettings} from './settings/control/SettingsControl.js';
import './settings/boundary/Settings.js';
import {agentIdentifier, appName} from './app.config.js';

// Define a constant for storing the last location
const LAST_LOCATION_KEY = `${appName}.last.location`;

(async () => {
    try {
        await Olm.init();
        globalThis.Olm = Olm;
        console.log('✅ Olm initialized successfully with custom WASM path');
    } catch (error) {
        console.error('❌ Error initializing Olm:', error);
    }
})();

// Listen for navigation events to save the current path
window.addEventListener('vaadin-router-location-changed', (event) => {
    const path = event.detail.location.pathname + event.detail.location.search;
    // Save current location except for paths that shouldn't be restored
    if (path !== '/' && path !== '/login' && path !== '/register' && path !== '/change-server') {
        localStorage.setItem(LAST_LOCATION_KEY, path);
    }
});

(() => {
    window.addEventListener('load', async () => {
        // Check for existing session and client
        const session = store.getState().matrix.session;
        const matrixClient = getMatrixClient();
        
        // In development mode, try to restore session from localStorage if needed
        if (process.env.NODE_ENV === 'development') {
            if (!session) {
                const savedSession = localStorage.getItem(`${appName}.dev.session`);
                if (savedSession) {
                    try {
                        const sessionData = JSON.parse(savedSession);
                        setSessionState(sessionData);
                        setMatrixUpdateState();
                        
                        // If we don't have a client yet, create one
                        if (!matrixClient) {
                            await createMatrixClient(sessionData, sessionData.baseUrl);
                        }
                    } catch (e) {
                        console.warn('Failed to restore session from localStorage:', e);
                    }
                }
            }
            
            // After potential restoration, check again
            const updatedSession = store.getState().matrix.session;
            const updatedClient = getMatrixClient();
            
            // If we're at the root or login path but have a valid session and client, restore last location
            if ((window.location.pathname === '/' || window.location.pathname === '/login') && 
                updatedSession && updatedClient) {
                const lastLocation = localStorage.getItem(LAST_LOCATION_KEY);
                if (lastLocation && lastLocation.startsWith('/messenger')) {
                    Router.go(lastLocation);
                } else {
                    Router.go('/messenger/');
                }
                return;
            }
        }
        
        // For non-development mode or if we couldn't restore a session
        if (!session && window.location.pathname !== '/' && 
            window.location.pathname !== '/login' && 
            window.location.pathname !== '/register' && 
            window.location.pathname !== '/change-server') {
            // If no session and not on a public page, redirect to root
            window.history.replaceState(null, '', '/');
        }
    });
})();

store.subscribe(_ => {
    const state = store.getState();
    //save(state);
});

const sessionTest = async (context, commands) => {
    // Check if we have a client already initialized
    const matrixClient = getMatrixClient();
    const session = store.getState().matrix.session;
    
    // If we have a client but no session in the store, try to restore the session
    if (matrixClient && !session && process.env.NODE_ENV === 'development') {
        const savedSession = localStorage.getItem(`${appName}.dev.session`);
        if (savedSession) {
            try {
                const sessionData = JSON.parse(savedSession);
                setSessionState(sessionData);
                setMatrixUpdateState();
                return true;
            } catch (e) {
                console.warn('Failed to restore session from localStorage:', e);
            }
        }
    }
    
    // If we still don't have a session, redirect to login
    if (!session) {
        return commands.redirect('/login');
    }
    
    return true;
};

const rootPathHandler = async (context, commands) => {
    // Check if we have a client already initialized
    const matrixClient = getMatrixClient();
    const session = store.getState().matrix.session;
    
    // If we have a client and session, try to restore last location
    if (matrixClient && session) {
        const lastLocation = localStorage.getItem(LAST_LOCATION_KEY);
        if (lastLocation && lastLocation.startsWith('/messenger')) {
            return commands.redirect(lastLocation);
        } else {
            return commands.redirect('/messenger/');
        }
    }
    
    // Otherwise, show the login page
    return commands.component('kosyma-login');
};

const app = document.getElementById('outlet');
const router = new Router(app);
router.setRoutes([
    {path: '/', action: rootPathHandler},
    {path: '/login', component: 'kosyma-login'},
    {path: '/register', component: 'kosyma-register'},
    {
        path: '/messenger',
        action: sessionTest,
        children: [
            {path: '/', component: 'kosyma-home'},
            {path: '/rooms/', component: 'kosyma-room-list'},
            {path: '/rooms/create', component: 'kosyma-room-create'},
            {path: '/rooms/:roomId', component: 'kosyma-room'},
            {path: '/users/directory', component: 'kosyma-user-directory'},
            {path: '/settings', component: 'settings-view'},
            {path: '/orgadmin/menu', component: 'kosyma-orgadmin-menu'},
            {path: '/orgadmin/users/list', component: 'kosyma-orgadmin-users-list'},
            {path: '/orgadmin/users/:id/edit', component: 'kosyma-orgadmin-users-edit'},
            {path: '/orgadmin/users/:id/displayname', component: 'kosyma-orgadmin-display-name'},
            {path: '/orgadmin/fhir/search', component: 'kosyma-orgadmin-fhir-search'},
            {path: '/orgadmin/contactmgmt/list', component: 'kosyma-orgadmin-contactmgmt-list'},
            {path: '/devices/list', component: 'kosyma-device-list'},
            {path: '/devices/verification/receiver/', component: 'kosyma-device-verification-receiver'},
            {path: '/devices/verification/sender/:deviceId/send', component: 'kosyma-device-verification-sender'},
            {
                path: '/devices/verification/sender/:deviceId/start',
                component: 'kosyma-device-verification-sender-start'
            },
            {path: '/devices/verification/sender/:deviceId/error', component: 'kosyma-device-verification-error'},
        ],
    },
    {path: '/change-server', component: 'kosyma-change-server'},
    {path: '(.*)', component: 'kosyma-login'},
]);

initializeSettings();

// 🌟 Token-Login
const token = extractParamHref(location.href, 'loginToken');
if (token !== null) {
    loginByToken(store.getState().matrix.base_url, token)
        .then(res => {
            setSessionState(res);
            createMatrixClient(res, store.getState().matrix.base_url)
                .then(() => {
                    setMatrixUpdateState();
                    addSystemSecurityUpdateNotification();
                    const lastLocation = localStorage.getItem(LAST_LOCATION_KEY);
                    if (lastLocation && lastLocation.startsWith('/messenger')) {
                        Router.go(lastLocation);
                    } else {
                        Router.go('/messenger/');
                    }
                });
        })
        .catch(err => addErrorMessage(err));
}

(originalFetch => {
    window.fetch = (resource, init = {}) => {
        init.headers = init.headers || {};
        init.headers['X-TIM-User-Agent'] = agentIdentifier + ',' + window.navigator.userAgent;
        return originalFetch(resource, init);
    };
})(window.fetch);

testVersion();
setInterval(testVersion, 1000 * 60 * 15); // 15 minutes
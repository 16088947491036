import {html} from 'lit-html';
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js';
import {formatTimestamp} from '../../time/control/Converter.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class RoomList extends BElement {

    view() {
        const rooms = getMatrixClient().getRooms();
        
        rooms.sort((a, b) => {
            if (a.getLastActiveTimestamp() > b.getLastActiveTimestamp()) {
                return -1;
            }
            if (a.getLastActiveTimestamp() < b.getLastActiveTimestamp()) {
                return 1;
            }
            return 0;
        });

        // Helper function to check if a room is public
        const isPublicRoom = (room) => {
            const joinRules = room.currentState.getStateEvents('m.room.join_rules', '');
            return joinRules && joinRules.getContent() && joinRules.getContent().join_rule === 'public';
        };

        // Helper function to check if a room is a direct message
        const isDirectMessage = (room) => {
            try {
                const client = getMatrixClient();
                if (!client) return false;
    
                // Get the direct message map from account data
                const directEvent = client.getAccountData('m.direct');
                if (!directEvent || !directEvent.getContent()) return false;
    
                const directContent = directEvent.getContent();
                
                // Check if this room ID is in any user's direct message list
                return Object.values(directContent).some(rooms => 
                    Array.isArray(rooms) && rooms.includes(room.roomId)
                );
            } catch (error) {
                console.error('Error checking if room is direct message:', error);
                return false;
            }
        };

        // Helper function to get the other user's ID in a direct message
        const getDirectMessageUserId = (room) => {
            try {
                const client = getMatrixClient();
                if (!client || !isDirectMessage(room)) return null;
    
                const directEvent = client.getAccountData('m.direct');
                if (!directEvent || !directEvent.getContent()) return null;
    
                const directContent = directEvent.getContent();
                
                // Find which user this DM is with
                for (const [userId, roomIds] of Object.entries(directContent)) {
                    if (Array.isArray(roomIds) && roomIds.includes(room.roomId)) {
                        return userId;
                    }
                }
                
                return null;
            } catch (error) {
                console.error('Error getting direct message user ID:', error);
                return null;
            }
        };

        // Helper function to get the display name for a direct message room
        const getDirectMessageDisplayName = (room) => {
            try {
                if (!isDirectMessage(room)) return room.name;
                
                const otherUserId = getDirectMessageUserId(room);
                if (!otherUserId) return room.name;
                
                // Try to get the user's display name
                const member = room.getMember(otherUserId);
                if (member && member.name) {
                    return member.name;
                }
                
                // Fall back to user ID if no display name available
                return otherUserId;
            } catch (error) {
                console.error('Error getting direct message display name:', error);
                return room.name || 'Unknown Room';
            }
        };

        return html`
            <style>
                .room-list-container {
                    margin: 20px 0;
                }
                .room-list-table {
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 0;
                    border-radius: 8px;
                    overflow: hidden;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
                }
                .room-list-table thead {
                    background-color: #1f73a3; /* Sky Blue */
                    color: white;
                }
                .room-list-table th {
                    padding: 16px 20px;
                    text-align: left;
                    font-weight: 600;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    border: none;
                }
                .room-list-table td {
                    padding: 16px 20px;
                    border-bottom: 1px solid #e1e5e8; /* Light Grey */
                    vertical-align: middle;
                }
                .room-list-table tbody tr:last-child td {
                    border-bottom: none;
                }
                .room-list-table tbody tr:hover {
                    background-color: rgba(31, 115, 163, 0.05); /* Sky Blue with opacity */
                }
                .public-room-indicator {
                    display: inline-block;
                    background-color: #f39200; /* Dark Yellow */
                    color: white;
                    font-size: 12px;
                    padding: 4px 10px;
                    border-radius: 16px;
                    margin-left: 10px;
                    font-weight: 600;
                }
                .direct-message-indicator {
                    display: inline-block;
                    background-color: #2196F3; /* Blue */
                    color: white;
                    font-size: 12px;
                    padding: 4px 10px;
                    border-radius: 16px;
                    margin-left: 10px;
                    font-weight: 600;
                }
                .room-name {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    color: #241f21; /* Anthrazit */
                }
                .room-avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    margin-right: 12px;
                    object-fit: cover;
                    background-color: #f8f9fa;
                }
                .room-avatar-placeholder {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    margin-right: 12px;
                    background-color: #f8f9fa;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #908f87;
                    font-size: 16px;
                }
                .member-list {
                    max-height: 80px;
                    overflow-y: auto;
                    font-size: 13px;
                    color: #908f87; /* Warm Grey */
                }
                .member-item {
                    padding: 2px 0;
                }
                .timestamp {
                    color: #908f87; /* Warm Grey */
                    font-size: 13px;
                }
                .open-button {
                    min-width: 100px;
                }
                kosyma-button {
                    --button-background: #0f9e94; /* Soft Aqua */
                    --button-hover-background: #0d8a82; /* Darker Soft Aqua */
                    --button-text-color: white;
                    --button-border-radius: 6px;
                }
                h2 {
                    color: #241f21; /* Anthrazit */
                    font-size: 24px;
                    margin-bottom: 20px;
                    font-weight: 600;
                }
                .empty-state {
                    text-align: center;
                    padding: 40px 20px;
                    color: #908f87; /* Warm Grey */
                }
            </style>
            <div class='content-area'>
                <div class='inner'>
                    <h2>Rooms</h2>
                    <div class="room-list-container">
                        <table class="room-list-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Members</th>
                                <th>Last Update</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            ${rooms.length === 0 ? 
                                html`<tr><td colspan="4"><div class="empty-state">No rooms available</div></td></tr>` :
                                rooms.map(e => html`
                                <tr>
                                    <td>
                                        <div class="room-name">
                                            ${this.getRoomAvatar(e)}
                                            <div>
                                                ${isDirectMessage(e) ? 
                                                    html`${getDirectMessageDisplayName(e)}` : 
                                                    html`${e.name}`}
                                                ${isPublicRoom(e) ? html`<span class="public-room-indicator">Public</span>` : ''}
                                                ${isDirectMessage(e) ? html`<span class="direct-message-indicator">Direct Message</span>` : ''}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="member-list">
                                            ${Object.keys(e.currentState.members).map(member => html`
                                                <div class="member-item">${member}</div>`)}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="timestamp">${formatTimestamp(e.getLastActiveTimestamp())}</div>
                                    </td>
                                    <td>
                                        <kosyma-button
                                                identifier=${'open_' + e.roomId}
                                                label='Open'
                                                class="open-button"
                                                @click=${() => Router.go('/messenger/rooms/' + e.roomId)}
                                        ></kosyma-button>
                                    </td>
                                </tr>
                            `)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        `;
    }

    getRoomAvatar(room) {
        const avatarEvent = room.currentState.getStateEvents('m.room.avatar', '');
        if (avatarEvent && avatarEvent.getContent() && avatarEvent.getContent().url) {
            const mxcUrl = avatarEvent.getContent().url;
            const avatarUrl = getMatrixClient().mxcUrlToHttp(mxcUrl);
            return html`<img src=${avatarUrl} class="room-avatar" alt="${room.name}" @error=${this.onAvatarError}/>`;
        }
        return html`<div class="room-avatar-placeholder">${room.name.charAt(0).toUpperCase()}</div>`;
    }

    onAvatarError(e) {
        // Replace failed avatar with placeholder
        const roomName = e.target.alt;
        const placeholder = document.createElement('div');
        placeholder.className = 'room-avatar-placeholder';
        placeholder.textContent = roomName.charAt(0).toUpperCase();
        e.target.parentNode.replaceChild(placeholder, e.target);
    }
}

customElements.define('kosyma-room-list', RoomList);
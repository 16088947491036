import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import {setFhirSearchValueState} from '../control/OrgAdminControl.js';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';


class OrgAdminFhirSearch extends BElement {
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('debounced-input', this.onDebouncedInput);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('debounced-input', this.onDebouncedInput);
    }

    extractState(state) {
        return {
            matrix: state.matrix,
            fhir_search: state.orgAdmin.fhir_search
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <div class='inner'>
                        <h2>FHIR Search</h2>
                    </div>
                    <div class='inner'>
                        <div>
                            <kosyma-input-text
                                    identifier='filter'
                                    label='Filter'
                                    name='filter'
                                    autofocus='true'
                                    value=${this.state.fhir_search.filter}
                                    @input=${e => this.onInputChange(e)}
                            ></kosyma-input-text>
                        </div>
                    </div>
                    ${this.state.fhir_search.results ? html`
                        <div class='inner'>
                            <h2>Results</h2>
                            <table>
                                <thead>
                                <tr>
                                    <th>Address</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                ${this.state.fhir_search.results.map(e => html`
                                    <tr>
                                        <td>${e.address}</td>
                                        <td>${e.name}</td>
                                        <td>${e.status}</td>
                                    </tr>
                                `)}
                                </tbody>
                            </table>
                        </div>
                    ` : nothing}
                </div>
            </div>
        `
    }

    onInvite(e) {

    }

    onInputChange = ({target: {name, value}}) => {
        setFhirSearchValueState({name, value});
        this.debouncedUpdate(value);
    }

    onDebouncedInput = () => {
        this.search().then(r => setFhirSearchValueState({name: 'results', value: r})).catch(e => addErrorMessage(e));
    }

    debounce = (func, wait) => {
        return (...args) => {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => func.apply(this, args), wait);
        };
    }

    search = async () => {
        const url = `${this.state.matrix.base_url}/admin/v1/search/endpoint?contains=${this.state.fhir_search.filter}`;
        const fetching = await fetch(url);
        return await fetching.json();
    }

    debouncedUpdate = this.debounce(value => {
        this.dispatchEvent(new CustomEvent('debounced-input', {
            detail: {value}
        }));
    }, 1000);
}

customElements.define('kosyma-orgadmin-fhir-search', OrgAdminFhirSearch);

import {html} from 'lit-html';
import BElement from '../../BElement.js';
import {build} from '../../version.js';
import '../css/footer.css'


class Footer extends BElement {
    view() {
        return html`
            <footer>
                <div class="footer-content">
                    <span>Kontakt: <a href="mailto:info@kosyma.de">info@kosyma.de</a> / +49 123 456789</span>
                    <span class="separator">&bull;</span>
                    <span>Build ${build.time}</span>
                    <span class="separator">&bull;</span>
                    <span>&copy; 2025 Kosyma GmbH. Alle Rechte vorbehalten.</span>
                </div>
            </footer>`
    }
}

customElements.define('kosyma-footer', Footer);

import {html} from 'lit-html';
import BElement from '../../BElement.js';
import * as maplibregl from 'maplibre-gl';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';
import {
    createMarker,
    reverseGeocode as reverseGeocodeAddress,
    geocodeSearch,
    findMapStyleUrl
} from './LocationMap.js';

/**
 * Location picker component for selecting and sharing a location
 */
class LocationPicker extends BElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        
        // Initialize properties first
        this.mapInstance = null;
        this.marker = null;
        this.selectedLocation = null;
        this.isLoading = false;
        this.geocodingResults = [];
        this.searchValue = '';
        this.mapInitialized = false;
        this._mapInitTimer = null;
        this.isLoadingLocation = false;
        this.isSearching = false;
        this.searchResults = [];
        this.useCurrentLocation = false;
        this.map = null;
        
        // Define event handler methods inline to avoid binding issues
        this.handleMapClick = (e) => {
            const coords = {
                longitude: e.lngLat.lng, 
                latitude: e.lngLat.lat
            };
            
            this.updateSelectedLocation({
                name: null,
                latitude: coords.latitude,
                longitude: coords.longitude
            });
            
            // Create or update the marker
            this.createMarker();
        };
        
        // Define onMapClick inline too since it's used in map event binding
        this.onMapClick = (e) => {
            const coords = {
                longitude: e.lngLat.lng, 
                latitude: e.lngLat.lat
            };
            
            this.updateSelectedLocation({
                name: null,
                latitude: coords.latitude,
                longitude: coords.longitude
            });
            
            // Create or update the marker
            this.createMarker();
        };
        
        // Bind all methods to this instance to preserve context
        this.getCurrentLocationExplicit = this.getCurrentLocationExplicit.bind(this);
        this.search = this.search.bind(this);
        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onShare = this.onShare.bind(this);
        this.handleMarkerDragEnd = this.handleMarkerDragEnd.bind(this);
        this.onCurrentLocationClick = this.onCurrentLocationClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSendLocationClick = this.onSendLocationClick.bind(this);
        this.initMap = this.initMap.bind(this);
        this.createMarker = this.createMarker.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.updateSelectedLocation = this.updateSelectedLocation.bind(this);
        this.reverseGeocode = this.reverseGeocode.bind(this);
        
        // Add MapLibre CSS to the shadow DOM
        const maplibreStyle = document.createElement('link');
        maplibreStyle.rel = 'stylesheet';
        maplibreStyle.href = 'https://unpkg.com/maplibre-gl@5.2.0/dist/maplibre-gl.css';
        this.shadowRoot.appendChild(maplibreStyle);
        
        // Add our custom styles to the shadow DOM
        const style = document.createElement('style');
        style.textContent = `
            .location-picker-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                max-width: 100%;
                margin: 0;
                gap: 12px;
                padding: 16px;
                box-sizing: border-box;
                background-color: white;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                overflow: hidden;
            }
            
            .location-picker-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
                padding-bottom: 8px;
                border-bottom: 1px solid #eeeeee;
            }
            
            .location-picker-title {
                font-size: 18px;
                font-weight: 500;
                color: #333;
            }
            
            .location-picker-close-button {
                background: transparent;
                border: none;
                cursor: pointer;
                padding: 4px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            .location-picker-close-button:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
            
            .location-map-container {
                width: 100%;
                height: 320px;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid #e0e0e0;
                background-color: #f5f5f5;
                position: relative;
                flex-shrink: 0;
            }
            
            .location-map {
                width: 100%;
                height: 100%;
            }
            
            .map-controls {
                position: absolute;
                bottom: 16px;
                left: 16px;
                z-index: 10;
            }
            
            .current-location-button {
                background-color: white;
                color: #1E88E5;
                border: none;
                border-radius: 4px;
                padding: 8px 12px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                transition: all 0.2s ease;
            }
            
            .current-location-button:hover {
                background-color: #f5f5f5;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
            }
            
            .current-location-button:disabled {
                background-color: #f5f5f5;
                color: #9e9e9e;
                cursor: not-allowed;
                box-shadow: none;
            }
            
            .location-preview {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px;
                background-color: #f5f5f5;
                border-radius: 8px;
                border: 1px solid #e0e0e0;
                flex-shrink: 0;
            }
            
            .location-details {
                flex: 1;
                overflow: hidden;
            }
            
            .location-name {
                font-weight: 500;
                margin-bottom: 4px;
                font-size: 14px;
                color: #333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            .location-coordinates {
                font-family: monospace;
                font-size: 12px;
                color: #757575;
            }
            
            .location-share-button {
                background-color: #1E88E5;
                color: white;
                border: none;
                border-radius: 4px;
                padding: 8px 16px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                transition: background-color 0.2s ease;
            }
            
            .location-share-button:hover {
                background-color: #1976D2;
            }
            
            .location-share-button:disabled {
                background-color: #BDBDBD;
                cursor: not-allowed;
            }
            
            /* MapLibre overrides */
            .maplibregl-ctrl-top-right {
                top: 10px;
                right: 10px;
            }
            
            .maplibregl-ctrl {
                margin: 0 0 10px 0;
                box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
                border-radius: 4px;
            }
            
            .maplibregl-ctrl-attrib {
                font-size: 12px;
                padding: 4px 8px;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 4px 0 0 0;
                color: #333;
            }
            
            .maplibregl-ctrl-attrib-button {
                display: none;
            }
            
            .maplibregl-ctrl-attrib a {
                color: #1976D2;
                text-decoration: none;
            }
            
            .map-error {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: rgba(255, 255, 255, 0.9);
                padding: 12px 16px;
                border-radius: 4px;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
                color: #f44336;
                font-size: 14px;
                max-width: 80%;
                text-align: center;
            }
        `;
        this.shadowRoot.appendChild(style);
    }

    // Override getRenderTarget to render into shadowRoot
    getRenderTarget() {
        return this.shadowRoot;
    }

    extractState(state) {
        return {
            room: state.rooms.room,
        };
    }

    view() {
        return html`
            <div class="location-picker-container">
                <div class="location-picker-header">
                    <div class="location-picker-title">Share Location</div>
                    <button class="location-picker-close-button" @click=${this.onCancelClick}>
                        ✕
                    </button>
                </div>
                
                <div class="location-map-container">
                    <div id="location-picker-map" class="location-map"></div>
                    
                    <div class="map-controls">
                        <button class="current-location-button" 
                                @click=${this.onCurrentLocationClick}
                                ?disabled=${this.isLoadingLocation}>
                            ${this.isLoadingLocation 
                                ? 'Getting location...' 
                                : html`
                                    📍 Use my current location
                                `}
                        </button>
                    </div>
                </div>
                
                ${this.selectedLocation ? html`
                    <div class="location-preview">
                        <div class="location-details">
                            <div class="location-name">${this.selectedLocation.description || 'Selected location'}</div>
                            <div class="location-coordinates">
                                ${this.selectedLocation.latitude.toFixed(6)}, 
                                ${this.selectedLocation.longitude.toFixed(6)}
                            </div>
                        </div>
                        <button class="location-share-button" @click=${this.onSendLocationClick}>
                            Share Location
                        </button>
                    </div>
                ` : ''}
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        
        // After a short delay to ensure the DOM is ready
        this._mapInitTimer = setTimeout(() => {
            this.initMap();
        }, 300);
    }

    disconnectedCallback() {
        
        // Clear any pending timers
        if (this._mapInitTimer) {
            clearTimeout(this._mapInitTimer);
            this._mapInitTimer = null;
        }
        
        // Clean up map resources and event handlers
        if (this.map) {
            try {
                // Remove event handlers
                this.map.off('click', this.onMapClick);
                
                // Remove the map
                this.map.remove();
                this.map = null;
                
                // Remove marker
                if (this.marker) {
                    this.marker.remove();
                    this.marker = null;
                }
                
            } catch (e) {
                console.error('Error cleaning up map resources:', e);
            }
        }
        
        // Also clean up any legacy map instances
        this.cleanupMap();
        
        super.disconnectedCallback();
    }

    /**
     * Initialize map using the utility functions
     */
    initMap() {
        try {
            if (this.map) {
                return;
            }

            // Double check that we have all required methods and properties
            if (!this.onMapClick) {
                console.error("Map click handler not defined");
                this.onMapClick = (e) => {
                    const coords = {
                        longitude: e.lngLat.lng, 
                        latitude: e.lngLat.lat
                    };
                    
                    if (this.updateSelectedLocation) {
                        this.updateSelectedLocation({
                            name: null,
                            latitude: coords.latitude,
                            longitude: coords.longitude
                        });
                    }
                    
                    if (this.createMarker) {
                        this.createMarker();
                    }
                };
            }
            
            // Get the shadow root safely
            if (!this.shadowRoot) {
                console.error("Shadow root not available, cannot initialize map");
                setTimeout(() => this.initMap(), 200);
                return;
            }
            
            // Ensure the map container exists
            const mapContainer = this.shadowRoot.getElementById('location-picker-map');
            if (!mapContainer) {
                console.error("Map container not found, waiting for DOM to be ready");
                // Wait a bit and try again, in case the DOM is still rendering
                setTimeout(() => this.initMap(), 200);
                return;
            }
            
            
            // Make sure maplibregl is available
            if (!maplibregl) {
                console.error("maplibregl library not found!");
                const errorMsg = document.createElement('div');
                errorMsg.className = 'map-error';
                errorMsg.textContent = 'Map library not available';
                mapContainer.appendChild(errorMsg);
                return;
            }
            
            // Use the map style from LocationMap utility
            const mapStyle = findMapStyleUrl();
            
            // Create map instance directly using the container element
            // (not using the helper function since we have shadowRoot)
            try {
                this.map = new maplibregl.Map({
                    container: mapContainer,
                    style: mapStyle,
                    trackResize: false,
                    renderWorldCopies: true,
                    attributionControl: {
                        compact: false // Display full attribution
                    }
                });
                
                // Disable rotation to prevent user confusion
                if (this.map.dragRotate) {
                    this.map.dragRotate.disable();
                }
                
                if (this.map.touchZoomRotate) {
                    this.map.touchZoomRotate.disableRotation();
                }
                
                // Add only navigation controls in top-right corner
                this.map.addControl(new maplibregl.NavigationControl({
                    showCompass: false
                }), 'top-right');
                
                // Define Germany bounds (southwest corner, northeast corner)
                const germanyBounds = [
                    [5.9, 47.3], // Southwest coordinates [lng, lat]
                    [15.0, 55.0]  // Northeast coordinates [lng, lat]
                ];

                // Fit the map to Germany's bounds with some padding
                this.map.fitBounds(germanyBounds, {
                    padding: 20,
                    maxZoom: 8,
                    duration: 0 // No animation on initial load
                });
                
                // Add event listeners using the properly bound method
                this.map.on('click', this.onMapClick);
                
                this.map.once('load', () => {
                    // Force resize to ensure map displays correctly
                    this.map.resize();
                    
                    // Create the marker if it doesn't exist yet
                    if (!this.marker && this.selectedLocation) {
                        this.createMarker();
                    }
                    
                    // If user has requested current location, get it now
                    if (this.useCurrentLocation) {
                        this.getCurrentLocation();
                    }
                });
                
                this.map.on('error', (e) => {
                    console.error("Map error:", e);
                    if (mapContainer && mapContainer.isConnected) {
                        mapContainer.innerHTML = '<div class="map-error">Failed to load map. Please check your internet connection.</div>';
                    }
                });
            } catch (mapError) {
                console.error("Error creating map instance:", mapError);
                mapContainer.innerHTML = '<div class="map-error">Could not create map: ' + mapError.message + '</div>';
                return;
            }
        } catch (error) {
            console.error("Failed to initialize map:", error);
            try {
                const mapContainer = this.shadowRoot ? this.shadowRoot.getElementById('location-picker-map') : null;
                if (mapContainer && mapContainer.isConnected) {
                    mapContainer.innerHTML = '<div class="map-error">Failed to initialize map: ' + error.message + '</div>';
                }
            } catch (displayError) {
                console.error("Could not display error message:", displayError);
            }
        }
    }
    
    /**
     * Handle map click events
     */
    onMapClick(e) {
        const coords = {
            longitude: e.lngLat.lng, 
            latitude: e.lngLat.lat
        };
        
        this.updateSelectedLocation({
            name: null,
            latitude: coords.latitude,
            longitude: coords.longitude
        });
        
        // Create or update the marker
        this.createMarker();
    }
    
    /**
     * Handle marker drag end events
     */
    handleMarkerDragEnd() {
        if (!this.marker) return;
        
        const lngLat = this.marker.getLngLat();
        const coords = {
            longitude: lngLat.lng,
            latitude: lngLat.lat
        };
        
        this.updateSelectedLocation({
            ...coords,
            name: 'Custom location'
        });
        
        // Try to get location name
        this.reverseGeocode(coords.latitude, coords.longitude);
    }

    /**
     * Clean up map resources
     */
    cleanupMap() {
        if (this.mapInstance) {
            try {
                this.mapInstance.remove();
            } catch (e) {
                console.error('Error removing map:', e);
            }
            this.mapInstance = null;
        }
        this.mapInitialized = false;
    }

    /**
     * Explicitly request current location when button is clicked
     */
    getCurrentLocationExplicit() {
        
        try {
            // Start loading state immediately
            this.isLoadingLocation = true;
            this.triggerViewUpdate();
            
            // Check if map is initialized - if not, initialize it first
            if (!this.mapInstance || !this.mapInitialized) {

                // Initialize map 
                this.initMap();
                
                // Wait a moment for map to initialize before requesting location
                setTimeout(() => {
                    if (this.mapInstance && this.mapInitialized) {
                        this.getCurrentLocation();
                    } else {
                        console.error('Map failed to initialize');
                        this.isLoadingLocation = false;
                        this.triggerViewUpdate();
                        addErrorMessage('Could not initialize map. Please try again.');
                    }
                }, 1000);
                return;
            }
            
            // Map is already initialized, get location directly
            this.getCurrentLocation();
        } catch (error) {
            console.error('Error in getCurrentLocationExplicit:', error);
            this.isLoadingLocation = false;
            this.triggerViewUpdate();
            addErrorMessage('An error occurred while trying to get your location. Please try again.');
        }
    }

    /**
     * Get user's current location from browser
     */
    getCurrentLocation() {
        this.isLoadingLocation = true;
        this.triggerViewUpdate();
        
        if (!navigator.geolocation) {
            addErrorMessage("Geolocation is not supported by your browser");
            this.isLoadingLocation = false;
            this.triggerViewUpdate();
            return;
        }
        
        // Check if map is initialized
        if (!this.map) {
            this.useCurrentLocation = true;
            return;
        }
        
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const coords = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                };
                
                // Update the selected location
                this.updateSelectedLocation({
                    name: "Your location",
                    ...coords
                });
                
                // Create or update marker
                this.createMarker();
                
                // Stop loading
                this.isLoadingLocation = false;
                this.triggerViewUpdate();
            },
            (error) => {
                console.error("Geolocation error:", error);
                
                let errorMessage = "Failed to get your location";
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        errorMessage = "You denied the request for geolocation";
                        break;
                    case error.POSITION_UNAVAILABLE:
                        errorMessage = "Location information is unavailable";
                        break;
                    case error.TIMEOUT:
                        errorMessage = "The request to get your location timed out";
                        break;
                }
                
                addErrorMessage(errorMessage);
                this.isLoadingLocation = false;
                this.triggerViewUpdate();
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
    }

    /**
     * Handle search input changes
     */
    handleSearchInput(e) {
        this.searchValue = e.target.value;
    }

    /**
     * Geocode search query using the utility function
     */
    async search() {
        if (!this.searchValue.trim() || this.isLoading) return;
        
        this.isLoading = true;
        this.triggerViewUpdate();
        
        try {
            // Use the geocodeSearch utility
            this.geocodingResults = await geocodeSearch(this.searchValue);
        } catch (error) {
            console.error('Geocoding error:', error);
            addErrorMessage('Location search failed. Please try again.');
            this.geocodingResults = [];
        } finally {
            this.isLoading = false;
            this.triggerViewUpdate();
        }
    }

    /**
     * Reverse geocode coordinates to get a location name
     */
    reverseGeocode(latitude, longitude) {
        return reverseGeocodeAddress(latitude, longitude);
    }

    /**
     * Select a location from search results
     */
    selectLocation(result) {
        // Check if map instance exists
        if (!this.mapInstance) {
            console.error('Map instance not available');
            return;
        }
        
        const coords = {
            latitude: result.latitude,
            longitude: result.longitude
        };
        
        // Update map
        this.mapInstance.setCenter([coords.longitude, coords.latitude]);
        this.mapInstance.setZoom(10);
        
        // Update marker
        if (!this.marker) {
            this.marker = createMarker(coords, true);
        }
        
        this.marker.setLngLat([coords.longitude, coords.latitude]).addTo(this.mapInstance);
        
        // Update selected location
        this.updateSelectedLocation({
            ...coords,
            name: result.place_name
        });
        
        // Clear search results
        this.geocodingResults = [];
        this.triggerViewUpdate();
    }

    /**
     * Update the selected location
     * @param {Object} location - The location object with latitude and longitude
     */
    updateSelectedLocation(location) {
        this.selectedLocation = location;
        
        // Try to get a readable address for the location
        this.reverseGeocode(location.latitude, location.longitude)
            .then(description => {
                this.selectedLocation.description = description;
                this.triggerViewUpdate();
            })
            .catch(error => {
                console.error("Reverse geocoding failed:", error);
                this.selectedLocation.description = `Custom location`;
                this.triggerViewUpdate();
            });
    }

    /**
     * Handle cancel button click
     */
    onCancel() {
        // Fire a custom event to notify parent
        const event = new CustomEvent('location-canceled', {
            bubbles: true, 
            composed: true
        });
        this.dispatchEvent(event);
    }

    /**
     * Handle share button click
     */
    onShare() {
        if (!this.selectedLocation) {
            addErrorMessage('Please select a location first');
            return;
        }
        
        // Fire a custom event to notify parent with location data
        const event = new CustomEvent('location-selected', {
            detail: {
                latitude: this.selectedLocation.latitude,
                longitude: this.selectedLocation.longitude,
                description: this.selectedLocation.name
            },
            bubbles: true, 
            composed: true
        });
        this.dispatchEvent(event);
    }

    /**
     * Create a marker at the selected location
     */
    createMarker() {
        if (!this.map || !this.selectedLocation) {
            return;
        }
        
        // Remove existing marker
        if (this.marker) {
            this.marker.remove();
        }
        
        // Create new marker using utility function
        const coords = {
            latitude: this.selectedLocation.latitude,
            longitude: this.selectedLocation.longitude
        };
        
        this.marker = createMarker(coords, true);
        this.marker.addTo(this.map);
        
        // Add drag end event for the marker
        this.marker.on('dragend', () => {
            const lngLat = this.marker.getLngLat();
            this.updateSelectedLocation({
                name: null,
                latitude: lngLat.lat,
                longitude: lngLat.lng
            });
        });
        
        // Center the map on the marker
        this.map.flyTo({
            center: [this.selectedLocation.longitude, this.selectedLocation.latitude],
            zoom: 13,
            essential: true
        });
    }

    /**
     * Handle the click event for "Use my current location" button
     */
    onCurrentLocationClick() {
        if (!this.isLoadingLocation) {
            // Set flag for tracking current location request
            this.isLoadingLocation = true;
            this.useCurrentLocation = true;
            this.triggerViewUpdate();
            
            // Check if map is already initialized
            if (this.map) {
                this.getCurrentLocation();
            } else {
                this.initMap();
                // Try to initialize the map immediately
                try {
                    this.initMap();
                    // The map's onload event will trigger getCurrentLocation if useCurrentLocation is true
                } catch (error) {
                    console.error("Error initializing map for current location:", error);
                    this.isLoadingLocation = false;
                    this.triggerViewUpdate();
                    addErrorMessage("Could not initialize map. Please try again.");
                }
            }
        } else {
            console.log("Location request already in progress");
        }
    }

    /**
     * Handle cancel button click
     */
    onCancelClick() {
        // Fire a custom event to notify parent
        const event = new CustomEvent('location-canceled', {
            bubbles: true, 
            composed: true
        });
        this.dispatchEvent(event);
    }

    /**
     * Handle share location button click
     */
    onSendLocationClick() {
        if (!this.selectedLocation) {
            addErrorMessage('Please select a location first');
            return;
        }
        
        // Fire a custom event to notify parent with location data
        const event = new CustomEvent('location-selected', {
            detail: {
                latitude: this.selectedLocation.latitude,
                longitude: this.selectedLocation.longitude,
                description: this.selectedLocation.description || 'Shared location'
            },
            bubbles: true, 
            composed: true
        });
        this.dispatchEvent(event);
    }
}

customElements.define('kosyma-location-picker', LocationPicker); 
export const calculateBackoff = (attempt) => {
    const baseDelay = 5000; // 5 seconds
    const maxDelay = 60000; // 60 seconds max
    const backoff = Math.min(maxDelay, baseDelay * (attempt + 1));
    return backoff;
};

export const getRetryMessage = (seconds) => {
    if (seconds >= 60) {
        return "1 minute";
    }
    return `${seconds} seconds`;
}; 
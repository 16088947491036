export const uploadSmartContent = async (matrixClient, file, serverName) => {
    const MAX_SIZE_BYTES = 6 * 1024 * 1024; // 6 MB

    if (file.size <= MAX_SIZE_BYTES) {
        return await matrixClient.uploadContent(file, {
            name: file.name,
            type: file.type,
            rawResponse: false,
        });
    }

    console.log('Using MSC3870 2-step upload for large file');

    const baseUrl = matrixClient.baseUrl;
    const accessToken = matrixClient.getAccessToken();

    const createRes = await fetch(`${baseUrl}/v1/create`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/octet-stream'
        },
        body: file
    });

    if (!createRes.ok) {
        throw new Error(`Failed to create upload session: ${createRes.statusText}`);
    }

    const {media_id: mediaId} = await createRes.json();
    if (!mediaId) {
        throw new Error('Missing mediaId in /v1/create response');
    }

    const uploadUrl = `${baseUrl}/_matrix/media/v3/upload/${encodeURIComponent(serverName)}/${encodeURIComponent(mediaId)}`;
    const uploadRes = await fetch(uploadUrl, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': file.type
        },
        body: file
    });

    if (!uploadRes.ok) {
        throw new Error(`Failed to upload file: ${uploadRes.statusText}`);
    }

    const completeUrl = `${baseUrl}/unstable/com.beeper.msc3870/upload/${encodeURIComponent(serverName)}/${encodeURIComponent(mediaId)}/complete`;
    const completeRes = await fetch(completeUrl, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });

    if (!completeRes.ok) {
        throw new Error(`Failed to complete upload: ${completeRes.statusText}`);
    }

    const result = await completeRes    .json();
    return result.mxc || `mxc://${serverName}/${mediaId}`;
};
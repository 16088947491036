import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/AudioRecorder.css';

class AudioRecorder extends BElement {
    constructor() {
        super();
        this.mediaRecorder = null;
        this.audioChunks = [];
        this.audioBlob = null;
        this.audioUrl = null;
        this.isRecording = false;
        this.isPreviewing = false;
        this.recordingDuration = 0;
        this.recordingTimer = null;
        this.recordingStartTime = null;
        
        // Ensure Font Awesome is loaded
        this.loadFontAwesome();
    }
    
    loadFontAwesome() {
        if (!document.getElementById('font-awesome-css')) {
            const link = document.createElement('link');
            link.id = 'font-awesome-css';
            link.rel = 'stylesheet';
            link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css';
            document.head.appendChild(link);
        }
    }

    disconnectedCallback() {
        this.stopRecording();
        this.cleanupPreview();
        super.disconnectedCallback();
    }

    view() {
        return html`
            <div class="audio-recorder">
                ${this.isPreviewing ? html`
                    <div class="preview-container">
                        <audio src="${this.audioUrl}" controls class="audio-preview"></audio>
                        <div class="preview-actions">
                            <button class="discard-btn" @click=${this.discardRecording}>
                                <i class="fa-solid fa-trash"></i> Discard
                            </button>
                            <button class="send-btn" @click=${this.sendAudio}>
                                <i class="fa-solid fa-paper-plane"></i> Send
                            </button>
                        </div>
                    </div>
                ` : html`
                    <div class="recorder-container">
                        <div class="recording-indicator ${this.isRecording ? 'active' : ''}">
                            ${this.isRecording ? html`
                                <span class="recording-time">${this.formatDuration(this.recordingDuration)}</span>
                                <span class="recording-dot"></span>
                            ` : html`
                                <span>Click the record button below to start recording audio</span>
                            `}
                        </div>
                        
                        <div class="recorder-info">
                            <p>You'll need to grant microphone permission when prompted by your browser.</p>
                        </div>
                        
                        <div class="recorder-controls">
                            ${this.isRecording ? html`
                                <button class="stop-btn" @click=${this.stopRecording}>
                                    <i class="fa-solid fa-stop"></i> Stop Recording
                                </button>
                            ` : html`
                                <button class="record-btn" @click=${this.startRecording}>
                                    <i class="fa-solid fa-microphone"></i> Start Recording
                                </button>
                            `}
                            
                            <button class="cancel-btn" @click=${this.onCancel}>
                                <i class="fa-solid fa-times"></i> Cancel
                            </button>
                        </div>
                    </div>
                `}
            </div>
        `;
    }

    formatDuration(ms) {
        const seconds = Math.floor(ms / 1000);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    startRecording = async () => {
        console.log('Start recording method called');
        
        try {
            // First check if mediaDevices API is available
            if (!navigator.mediaDevices) {
                throw new Error('Media devices API not available in this browser');
            }
            
            console.log('Requesting microphone permission...');
            
            // Request microphone access with explicit constraints
            const constraints = {
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                }
            };
            
            // Explicitly request user permissions with a timeout
            let permissionTimeout;
            const timeoutPromise = new Promise((_, reject) => {
                permissionTimeout = setTimeout(() => {
                    reject(new Error('Permission request timeout - user may not have responded'));
                }, 10000); // 10 second timeout
            });
            
            // Try to get user media with timeout
            const stream = await Promise.race([
                navigator.mediaDevices.getUserMedia(constraints),
                timeoutPromise
            ]);
            
            // Clear timeout if we got here
            clearTimeout(permissionTimeout);
            
            console.log('Microphone permission granted, creating MediaRecorder');
            
            // Create media recorder with proper options
            let options;
            
            // Try different MIME types in order of preference
            const mimeTypes = [
                'audio/webm',
                'audio/webm;codecs=opus',
                'audio/ogg;codecs=opus',
                'audio/mp4',
                ''  // empty string = browser default
            ];
            
            // Find first supported MIME type
            let selectedMimeType = '';
            for (const mimeType of mimeTypes) {
                if (mimeType && MediaRecorder.isTypeSupported(mimeType)) {
                    selectedMimeType = mimeType;
                    break;
                }
            }
            
            if (selectedMimeType) {
                console.log(`Using MIME type: ${selectedMimeType}`);
                options = { mimeType: selectedMimeType };
                this.mediaRecorder = new MediaRecorder(stream, options);
            } else {
                console.warn('No specific MIME type supported, using browser default');
                this.mediaRecorder = new MediaRecorder(stream);
            }
            
            console.log('MediaRecorder created:', this.mediaRecorder.state);
            
            this.audioChunks = [];
            
            // Set up event handlers
            this.mediaRecorder.ondataavailable = (event) => {
                console.log('Data available event:', event.data?.size || 0, 'bytes');
                if (event.data && event.data.size > 0) {
                    this.audioChunks.push(event.data);
                }
            };
            
            // On recording stop
            this.mediaRecorder.onstop = () => {
                console.log('Recording stopped, chunks:', this.audioChunks.length);
                
                if (this.audioChunks.length === 0) {
                    console.error('No audio data recorded');
                    this.dispatchEvent(new CustomEvent('recording-error', { 
                        detail: { error: 'No audio data recorded' } 
                    }));
                    return;
                }
                
                // Create audio blob with proper type
                let mimeType = this.mediaRecorder.mimeType || 'audio/webm';
                
                this.audioBlob = new Blob(this.audioChunks, { type: mimeType });
                console.log('Audio blob created:', this.audioBlob.size, 'bytes, type:', mimeType);
                
                if (this.audioBlob.size < 100) {
                    console.error('Audio blob too small, likely no data');
                    this.dispatchEvent(new CustomEvent('recording-error', { 
                        detail: { error: 'Recording failed - no audio data' } 
                    }));
                    return;
                }
                
                // Create object URL for preview
                this.audioUrl = URL.createObjectURL(this.audioBlob);
                console.log('Audio URL created for preview');
                
                // Switch to preview mode
                this.isPreviewing = true;
                this.isRecording = false;
                this.triggerViewUpdate();
                
                // Stop all tracks to release the microphone
                stream.getTracks().forEach(track => track.stop());
            };
            
            // Handle errors
            this.mediaRecorder.onerror = (event) => {
                console.error('MediaRecorder error:', event.error);
                this.dispatchEvent(new CustomEvent('recording-error', { 
                    detail: { error: event.error?.message || 'Recording error' } 
                }));
            };
            
            // Start recording with timeslices to ensure data is captured
            this.mediaRecorder.start(1000); // Capture in 1-second chunks
            console.log('MediaRecorder started', this.mediaRecorder.state);
            
            this.isRecording = true;
            this.recordingStartTime = Date.now();
            
            // Update recording duration every second
            this.recordingTimer = setInterval(() => {
                this.recordingDuration = Date.now() - this.recordingStartTime;
                this.triggerViewUpdate();
            }, 1000);
            
            this.triggerViewUpdate();
        } catch (error) {
            console.error('Error starting recording:', error);
            
            // Provide more specific error messages based on the error
            let errorMessage = error.message || 'Could not access microphone';
            
            // Check for common permission errors
            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                errorMessage = 'Microphone access denied. Please grant permission to use the microphone.';
            } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
                errorMessage = 'No microphone found. Please connect a microphone and try again.';
            } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
                errorMessage = 'Could not access microphone. It may be in use by another application.';
            } else if (error.name === 'OverconstrainedError') {
                errorMessage = 'Microphone does not satisfy the requested constraints.';
            } else if (error.name === 'TypeError' && error.message.includes('timeout')) {
                errorMessage = 'Permission request timed out. Please try again.';
            }
            
            this.dispatchEvent(new CustomEvent('recording-error', { 
                detail: { error: errorMessage } 
            }));
        }
    }

    stopRecording = () => {
        console.log('Stopping recording, media recorder state:', this.mediaRecorder?.state);
        
        if (this.mediaRecorder && (this.mediaRecorder.state === 'recording' || this.mediaRecorder.state === 'paused')) {
            try {
                this.mediaRecorder.stop();
                console.log('MediaRecorder stop called');
            } catch (e) {
                console.error('Error stopping media recorder:', e);
            }
        }
        
        clearInterval(this.recordingTimer);
    }

    discardRecording = () => {
        this.cleanupPreview();
        this.isRecording = false;
        this.isPreviewing = false;
        this.audioChunks = [];
        this.recordingDuration = 0;
        this.triggerViewUpdate();
    }

    cleanupPreview = () => {
        if (this.audioUrl) {
            URL.revokeObjectURL(this.audioUrl);
            this.audioUrl = null;
            this.audioBlob = null;
        }
    }

    sendAudio = () => {
        if (!this.audioBlob) return;
        
        this.dispatchEvent(new CustomEvent('audio-ready', { 
            detail: { blob: this.audioBlob } 
        }));
        
        // Reset after sending
        this.cleanupPreview();
        this.discardRecording();
    }

    onCancel = () => {
        this.stopRecording();
        this.cleanupPreview();
        this.dispatchEvent(new CustomEvent('cancel'));
    }
}

// Try to properly register the component
try {
    if (!customElements.get('kosyma-audio-recorder')) {
        console.log('Registering kosyma-audio-recorder component');
        customElements.define('kosyma-audio-recorder', AudioRecorder);
        console.log('Successfully registered kosyma-audio-recorder component');
    } else {
        console.log('kosyma-audio-recorder component already registered');
    }
} catch (error) {
    console.error('Failed to register kosyma-audio-recorder component:', error);
}

// Export the class for better module loading
export default AudioRecorder; 
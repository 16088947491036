import BElement from '../../BElement.js'
import {html} from 'lit-html'

/**
 * @customElement kosyma-button
 * @extends BElement
 * @description A button component that extends BElement
 * @property {string} label - The label of the button
 * @property {string} identifier - The identifier of the button
 * @property {string} readonly - Whether the button is readonly
 * @property {string} style - The style of the button
 * @property {string} alert - Whether the button is an alert
 * @property {string} variant - The variant of the button - supports: default, sky-blue, light-grey, dark-grey, red, green, yellow, orange, purple, pink, brown, gray, black, white
 * @property {string} role - The role of the button - supports: primary, secondary, tertiary, quaternary, quinary, senary, septenary, octonary, nonary, denary
 * */
class Button extends BElement {
    view() {
        const label = this.getAttribute('label')
        const identifier = this.getAttribute('identifier')
        const readonly = this.getAttribute('readonly')
        const style = this.getAttribute('style')
        const alert = this.getAttribute('alert')
        const variant = this.getAttribute('variant') || 'default'
        const role = this.getAttribute('role')
        
        // Determine button class based on attributes
        let buttonClass = 'form-button'
        
        // Add variant class if specified
        if (variant !== 'default') {
            buttonClass += ` ${variant}`
        }
        
        // Add alert class if specified
        if (alert) {
            buttonClass += ' alert'
        }
        
        return html`
            <button
                    class='${buttonClass}'
                    id=${identifier ? identifier : ''}
                    role=${role ? role : 'button'}
                    ?readonly=${(!!readonly)}
                    ?aria-readonly=${(!!readonly)}
                    style=${style ? style : ''}
            >
                ${label}
            </button>
        `
    }
}

customElements.define('kosyma-button', Button)

import {html} from 'lit-html';
import BElement from '../../BElement.js';
import {Router} from '@vaadin/router';

class NewDeviceNotification extends BElement {
    static get properties() {
        return {
            device: { type: Object },
            onClose: { type: Function }
        };
    }

    constructor() {
        super();
        this.device = null;
    }

    view() {
        if (!this.device) return html``;

        const deviceName = this.device.display_name || 'Unnamed Device';
        
        return html`
            <div class="new-device-notification">
                <div class="notification-header">
                    <h3>New Device Detected</h3>
                    <button class="close-button" @click=${this.onClose}>×</button>
                </div>
                <div class="notification-content">
                    <p>A new device has been added to your account:</p>
                    <ul>
                        <li><strong>Name:</strong> ${deviceName}</li>
                        <li><strong>ID:</strong> ${this.device.device_id}</li>
                        <li><strong>IP Address:</strong> ${this.device.last_seen_ip || 'Unknown'}</li>
                        <li><strong>Time:</strong> ${this.formatDate(this.device.last_seen_ts)}</li>
                    </ul>
                    <p class="warning">If you did not add this device, your account may be compromised.</p>
                </div>
                <div class="notification-actions">
                    <button class="action-button verify" @click=${this.onVerify}>Verify Device</button>
                    <button class="action-button delete" @click=${this.onDelete}>Delete Device</button>
                    <button class="action-button view" @click=${this.onViewDevices}>View All Devices</button>
                </div>
            </div>
        `;
    }

    formatDate(timestamp) {
        if (!timestamp) return 'Unknown';
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    onVerify = () => {
        Router.go(`/messenger/devices/verification/sender/${this.device.device_id}/send`);
        if (this.onClose) this.onClose();
    }

    onDelete = () => {
        Router.go(`/messenger/devices/list`);
        if (this.onClose) this.onClose();
    }

    onViewDevices = () => {
        Router.go('/messenger/devices/list');
        if (this.onClose) this.onClose();
    }
}

customElements.define('kosyma-new-device-notification', NewDeviceNotification);

// Add CSS
const style = document.createElement('style');
style.textContent = `
    .new-device-notification {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        width: 400px;
        max-width: 90vw;
        overflow: hidden;
        animation: slide-in 0.3s ease-out;
    }

    @keyframes slide-in {
        from { transform: translateY(-20px); opacity: 0; }
        to { transform: translateY(0); opacity: 1; }
    }

    .notification-header {
        background-color: #ff4b4b;
        color: white;
        padding: 12px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .notification-header h3 {
        margin: 0;
        font-size: 18px;
    }

    .close-button {
        background: none;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
        padding: 0;
        line-height: 1;
    }

    .notification-content {
        padding: 15px;
    }

    .notification-content ul {
        margin: 10px 0;
        padding-left: 20px;
    }

    .warning {
        color: #ff4b4b;
        font-weight: bold;
        margin-top: 15px;
    }

    .notification-actions {
        display: flex;
        padding: 0 15px 15px;
        gap: 10px;
    }

    .action-button {
        flex: 1;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
    }

    .verify {
        background-color: #4caf50;
        color: white;
    }

    .delete {
        background-color: #ff4b4b;
        color: white;
    }

    .view {
        background-color: #2196f3;
        color: white;
    }
`;
document.head.appendChild(style); 
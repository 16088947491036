class OrgAdminUsersClient {
    constructor(baseURL, token) {
        this.baseURL = baseURL;
        this.token = token;
    }

    request = async (endpoint, {method = 'GET', body = null} = {}) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        };

        const response = await fetch(`${this.baseURL}${endpoint}`, {
            method,
            headers,
            body: body ? JSON.stringify(body) : null
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error: ${errorData.error || response.statusText}`);
        }

        return response.status === 204 ? null : response.json();
    };

    getCognitoUser = userId => this.request(`/admin/v1/users/${userId}/cognitouser`);

    getMatrixUser = userId => this.request(`/admin/v1/users/${userId}/matrixuser`);

    updateUser = (userId, attributes) =>
        this.request(`/admin/v1/users/${userId}/matrixuser`, {
            method: 'PUT',
            body: attributes
        });

    createUser = (attributes, {isAdmin = false, adminName = null} = {}) => {
        const endpoint = isAdmin
            ? `/admin/v1/users/admin/${adminName}`
            : `/admin/v1/users/matrixuser`;
        return this.request(endpoint, {
            method: 'POST',
            body: attributes
        });
    };

    deleteUser = userId =>
        this.request(`/admin/v1/users/${userId}/matrixuser`, {
            method: 'DELETE'
        });

    deleteDevice = (userId, deviceId) =>
        this.request(`/admin/v1/devices/${userId}/${deviceId}`, {
            method: 'DELETE'
        });

    async updateDisplayName(userId, firstName, lastName) {
        const response = await fetch(`${this.baseURL}/admin/v1/users/${userId}/matrix/displayname?firstname=${firstName}&lastname=${lastName}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json'
            }
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return await response.json();
    }
}

export default OrgAdminUsersClient;
import { getMatrixClient } from '../../matrix/control/MatrixClient.js';
import { createAction } from '@reduxjs/toolkit';
import store from '../../store.js';

export const setReadReceiptsAction = createAction('setReadReceiptsAction');
export const setFullyReadMarkerAction = createAction('setFullyReadMarkerAction');

export const setReadReceiptsState = (roomId, eventId, receipts) => {
    store.dispatch(setReadReceiptsAction({ roomId, eventId, receipts }));
};

export const setFullyReadMarkerState = (roomId, eventId) => {
    store.dispatch(setFullyReadMarkerAction({ roomId, eventId }));
};

/**
 * Check if read receipts are enabled in settings
 * @returns {boolean} Whether read receipts are enabled
 */
const isReadReceiptsEnabled = () => {
    const state = store.getState();
    return state.settings?.readReceiptsEnabled ?? false; // Default to false if not set
};

/**
 * Process a read receipt event and update the state accordingly
 * @param {Object} event - The read receipt event
 */
export const processReadReceipt = (event) => {
    try {
        // Get content either from the function or directly from the object
        const content = event.content || {};
        
        // Try to get room ID from various possible locations
        const roomId = event.room_id || event.event?.room_id;

        if (!roomId) {
            console.warn('No room ID found in read receipt event:', event);
            return;
        }

        // For each event ID in the content
        Object.entries(content).forEach(([eventId, receiptTypes]) => {
            try {
                // Check if this event has read receipts
                if (receiptTypes['m.read']) {
                    const readData = receiptTypes['m.read'];
                    const userIds = Object.keys(readData).filter(key => key !== 'ts');

                    if (userIds.length > 0) {
                        // Update the state with these receipts
                        setReadReceiptsState(roomId, eventId, userIds);
                        
                        // Also update the state for the room's receipt store
                        const client = getMatrixClient();
                        const room = client.getRoom(roomId);
                        if (room) {
                            const event = room.findEventById(eventId);
                            if (event) {
                                userIds.forEach(userId => {
                                    room.addReceipt({
                                        type: 'm.read',
                                        userId: userId,
                                        data: { ts: readData[userId]?.ts || Date.now() }
                                    }, event);
                                });
                                console.debug('Updated room receipt store:', {
                                    roomId,
                                    eventId,
                                    userIds
                                });
                            }
                        }
                    }
                }
            } catch (innerError) {
                console.error('Error processing individual receipt:', {
                    roomId,
                    eventId,
                    error: innerError.message
                });
            }
        });
    } catch (error) {
        console.error('Error processing read receipt:', {
            error: error.message,
            event: {
                type: event.type,
                roomId: event.room_id,
                content: event.content
            }
        });
    }
};

/**
 * Sends a read receipt for the given event in the specified room
 * Only sends if read receipts are enabled in settings
 * @param {string} roomId - The ID of the room
 * @param {string} eventId - The ID of the event to mark as read
 * @param {boolean} isPrivate - Whether to send a private read receipt
 */
export const sendReadReceipt = async (roomId, eventId, isPrivate = false) => {
    try {
        const client = getMatrixClient();
        const readReceiptsEnabled = isReadReceiptsEnabled();
        
        console.debug('Sending read receipt:', {
            roomId,
            eventId,
            isPrivate,
            readReceiptsEnabled
        });

        // Always update fully-read marker
        await client.setRoomReadMarkers(roomId, eventId, eventId);
        setFullyReadMarkerState(roomId, eventId);

        // Only send read receipt if enabled
        if (readReceiptsEnabled && !isPrivate) {
            const room = client.getRoom(roomId);
            if (!room) {
                throw new Error(`Room not found: ${roomId}`);
            }

            const event = room.findEventById(eventId) || { 
                getId: () => eventId, 
                event: { room_id: roomId } 
            };
            
            await client.sendReadReceipt(event);
            console.debug('Sent read receipt successfully');
        } else if (isPrivate) {
            const room = client.getRoom(roomId);
            if (!room) {
                throw new Error(`Room not found: ${roomId}`);
            }

            const event = room.findEventById(eventId) || { 
                getId: () => eventId, 
                event: { room_id: roomId } 
            };
            
            await client.sendReadReceipt(event, 'm.read.private');
            console.debug('Sent private read receipt successfully');
        }
    } catch (error) {
        console.error('Failed to send read receipt:', {
            error: error.message,
            roomId,
            eventId,
            isPrivate
        });
        throw error; // Re-throw to allow handling by caller
    }
};

/**
 * Gets all read receipts for a specific event
 * @param {string} roomId - The ID of the room
 * @param {string} eventId - The ID of the event
 * @returns {Array} Array of user IDs who have read the message
 */
export const getReadReceipts = (roomId, eventId) => {
    const readReceiptsEnabled = isReadReceiptsEnabled();
    
    if (!readReceiptsEnabled) {
        console.log('Read receipts are disabled');
        return []; // Don't show any receipts if disabled
    }

    const client = getMatrixClient();
    const room = client.getRoom(roomId);
    if (!room) {
        console.log('Room not found:', roomId);
        return [];
    }

    // Try to get receipts directly from the room's receipt store
    const receiptStore = room.getReceiptsForEvent({
        getId: () => eventId,
        event: { room_id: roomId }
    }) || [];
    
    console.log('Raw read receipts from Matrix SDK:', {
        roomId,
        eventId,
        receiptStore
    });

    // Filter for 'm.read' type receipts and extract user IDs
    const userIds = receiptStore
        .filter(receipt => receipt.type === 'm.read')
        .map(receipt => receipt.userId);

    console.log('Processed read receipts for event', eventId, ':', userIds);
    return userIds;
};

/**
 * Gets the fully-read marker position for a room
 * @param {string} roomId - The ID of the room
 * @returns {string|null} The event ID of the fully-read marker, or null if not set
 */
export const getFullyReadMarker = (roomId) => {
    const client = getMatrixClient();
    const room = client.getRoom(roomId);
    if (!room) return null;

    return room.getFullyReadMarker();
};

/**
 * Mark all messages in a room as read up to the latest event
 * @param {string} roomId - The ID of the room
 */
export const markRoomAsRead = async (roomId) => {
    const client = getMatrixClient();
    const room = client.getRoom(roomId);
    if (!room) return;

    const timeline = room.getLiveTimeline();
    const events = timeline.getEvents();
    if (events.length === 0) return;

    // Get the latest event
    const latestEvent = events[events.length - 1];
    await sendReadReceipt(roomId, latestEvent.getId());
}; 
import {html, nothing} from 'lit-html'
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js'
import {setRoomCreateMatrixState, setRoomCreateTimState, setRoomCreateState} from '../control/RoomControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';

class RoomCreate extends BElement {
    connectedCallback() {
        super.connectedCallback();
    }

    extractState(state) {
        return {
            room_create: state.rooms.room_create,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Create Room</h2>
                    <kosyma-input-text
                            identifier='name'
                            label='Name'
                            autofocus='true'
                            .value=${this.state.room_create.matrix.name}
                            @keyup=${(e) => setRoomCreateMatrixState({name: 'name', value: e.target.value})}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='topic'
                            label='Topic'
                            .value=${this.state.room_create.matrix.topic}
                            @keyup=${(e) => setRoomCreateMatrixState({name: 'topic', value: e.target.value})}
                    >
                    </kosyma-input-text>
                    <div class='room-create-field'>
                        <label>Room Avatar</label>
                        <input type='file'
                               class='room-avatar-input'
                               accept='image/*'
                               @change=${this.onAvatarSelected}/>
                        ${this.state.room_create.avatar_preview ? html`
                            <img src=${this.state.room_create.avatar_preview} 
                                 alt="Room avatar preview" 
                                 class="avatar-preview"/>
                        ` : nothing}
                    </div>
                    <kosyma-checkbox
                            identifier='encrypted'
                            label='Encrypted'
                            checked=${this.state.room_create.matrix.encrypted}
                            @change=${(e) => setRoomCreateMatrixState({name: 'encrypted', value: e.target.checked})}
                    >
                    </kosyma-checkbox>
                    <kosyma-checkbox
                            identifier='public'
                            label='Public'
                            checked=${this.state.room_create.matrix.visibility === 'public'}
                            @change=${(e) => {
                                setRoomCreateMatrixState({
                                    name: 'visibility',
                                    value: e.target.checked ? 'public' : 'private'
                                });
                                setRoomCreateMatrixState({
                                    name: 'preset',
                                    value: e.target.checked ? 'public_chat' : 'private_chat'
                                });
                            }}
                    >
                    </kosyma-checkbox>
                    <kosyma-checkbox
                            identifier='tim'
                            label='Use TIM'
                            checked=${this.state.room_create.tim.enabled}
                            @change=${(e) => {
                                setRoomCreateTimState({
                                    name: 'enabled',
                                    value: e.target.checked
                                });
                            }}
                    >
                    </kosyma-checkbox>
                    ${this.state.room_create.tim.enabled
                            ? html`
                                <kosyma-input-text
                                        identifier='tim_room_name'
                                        label='TIM Room Name'
                                        .value=${this.state.room_create.tim.name}
                                        @keyup=${(e) => setRoomCreateTimState({
                                            name: 'name',
                                            value: e.target.value
                                        })}
                                ></kosyma-input-text>
                                <kosyma-input-text
                                        identifier='tim_room_topic'
                                        label='TIM Topic'
                                        .value=${this.state.room_create.tim.topic}
                                        @keyup=${(e) => setRoomCreateTimState({
                                            name: 'topic',
                                            value: e.target.value
                                        })}
                                ></kosyma-input-text>
                            ` : nothing}
                    <kosyma-button
                            identifier='create'
                            label='Create'
                            @click=${e => this.onCreate(e)}
                    ></kosyma-button>
                </div>
            </div>
        `
    }

    onCreate = async (e) => {
        e.target.disabled = true;

        try {
            const matrixClient = getMatrixClient();
            const roomCreateMatrixRequest = {...this.state.room_create.matrix};
            if (this.state.room_create.tim.enabled) {
                roomCreateMatrixRequest.content = {
                    type: 'de.gematik.tim.roomtype.casereference.v1'
                }
            }

            const {room_id} = await matrixClient.createRoom(roomCreateMatrixRequest);

            let counter = 1;
            while (!matrixClient.getRoom(room_id)) {
                console.log('wait for ' + room_id)
                await new Promise(resolve => setTimeout(resolve, 1000));
                counter++;
            }

            if (this.state.room_create.matrix.encrypted) {
                const cryptoConfig = {algorithm: 'm.megolm.v1.aes-sha2'};
                await matrixClient.sendStateEvent(room_id, 'm.room.encryption', cryptoConfig);
            }

            // If an avatar file was selected, upload it and set as room avatar
            const avatarInput = document.querySelector('.room-avatar-input');
            if (avatarInput && avatarInput.files.length > 0) {
                const file = avatarInput.files[0];
                try {
                    const response = await matrixClient.uploadContent(file);
                    await matrixClient.sendStateEvent(room_id, 'm.room.avatar', {
                        url: response.content_uri
                    });
                } catch (error) {
                    console.error('Failed to upload room avatar:', error);
                    addErrorMessage('Failed to set room avatar: ' + error.message);
                }
            }

            if (this.state.room_create.tim.enabled) {
                await matrixClient.sendStateEvent(room_id, 'de.gematik.tim.room.name', {
                    name: this.state.room_create.tim.name
                });
                await matrixClient.sendStateEvent(room_id, 'de.gematik.tim.room.topic', {
                    topic: this.state.room_create.tim.topic
                });
            }

            // For public rooms, route to the invitation view
            if (this.state.room_create.matrix.visibility === 'public') {
                Router.go(`/messenger/rooms/${room_id}?tab=invitation`);
            } else {
                Router.go(`/messenger/rooms/${room_id}`);
            }
        } catch (error) {
            console.error(error);
            addErrorMessage(error);
        }
    }

    onMatrixChange = (name, value) => {
        setRoomCreateMatrixState({name, value});
    }

    onAvatarSelected = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a preview URL for the selected image
            const reader = new FileReader();
            reader.onload = (e) => {
                setRoomCreateState({
                    name: 'avatar_preview',
                    value: e.target.result
                });
            };
            reader.readAsDataURL(file);
        }
    }

    onTimChange = (name, value) => {
        setRoomCreateTimState({name, value});
    }
}

customElements.define('kosyma-room-create', RoomCreate);

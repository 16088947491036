import * as sdk from 'matrix-js-sdk';
import {cryptoCallbacks} from './SecretStorageKeys.js';
import {appName} from '../../app.config.js';
import {LAST_SECURITY_NOTIFICATION_KEY} from '../../notification/control/NotificationsControl.js';
import {setupGlobalVerificationListeners} from './DeviceVerification.js';
import {setMatrixUpdateState, setSessionState} from './MatrixControl.js';
import store from '../../store.js';

let client;

const KEY = `${appName}.localstorage.control`;
const DEV_SESSION_KEY = `${appName}.dev.session`;
const DEV_MODE = process.env.NODE_ENV === 'development';

// Keep track of whether we've already initialized a client in this session
let clientInitialized = false;

export const createMatrixClient = async (loginResponse, baseUrl) => {
    // If we already have an initialized client in this session and we're in dev mode,
    // just return it to prevent re-initialization on hot reload
    if (DEV_MODE && clientInitialized && client) {
        // Ensure the session is in the Redux store
        const currentSession = store.getState().matrix.session;
        if (!currentSession) {
            const savedSession = localStorage.getItem(DEV_SESSION_KEY);
            if (savedSession) {
                try {
                    const session = JSON.parse(savedSession);
                    setSessionState(session);
                    setMatrixUpdateState();
                } catch (e) {
                    console.error('Failed to parse saved session:', e);
                }
            }
        }
        
        return client;
    }
    
    // In development mode, try to reuse existing session
    if (DEV_MODE) {
        // If we have a new login response, save it for future development reloads
        if (loginResponse) {
            // Create a clean session object with only serializable values
            const sessionToSave = {
                baseUrl,
                access_token: loginResponse.access_token,
                device_id: loginResponse.device_id,
                user_id: loginResponse.user_id,
                refresh_token: loginResponse.refresh_token
            };
            
            // Add any other important fields that might be present
            if (loginResponse.well_known) {
                sessionToSave.well_known = loginResponse.well_known;
            }
            
            try {
                // Test that the session is serializable
                const testJson = JSON.stringify(sessionToSave);
                localStorage.setItem(DEV_SESSION_KEY, testJson);
                
                // Make sure to update the Redux store
                setSessionState(sessionToSave);
                setMatrixUpdateState();
            } catch (e) {
                console.error('Failed to save session to localStorage:', e);
            }
        } else {
            // Try to restore from localStorage if no login response provided
            const savedSession = localStorage.getItem(DEV_SESSION_KEY);
            
            if (savedSession) {
                try {
                    // Restore session from localStorage
                    const session = JSON.parse(savedSession);
                    // Check if the session is still valid before trying to use it
                    const isValid = await checkSessionValidity(session);
                    
                    if (isValid) {
                        // Update the Redux store with the session
                        setSessionState(session);
                        setMatrixUpdateState();
                        
                        const restoredClient = await createClientFromSavedSession(session);
                        clientInitialized = true;
                        return restoredClient;
                    } else {
                        console.warn('Saved session is no longer valid, clearing it');
                        localStorage.removeItem(DEV_SESSION_KEY);
                        // Also clear any IndexedDB data that might be corrupted
                        await clearIndexedDBData();
                        // Continue with normal flow if session is invalid
                    }
                } catch (e) {
                    console.warn('Failed to restore development session:', e);
                    // Continue with normal flow if restoration fails
                }
            }
        }
    } else {
        // In production, always clear storage before creating a new client
        await deleteLocalStorage();
    }

    // Create a new client with the provided login response
    const newClient = await initializeClient(loginResponse, baseUrl);
    clientInitialized = true;
    return newClient;
};

// Helper function to clear IndexedDB data
async function clearIndexedDBData() {
    if (window.indexedDB.databases !== undefined) {
        try {
            const dbs = await window.indexedDB.databases();
            for (const db of dbs) {
                window.indexedDB.deleteDatabase(db.name);
            }
        } catch (e) {
            console.error('Error clearing IndexedDB:', e);
        }
    }
}

// Helper function to check if a saved session is still valid
async function checkSessionValidity(session) {
    // Basic validation of session object
    if (!session || !session.access_token || !session.user_id || !session.baseUrl) {
        console.error('Invalid session object:', session);
        return false;
    }
    
    try {
        // Create a temporary client to check session validity
        const tempClient = sdk.createClient({
            baseUrl: session.baseUrl,
            accessToken: session.access_token,
            userId: session.user_id,
            useAuthorizationHeader: false,
            queryParams: {
                access_token: session.access_token
            }
        });
        
        // Try to make a simple API call that should succeed if the session is valid
        // Using /account/whoami is a lightweight call to check token validity
        const whoami = await tempClient.whoami();
        // Verify that the user ID matches
        if (whoami.user_id !== session.user_id) {
            console.error('User ID mismatch:', whoami.user_id, session.user_id);
            return false;
        }
        
        return true;
    } catch (e) {
        console.error('Session validation failed:', e);
        if (e.httpStatus) {
            console.error(`HTTP Status: ${e.httpStatus}, Error: ${e.data?.error}`);
        }
        return false;
    }
}

// Helper function to create client from saved session
async function createClientFromSavedSession(session) {
    try {
        // Skip deleteLocalStorage to preserve existing data
        return await initializeClient(session, session.baseUrl);
    } catch (error) {
        console.error('Error creating client from saved session:', error);
        // If we fail to create a client from the saved session, clear it
        localStorage.removeItem(DEV_SESSION_KEY);
        // Also clear any IndexedDB data that might be corrupted
        await clearIndexedDBData();
        throw new Error('Failed to restore session, please log in again');
    }
}

// Common client initialization logic
async function initializeClient(credentials, baseUrl) {
    try {
        // Ensure we have a valid access token
        if (!credentials.access_token) {
            console.error('No access token provided for Matrix client initialization');
            throw new Error('Missing access token');
        }
        
        // Log the token (first few characters) for debugging
        const indexedDBStore = new sdk.IndexedDBStore({
            indexedDB: globalThis.indexedDB,
            localStorage: globalThis.localStorage,
            dbName: 'kosyma-ti-messenger'
        });

        // Create the client with specific options to ensure access token is used
        client = sdk.createClient({
            baseUrl: baseUrl,
            accessToken: credentials.access_token,
            deviceId: credentials.device_id,
            userId: credentials.userId || credentials.user_id, // Handle both formats
            refreshToken: credentials.refresh_token,
            store: indexedDBStore,
            cryptoStore: new sdk.IndexedDBCryptoStore(globalThis.indexedDB, 'crypto'),
            timelineSupport: true,
            verificationMethods: [
                'm.sas.v1',
            ],
            cryptoCallbacks,
            // Add request timeout to avoid hanging requests
            localTimeoutMs: 30000, // Increased timeout
            // More conservative settings for development
            lazyLoadMembers: true,
            pendingEventOrdering: "detached",
            // Force using query parameters for authentication instead of headers
            useAuthorizationHeader: false,
            queryParams: {
                access_token: credentials.access_token
            }
        });

        // Now start the store after assigning it to the client
        await indexedDBStore.startup();
        
        // Initialize crypto with error handling
        try {
            await client.initCrypto();
        } catch (cryptoError) {
            console.warn('Crypto initialization failed, continuing without E2E encryption:', cryptoError);
            // Continue without crypto - this might be acceptable for development
        }
        
        // Add reconnection logic for when the server is temporarily down
        client.on('sync', function(state, prevState, data) {
            console.log('Sync state changed:', state, 'from', prevState);
            
            // If sync fails, try to recover
            if (state === 'ERROR') {
                console.warn('Sync encountered an error, will attempt to recover');
                
                // Wait a moment and try to restart the client
                setTimeout(() => {
                    try {
                        // Force the client to stop first
                        client.stopClient();
                        
                        // Then restart with more aggressive settings to recover timeline
                        client.startClient({
                            initialSyncLimit: 50, // Increase limit to ensure we get messages
                            includeArchivedRooms: false,
                            lazyLoadMembers: true,
                            disablePresence: true,
                            queryParams: {
                                access_token: credentials.access_token
                            }
                        });
                        
                    } catch (e) {
                        console.warn('Failed to restart client after sync error:', e);
                    }
                }, 5000);
            }
            
            // When sync is properly prepared, save the session state for potential recovery
            if (state === 'PREPARED') {
                try {
                    // Save an optimized version of the session for possible recovery
                    const sessionToSave = {
                        baseUrl: client.baseUrl,
                        access_token: credentials.access_token,
                        device_id: credentials.device_id,
                        user_id: credentials.user_id || credentials.userId,
                        refresh_token: credentials.refresh_token,
                        last_sync: Date.now()
                    };
                    
                    // In development mode, save this to localStorage for recovery
                    if (DEV_MODE) {
                        localStorage.setItem(DEV_SESSION_KEY, JSON.stringify(sessionToSave));
                    }
                } catch (e) {
                    console.error('Failed to save session state:', e);
                }
            }
        });
        
        client.on('Session.logged_out', function(error) {
            // Clear the saved session when we detect a logout
            if (DEV_MODE) {
                localStorage.removeItem(DEV_SESSION_KEY);
                clientInitialized = false;
            }
        });
        
        // Start client with error handling for API failures
        try {
            // Use more aggressive sync settings to ensure timelines are loaded
            const syncOptions = {
                initialSyncLimit: 100, // Significantly increased to ensure we load messages
                includeArchivedRooms: false,
                lazyLoadMembers: true,
                disablePresence: true, // Disable presence updates to reduce API calls
                queryParams: {
                    access_token: credentials.access_token
                }
            };
            
            // Add a timeout to catch hanging sync requests
            const startPromise = client.startClient(syncOptions);
            const timeoutPromise = new Promise((_, reject) => {
                setTimeout(() => reject(new Error('Client start timed out')), 30000);
            });
            
            await Promise.race([startPromise, timeoutPromise]);
            
            // Add a debug log of the rooms and their timelines after sync
            client.once('sync', function(state, prevState, data) {
                if (state === 'PREPARED') {
                    const rooms = client.getRooms();
                    let anyRoomHasEvents = false;
                    
                    rooms.forEach(room => {
                        try {
                            const timeline = room.getLiveTimeline();
                            const timelineEvents = timeline.getEvents();
                            if (timelineEvents.length > 0 || (room.timeline && room.timeline.length > 0)) {
                                anyRoomHasEvents = true;
                            }
                        } catch (e) {
                            console.error(`Error checking timeline for room ${room.roomId}:`, e);
                        }
                    });
                    
                    // If none of the rooms have events, try to force a refresh of room timelines
                    if (rooms.length > 0 && !anyRoomHasEvents) {
                        console.warn('No rooms have events after sync - attempting timeline recovery');
                        
                        // Wait a moment for any pending operations to complete
                        setTimeout(async () => {
                            try {
                                for (const room of rooms) {
                                    try {
                                        // Force a direct messages request for each room
                                        const response = await client.createMessagesRequest(
                                            room.roomId,
                                            null,
                                            50,
                                            'b'
                                        );
                                        
                                        if (response && response.chunk && response.chunk.length > 0) {
                                            client.getEventMapper()(response.chunk);
                                        }
                                    } catch (roomError) {
                                        console.warn(`Failed to recover timeline for room ${room.roomId}:`, roomError);
                                    }
                                }
                            } catch (recoveryError) {
                                console.error('Timeline recovery attempt failed:', recoveryError);
                            }
                        }, 5000);
                    }
                }
            });
        } catch (startError) {
            console.error('Failed to start client:', startError);
            if (startError.httpStatus) {
                console.error(`HTTP Status: ${startError.httpStatus}, Error: ${startError.data?.error}`);
            }
            
            // In development mode, try to continue even if there are errors
            if (DEV_MODE) {
                console.warn('Continuing despite client start error in development mode');
                
                // Force the client to be considered initialized even if there were errors
                clientInitialized = true;
            } else {
                throw startError;
            }
        }
        
        await client.setGlobalErrorOnUnknownDevices(false);
        
        // Set up global verification listeners
        setupGlobalVerificationListeners();
        
        console.log('Matrix client initialization complete');
        return client;
    } catch (error) {
        console.error('Client initialization failed:', error);
        throw error;
    }
}

export const deleteLocalStorage = async (force = false) => {
    // Only skip clearing in development mode when not forced
    if (DEV_MODE && !force) {
        console.log('Skipping localStorage clearing in development mode');
        return;
    }
    
    // In production or when forced, proceed with normal clearing
    const lastShown = localStorage.getItem(LAST_SECURITY_NOTIFICATION_KEY) || 0;
    window.localStorage.clear();
    localStorage.setItem(LAST_SECURITY_NOTIFICATION_KEY, lastShown);

    // Clear the dev session key explicitly
    if (DEV_MODE) {
        localStorage.removeItem(DEV_SESSION_KEY);
        clientInitialized = false;
    }

    // Clear IndexedDB in production or when forced
    if (window.indexedDB.databases !== undefined) {
        const dbs = await window.indexedDB.databases();
        dbs.forEach(db => window.indexedDB.deleteDatabase(db.name));
    }
}

export const setMatrixClient = newClient => {
    client = newClient;
    if (DEV_MODE) {
        clientInitialized = newClient !== null;
    }
}

export const getMatrixClient = () => {
    return client;
}

// Update the uploadContentWithRetry function to use the proper URL format for ujumbelabs server
export const uploadContentWithRetry = async (file, maxSizeMB = 10) => {
    const client = getMatrixClient();
    if (!client) {
        throw new Error('Matrix client not initialized');
    }
    
    // Validate file size
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size > maxSizeBytes) {
        throw {
            httpStatus: 413,
            data: { error: `File exceeds maximum size of ${maxSizeMB}MB` }
        };
    }
    
    // Attempt to determine content type more accurately if it's generic
    let contentType = file.type;
    if (!contentType || contentType === 'application/octet-stream') {
        // Try to infer content type from extension
        const extension = file.name.split('.').pop().toLowerCase();
        const mimeTypes = {
            'mp4': 'video/mp4',
            'webm': 'video/webm',
            'ogg': 'video/ogg',
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'png': 'image/png',
            'gif': 'image/gif',
            'pdf': 'application/pdf'
        };
        contentType = mimeTypes[extension] || 'application/octet-stream';
    }
    
    // For images and videos, verify that they are valid
    if (contentType.startsWith('image/')) {
        if (!await isValidImage(file)) {
            throw new Error('Invalid image file');
        }
    } else if (contentType.startsWith('video/')) {
        if (!await isValidVideo(file)) {
            throw new Error('Invalid video file');
        }
    }
    
    // Check if the URL contains ujumbelabs, and use their specific format if so
    const baseUrl = client.baseUrl;
    const isUjumbeServer = baseUrl && baseUrl.includes('ujumbelabs.com');
    
    // Upload options that work specifically with the ujumbelabs server
    const options = {
        type: contentType,
        onlyContentUri: true
    };
    
    // Add specific settings for ujumbe server
    if (isUjumbeServer) {
        // Use v3 endpoint instead of r0
        options.urlPath = '/_matrix/media/v3/upload';
        
        // Add filename as query parameter
        options.extraParams = {
            filename: encodeURIComponent(file.name)
        };
        
        console.log('Using specific upload format for ujumbelabs server');
    }
    
    try {
        // Upload with the appropriate options
        return await client.uploadContent(file, options);
    } catch (err) {
        // Enhanced error logging
        console.error('Upload failed:', err);
        if (err.httpStatus) {
            console.error(`HTTP Status: ${err.httpStatus}, Error:`, err.data || err.message);
        }
        throw err;
    }
};

// Helper to validate image files
async function isValidImage(file) {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = URL.createObjectURL(file);
    });
}

// Helper to validate video files
async function isValidVideo(file) {
    return new Promise((resolve) => {
        const video = document.createElement('video');
        
        // Set a timeout in case the video takes too long to load
        const timeoutId = setTimeout(() => {
            URL.revokeObjectURL(video.src);
            resolve(false);
        }, 3000);
        
        video.onloadedmetadata = () => {
            clearTimeout(timeoutId);
            URL.revokeObjectURL(video.src);
            resolve(true);
        };
        
        video.onerror = () => {
            clearTimeout(timeoutId);
            URL.revokeObjectURL(video.src);
            resolve(false);
        };
        
        video.src = URL.createObjectURL(file);
    });
}